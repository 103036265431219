import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../tools/privateRoute";
import Content from './content';
import Design from './design';
import Abc from './abc';
import Forms from './forms';
import Users from './users';
import Offers from "./offers";
import Purchase from "./purchase";
import Roles from "./users/roles";
import Profile from "../modules/account/profile";
import Forum from './forum';
import Community from './community';
import Shipment from './shipment';
import Tools from './tools';

const Entities = ({match}) =>(
    <div>
        <Switch>
            <PrivateRoute path={`${match.url}static-content`} component={Content}/>
            <PrivateRoute path={`${match.url}design`} component={Design}/>
            <PrivateRoute path={`${match.url}abc`} component={Abc}/>
            <PrivateRoute path={`${match.url}forms`} component={Forms}/>
            <PrivateRoute path={`${match.url}users`} component={Users}/>
            <PrivateRoute path={`${match.url}roles`} component={Roles}/>
            <PrivateRoute path={`${match.url}purchase`} component={Purchase}/>
            <PrivateRoute path={`${match.url}offers`} component={Offers}/>
            <PrivateRoute path={`${match.url}profile`} component={Profile}/>
            <PrivateRoute path={`${match.url}forum`} component={Forum}/>
            <PrivateRoute path={`${match.url}community`} component={Community}/>
            <PrivateRoute path={`${match.url}shipment`} component={Shipment}/>
            <PrivateRoute path={`${match.url}tools`} component={Tools}/>
        </Switch>
    </div>
);

export default Entities;