import {billing, shipmentTime} from "../webapi/constants";

export const pieceList = (prefix) => [
    {
        id: `${prefix}.item`,
        name: 'Lista paczek',
        type: 'list',
        first: true,
        values: [
            {
                name: 'Typ paczki',
                id: `type`,
                type: 'select',
                values: [
                    {
                        id: 'ENVELOPE',
                        name: 'Przesyłka kopertowa',
                    },
                    {
                        id: 'PACKAGE',
                        name: 'paczka',
                    },
                ],
                required: 'To pole jest wymagane',
            },
            {
                name: 'Waga paczki (w kilogramach)',
                id: `weight`,
                type: 'number',
                required: 'To pole jest wymagane',
            },
            {
                name: 'Szerokość paczki (w centymetrach)',
                id: `width`,
                type: 'number',
                required: 'To pole jest wymagane',
            },
            {
                name: 'Wysokość paczki (w centymetrach)',
                id: `height`,
                type: 'number',
                required: 'To pole jest wymagane',
            },
            {
                name: 'Długość paczki (w centymetrach)',
                id: `lenght`,
                type: 'number',
                required: 'To pole jest wymagane',
            },
            {
                name: 'Ilość paczek tego typu',
                id: `quantity`,
                type: 'number',
                required: 'To pole jest wymagane',
            },
            {
                name: 'Oznaczenie, że paczka jest niestandardowa',
                id: `nonStandard`,
                type: 'checkbox',
            },
            {
                name: 'Identyfikator paczki (JJD) na etykietę BLP',
                id: `blpPieceId`,
                type: 'text',
            },
        ],
        required: 'To pole jest wymagane',
    }
]

export const shipmentInfo = (prefix) => [
    {
        name: 'Rodzaj żądania',
        id: `${prefix}.dropOffType`,
        type: 'select',
        values: [
            {
                id: 'REGULAR_PICKUP',
                name: 'Utworzenie przesyłki bez restrykicji',
            },
            {
                id: 'REQUEST_COURIER',
                name: 'Utworzenie przesyłki i zamówienia',
            },
            {
                id: 'COURIER_ONLY',
                name: 'Zamówienie kuriera',
            }
        ],
        required: 'To pole jest wymagane',
    },
    {
        name: 'Typ usługi przewozowej',
        id: `${prefix}.serviceType`,
        type: 'select',
        values: [
            {
                id: 'LM',
                name: 'Usługa przewozowa',
            },
        ],
        required: 'To pole jest wymagane',
    },
    ...billing(`${prefix}.billing`),

    {
        name: 'Wybór etykiety zwrotnej',
        id: `${prefix}.labelType`,
        type: 'select',
        values: [
            {
                id: 'BLP',
                name: 'etykieta BLP w formacie PDF',
            },
            {
                id: 'ZBLP',
                name: 'etykieta BLP w formacie drukarek Zebra',
            }
        ],
        required: 'To pole jest wymagane',
    },
    ...shipmentTime(`${prefix}`),
];



export const Address = (name, prefix) => [
            {
                name: `${name} -> Imię i Nazwisko`,
                id: `${prefix}.name`,
                type: 'text',
                required: 'To pole jest wymagane',
            },
            {
                name: `${name} -> Kod pocztowy (bez -)`,
                id: `${prefix}.postcode`,
                type: 'text',
                required: 'To pole jest wymagane',
            },
            {
                name: `${name} -> Typ adresu`,
                id: `${prefix}.addressType`,
                type: 'select',
                values: [
                    {
                        id: 'B',
                        name: 'B',
                    },
                    {
                        id: 'C',
                        name: 'C',
                    }
                ],
                required: 'To pole jest wymagane',
            },
            {
                name: `${name} -> Miasto`,
                id: `${prefix}.city`,
                type: 'text',
                required: 'To pole jest wymagane',
            },
            {
                name: `${name} -> Ulica`,
                id: `${prefix}.street`,
                type: 'text',
                required: 'To pole jest wymagane',
            },
            {
                name: `${name} -> Numer domu`,
                id: `${prefix}.houseNumber`,
                type: 'text',
                required: 'To pole jest wymagane',
            },
            {
                name: `${name} -> Numer mieszkania`,
                id: `${prefix}.apartmentNumber`,
                type: 'text',
            },
        ];

export const Preaviso = (name, prefix) => [
    {
        name: `${name} -> Nazwa osoby kontaktowej`,
        id: `${prefix}.personName`,
        type: 'text',
    },
    {
        name: `${name} -> Adres email`,
        id: `${prefix}.emailAddress`,
        type: 'text',
    },
    {
        name: `${name} -> Numer telefonu`,
        id: `${prefix}.phoneNumber`,
        type: 'text',
    },
];

export const AddressData = (name, prefix) => [
    ...Address(`${name} - dane adresowe`, `${prefix}.address`),
    ...Preaviso(`${name} - dane kontaktowe`, `${prefix}.contact`),
    ...Preaviso(`${name} - dane do preawizacji`, `${prefix}.preaviso`),
];

export const ship = (prefix) => [
    ...AddressData('Nadawca', `${prefix}.shipper`),
    ...AddressData('Odbiorca', `${prefix}.receiver`),
    {
        id: `${prefix}.servicePointAccountNumber`,
        name: "Numer SAP service pointu",
        type: 'text',
    }
];

export const fields = [
    //shipment info

    ...shipmentInfo('shipmentInfo'),

    //rest
    {
        name: 'Komentarz',
        id: 'comment',
        type: 'text',
    },
    {
        name: 'Zawartość przesyłki',
        id: 'content',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Numer referencyjny przesyłki',
        id: 'reference',
        type: 'text',
    },

    //ship
    ...ship('ship'),

    //piece list
    ...pieceList('pieceList'),
];