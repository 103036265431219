import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import ArticleCreator from "./articleCreator";
import Articles from "./articles";
import ArticleView from "./article";
import Category from './category';

const Article = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/category`} component={Category}/>
            <PrivateRoute exact path={`${match.url}/add`} component={ArticleCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={ArticleView}/>
            <PrivateRoute exact path={`${match.url}`} component={Articles}/>
        </Switch>
    </>
);

export default Article;