import DateFilter from "./DateFilter";
import React from "react";

export const DateSpanFilter = ({update, name, title, time}) => {

    const handleInput = (e) => {
        let result;
        if (e.from !== undefined) {
            if (e.from !== '') {
                result = {
                    from: `${name}>${e.from}`,
                }
            } else {
                result = {
                    from: '',
                }
            }
        } else if (e.to !== undefined) {
            if (e.to !== '') {
                result = {
                    to: `${name}<${e.to}`,
                }
            } else {
                result = {
                    to: '',
                }
            }
        }
        update(result);
    }

    return (
        <div>
            <label className='form-label' style={{fontWeight: "bold"}}>{title}</label>
            <DateFilter
                onChange={handleInput}
            />
        </div>
    )
}

export default DateSpanFilter;