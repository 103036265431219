import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {draft} from "./constants";
import {addDesign, getAllDocuments} from "./document.reducer";
import {connect} from "react-redux";
import {getDesignSimple} from "../../design/design/design.reducer";

class DraftCreator extends Component{

    componentDidMount() {
        this.props.getDesignSimple();
    }

    cancel(){
        this.props.history.goBack();
    }

    columns = draft.design;


    save(data){
        const userId = this.props.match.params.id;
        this.props.addDesign(userId, {id: Number(data.design)}, this.props.match.params.type);
        this.props.getAllDocuments();
        this.cancel();
    }

    getData(){
        return this.props.designs;
    }

    render() {
        this.columns[0].values=this.getData();
        return (
            <Page header={'Dodaj projekt'}>
                {this.getData() && !this.getData().content &&
                <GenericForm
                    content={this.columns}
                    data={{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                }
            </Page>
        );
    }
}


const mapStateToProps = ({design, addon, addonPackage}) => ({
    designs: design.data,
    // addons: addon.data,
    // packages: addonPackage.data,
});

const mapDispatchToProps = {
    addDesign,
    getAllDocuments,
    // addAddon,
    // addPackage,
    getDesignSimple,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftCreator);