import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_MEDIAS: 'media/FETCH_ALL_MEDIAS',
    FETCH_MEDIA: 'media/FETCH_MEDIA',
    UPDATE_MEDIA: 'media/UPDATE_MEDIA',
    DELETE_MEDIA: 'media/DELETE_MEDIA',
    CHANGE_PAGINATION: 'media/CHANGE_PAGINATION',
    CREATE_MEDIA: 'media/CREATE_MEDIA',
    RESET: 'media/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10, 'id,desc'),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const MediaState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_MEDIAS):
        case REQUEST(ActionTypes.FETCH_MEDIA):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_MEDIA):
        case REQUEST(ActionTypes.DELETE_MEDIA):
        case REQUEST(ActionTypes.UPDATE_MEDIA):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_MEDIAS):
        case FAILURE(ActionTypes.FETCH_MEDIA):
        case FAILURE(ActionTypes.CREATE_MEDIA):
        case FAILURE(ActionTypes.DELETE_MEDIA):
        case FAILURE(ActionTypes.UPDATE_MEDIA):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_MEDIA):
        case SUCCESS(ActionTypes.UPDATE_MEDIA):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_MEDIAS):
        case SUCCESS(ActionTypes.FETCH_MEDIA):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_MEDIA):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default MediaState;

const apiUrl = 'media';

export const getAllMedias = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_MEDIAS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().media.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllMedias());
    return result;
}


export const getMedia = (id) =>({
    type: ActionTypes.FETCH_MEDIA,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createMedia = (media) => ({
    type: ActionTypes.CREATE_MEDIA,
    payload: connection(`${apiUrl}/admin`, 'POST', media)
});

export const updateMedia = (media) => ({
    type: ActionTypes.UPDATE_MEDIA,
    payload: connection(`${apiUrl}/admin`, 'PUT', media)
});

export const deleteMedia = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_MEDIA,
        payload: connection(`${apiUrl}/admin/${id}`, 'DELETE', null)
    });
    dispatch(getAllMedias());
    return result;
};