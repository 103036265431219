import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import SaleCreator from "./saleCreator";
import Sales from "./sales";
import SaleView from "./sale";

const Sale = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={SaleCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={SaleView}/>
            <PrivateRoute exact path={`${match.url}`} component={Sales}/>
        </Switch>
    </>
);

export default Sale;