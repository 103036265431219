export const fields = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Adres',
        id: 'location',
        type: 'text',
    },
    {
        name: 'Deweloper',
        id: 'developer',
        type: 'text',
    },
    {
        name: 'Zdjęcia',
        id: 'images',
        type: 'files',
    },
    {
        name: 'Adres strony internetowej',
        id: 'website',
        type: 'text',
    },
    {
        name: 'Szerokość geograficzna',
        id: 'latitude',
        type: 'number',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Długość geograficzna',
        id: 'longitude',
        type: 'number',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Projekty',
        id: 'designs',
        type: 'multiSelect',
    },
    {
        name: 'Link do dziennika budowy',
        id: 'journalLink',
        type: 'text',
    },
    {
        name: 'Widoczny w Deweloper - Osiedla deweloperskie',
        id: 'showDev',
        type: 'checkbox',
    },
    {
        name: 'Widoczny w ABC budowy - kup dom dla dewelopera',
        id: 'showAbc',
        type: 'checkbox',
    },
];