import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_PURCHASES: 'purchase/FETCH_ALL_PURCHASES',
    FETCH_PURCHASE: 'purchase/FETCH_PURCHASE',
    UPDATE_PURCHASE: 'purchase/UPDATE_PURCHASE',
    CREATE_PURCHASE: 'purchase/CREATE_PURCHASE',
    DELETE_PURCHASE: 'purchase/DELETE_PURCHASE',
    TOGGLE_PAYMENT: 'purchase/TOGGLE_PAYMENT',
    CHANGE_PAGINATION: 'purchase/CHANGE_PAGINATION',
    RESET: 'purchase/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const PurchaseState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_PURCHASES):
        case REQUEST(ActionTypes.FETCH_PURCHASE):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_PURCHASE):
        case REQUEST(ActionTypes.DELETE_PURCHASE):
        case REQUEST(ActionTypes.UPDATE_PURCHASE):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.CREATE_PURCHASE):
        case FAILURE(ActionTypes.FETCH_ALL_PURCHASES):
        case FAILURE(ActionTypes.FETCH_PURCHASE):
        case FAILURE(ActionTypes.DELETE_PURCHASE):
        case FAILURE(ActionTypes.UPDATE_PURCHASE):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_PURCHASE):
        case SUCCESS(ActionTypes.UPDATE_PURCHASE):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_PURCHASES):
        case SUCCESS(ActionTypes.FETCH_PURCHASE):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_PURCHASE):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default PurchaseState;

const apiUrl = 'purchase/admin';

export const getAllPurchases = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_PURCHASES,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().purchase.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllPurchases());
    return result;
}


export const getPurchase = (id) =>({
    type: ActionTypes.FETCH_PURCHASE,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const updatePurchase = (purchase) => ({
    type: ActionTypes.UPDATE_PURCHASE,
    payload: connection(`${apiUrl}`, 'PUT', purchase)
});

export const createPurchase = (purchase) => ({
    type: ActionTypes.CREATE_PURCHASE,
    payload: connection(`${apiUrl}`, 'POST', purchase)
});

export const togglePayment = (id) => ({
    type: ActionTypes.TOGGLE_PAYMENT,
    payload: connection(`${apiUrl}/payment/${id}`, 'PUT', null, true)
});

export const deletePurchase = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_PURCHASE,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllPurchases());
    return result;
};