export const descriptionFields = [
    {
        name: 'Opis',
        id: 'editor',
        type: 'page',
    }
    ];

export const downloadableFields = [
    {
        name: 'Rysunek szczegółowy',
        id: 'drawing.file',
        type: 'file',
    },
    {
        name: 'Rysunek szczegółowy - lustrzane odbicie',
        id: 'drawingMirror.file',
        type: 'file',
        mirror: true
    },
    {
        name: 'Obrys domu w PDF',
        id: 'housePdf.file',
        type: 'file',
    },
    {
        name: 'Obrys domu w DWG',
        id: 'houseDwg.file',
        type: 'file',
    },
    {
        name: 'Kosztorys uproszczony',
        id: 'estimate.file',
        type: 'file',
    },
    {
        name: 'Zestawienie materiałów',
        id: 'materials.file',
        type: 'file',
        numberOfSubscribers: 'materials.numberOfSubscribers'
    }
];

export const estimateFields = [
    {
        name: 'Opis',
        id: 'editor',
        type: 'page',
    },
    {
        name: 'Stan surowy zamknięty - koszty minimalne',
        id: 'basicMin',
        type: 'number',
    },
    {
        name: 'Stan surowy zamknięty - koszty średnie',
        id: 'basicMean',
        type: 'number',
    },
    {
        name: 'Stan surowy zamknięty metodą gospodarczą - koszty minimalne',
        id: 'basicEconomicMin',
        type: 'number',
    },
    {
        name: 'Stan surowy zamknięty metodą gospodarczą - koszty średnie',
        id: 'basicEconomicMean',
        type: 'number',
    },
    {
        name: 'Prace wykończeniowe - koszty minimalne',
        id: 'finishingMin',
        type: 'number',
    },
    {
        name: 'Prace wykończeniowe - koszty średnie',
        id: 'finishingMean',
        type: 'number',
    },
    {
        name: 'Instalacje - koszty minimalne',
        id: 'installationMin',
        type: 'number',
    },
    {
        name: 'Instalacje - koszty średnie',
        id: 'installationMean',
        type: 'number',
    },
    {
        name: 'Zestawienie materiałów',
        id: 'materials',
        type: 'file',
        image: true,
        noAlt: true,
    },
    {
        name: 'Uproszczony kosztorys',
        id: 'simplified',
        type: 'file',
        image: true,
        noAlt: true,
    }
];

export const projectionFields = [
    {
        name: 'Rzuty',
        id: 'projections',
        type: 'list',
        values: [
            {
                name: 'Nazwa rzutu',
                id: 'name',
                type: 'text',
            },
            {
                name: 'Lista elementów',
                id: 'projectionElements',
                type: 'list',
                nested: true,
                values: [
                    {
                        name: 'Nazwa',
                        id: 'name',
                        type: 'text',
                    },
                    {
                        name: 'Numer',
                        id: 'identifier',
                        type: 'text',
                    },
                    {
                        name: 'Powierzchnia',
                        id: 'area',
                        type: 'number',
                    },
                    {
                        name: 'Po podłodze',
                        id: 'floor',
                        type: 'number',
                    }
                ]
            },
            {
                name: 'Zdjęcie rzutu',
                id: 'image',
                type: 'file',
                image: true,
            },
            {
                name: 'Zdjęcie rzutu - Lustrzane odbicie',
                id: 'mirrorImage',
                type: 'file',
                image: true,
                mirror: true
            },
            {
                name: 'Zdjęcie rzutu z legendą',
                id: 'imageWithLegend',
                type: 'file',
                image: true,
            },
            {
                name: 'Zdjęcie rzutu z legendą - Lustrzane odbicie',
                id: 'mirrorImageWithLegend',
                type: 'file',
                image: true,
                mirror: true
            },
        ]
    },
    // {
    //     name: 'Rysunek szczegółowy',
    //     id: 'detailedDraft',
    //     type: 'file',
    //     image: true,
    // },
    // {
    //     name: 'Rysunek szczegółowy - Lustrzane odbicie',
    //     id: 'mirrorDetailedDraft',
    //     type: 'file',
    //     image: true,
    //     mirror: true
    // },
    {
        name: 'Analiza działki',
        id: 'plotImage',
        type: 'file',
        image: true,
    },
    {
        name: 'Analiza działki - Lustrzane odbicie',
        id: 'mirrorPlotImage',
        type: 'file',
        image: true,
        mirror: true
    },
    {
        name: 'Wizualizacje',
        id: 'visualImages',
        type: 'files',
        image: true,
    },
    {
        name: 'Wizualizacje - Lustrzane odbicie',
        id: 'mirrorVisualImages',
        type: 'files',
        image: true,
        mirror: true
    }
];

export const technicalFields = [
    {
        name: 'Energooszczędność',
        id: 'editorEnergy',
        type: 'page',
    },
    {
        name: 'Wskaźnik Euco+w (kWh/m2 rok)',
        id: 'euco',
        type: 'number',
    },
    {
        name: 'Wskaźnik Ep (kWh/m2 rok)',
        id: 'ep',
        type: 'number',
    },
    {
        name: 'Rozwiązania alternatywne',
        id: 'editor',
        type: 'page',
        // values: [
        //     {
        //         name: 'Podpunkt',
        //         id: 'value',
        //         type: 'textarea',
        //     }
        // ]
    },
    {
        name: 'Informacje',
        id: 'information',
        type: 'class',
        values: [
            {
                name: 'Powierzchnia użytkowa',
                id: 'information.areaUse',
                type: 'number',
            },
            {
                name: 'Powierzchnia garażu',
                id: 'information.areaGarage',
                type: 'number',
            },
            {
                name: 'Powierzchnia podłóg (bez garażu)',
                id: 'information.areaNoGarage',
                type: 'number',
            },
            {
                name: 'Powierzchnia zabudowy',
                id: 'information.areaBuilding',
                type: 'number',
            },
            {
                name: 'Powierzchnia całkowita',
                id: 'information.areaFull',
                type: 'number',
            },
            {
                name: 'Kubatura brutto',
                id: 'information.volume',
                type: 'number',
            },
            {
                name: 'Wysokość budynku',
                id: 'information.height',
                type: 'number',
            },
            {
                name: 'Powierzchnia dachu',
                id: 'information.areaRoof',
                type: 'number',
            },
            {
                name: 'Kąt nachylenia dachu',
                id: 'information.roofAngle',
                type: 'number',
            },
            {
                name: 'Wysokość do okapu dachu',
                id: 'information.heightUnderHood',
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki, X',
                id: 'information.minimalPlotX',
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki, Y',
                id: 'information.minimalPlotY',
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki po adaptacji, X',
                id: 'information.minimalPlotAdaptX',
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki po adaptacji, Y',
                id: 'information.minimalPlotAdaptY',
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki po adaptacji - opis',
                id: 'information.editorMinimalPlot',
                type: 'page',
            },
            {
                name: 'Minimalne wymiary budynku, X',
                id: 'information.minimalBuildingX',
                type: 'number',
            },
            {
                name: 'Minimalne wymiary budynku, Y',
                id: 'information.minimalBuildingY',
                type: 'number',
            },
        ]
    },
    {
        name: 'Technologia',
        id: 'technology',
        type: 'class',
        values: [
            {
                name: 'Fundamenty',
                id: 'technology.foundations',
                type: 'text',
            },
            {
                name: 'Strop',
                id: 'technology.ceiling',
                type: 'text',
            },
            {
                name: 'Ściany',
                id: 'technology.walls',
                type: 'text',
            },
            {
                name: 'Dach',
                id: 'technology.roof',
                type: 'text',
            },
            {
                name: 'Elewacja',
                id: 'technology.elevation',
                type: 'text',
            },
            {
                name: 'Ogrzewanie',
                id: 'technology.heating',
                type: 'text',
            },
            {
                name: 'Wentylacja',
                id: 'technology.ventilation',
                type: 'text',
            },
            {
                name: 'Rolety podtynkowe',
                id: 'technology.blinds',
                type: 'text',
            }
        ]
    }
];

export const virtualWalkFields = [
    {
        name: 'Pliki, główny plik powinien się nazwyać index.html',
        id: 'files',
        type: 'files',
        virtual: true,
    }
];

export const similarFields = [
    {
        name: 'Projekty podobne',
        id: 'similar',
        type: 'multiSelect',
    }
];

export const addons = [
    {
        name: 'Dodatki projektu',
        id: 'addons',
        type: 'multiSelect',
    }
];

export const rotationImages = [
    {
        name: 'Zdjęcia do obrotów 3d',
        id: 'rotationImages',
        type: 'files',
        image: true,
    }
];

export const images = [
    {
        name: 'Zdjęcia bryły (zalecane 1920 x 1080)',
        id: 'images',
        type: 'files',
        image: true,
        required: 'To pole jest wymagane',
    },
    {
        name: 'Zdjęcia wnętrza (zalecane 1920 x 1080)',
        id: 'interiorImages',
        type: 'files',
        image: true,
    },
    {
        name: 'Zdjęcia bryły - lustrzane odbicie (zalecane 1920 x 1080)',
        id: 'imagesMirror',
        type: 'files',
        image: true,
        required: 'To pole jest wymagane',
        mirror: true,
    },
    {
        name: 'Zdjęcia wnętrza - lustrzane odbicie (zalecane 1920 x 1080)',
        id: 'interiorImagesMirror',
        type: 'files',
        image: true,
        mirror: true,
    }
];