export const fields = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    // {
    //     name: 'Slug',
    //     id: 'slug',
    //     type: 'text',
    //     required: 'To pole jest wymagane',
    // },
    {
        name: 'Opis',
        id: 'description',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Stara cena',
        id: 'previousPrice',
        type: 'number',
    },
    {
        name: 'Aktualna cena',
        id: 'price',
        type: 'number',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Zdjęcie',
        id: 'image',
        type: 'file',
    },
    {
        name: 'Projekty',
        id: 'designs',
        type: 'multiSelect',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Strona szczegółowa',
        id: 'details',
        type: 'textarea',
    },
    {
        name: 'Wstążka rekomendujemy',
        id: 'recommend',
        type: 'checkbox',
    },
    {
        name: 'Ukryty na liście darmowych dodatków w ramach pakietu',
        id: 'excludedFromFreeChoice',
        type: 'checkbox',
    },
    {
        name: 'Slug',
        id: 'link',
        type: 'text',
    },
    {
        name: 'Meta tytuł',
        id: 'pageTitle',
        type: 'text',
    },
    {
        name: 'Meta opis',
        id: 'pageDescription',
        type: 'text',
    }
];