import React, {Component} from "react";
import Page from "../../../tools/Page";
import {createSale} from "./sale.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import {getDesignSimple} from "../design/design.reducer";
import {getSimpleAddons} from "../addons/addon.reducer";
import {getSimpleDesignCategories} from "../category/category.reducer";
import {getSimplePackages} from "../addonPackages/addonPackage.reducer";
import SaleForm from "./saleForm";

class SaleCreator extends Component{

    componentDidMount() {
        this.props.getDesignSimple();
        this.props.getSimpleAddons();
        this.props.getSimpleDesignCategories();
        this.props.getSimplePackages();
    }

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        let newData = data;
        newData.id = this.props.match.params.id;
        if(data.designs){
            newData.designs = data.designs.map(el => { return {id: el}})
        }
        if(data.addons){
            newData.addons = data.addons.map(el => { return {id: el}})
        }
        if(data.packages){
            newData.packages = data.packages.map(el => { return {id: el}})
        }
        if(data.categories){
            newData.categories = data.categories.map(el => { return {id: el}})
        }
        newData.isActive = false;
        this.props.createSale(newData);
    }

    render() {
        return (
            <Page header={'Dodaj promocje'}>
                {this.props.packages && this.props.designs && this.props.addons && this.props.categories &&
                    <SaleForm
                        data={{}}
                        packages={this.props.packages}
                        designs={this.props.designs}
                        addons={this.props.addons}
                        categories={this.props.categories}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                }
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({sale, addonPackage, design, addon, category}) => ({
    updateSuccess: sale.updateSuccess,
    data: sale.data,
    packages: addonPackage.data,
    designs: design.data,
    addons: addon.data,
    categories: category.data,
});

const mapDispatchToProps = {
    createSale,
    getSimplePackages,
    getSimpleDesignCategories,
    getSimpleAddons,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleCreator);