export const fields = [

    {
        name: 'Imię i nazwisko',
        id: 'name',
        type: 'text',
    },
    {
        name: 'Telefon',
        id: 'phone',
        type: 'text',
    },
    {
        name: 'Email',
        id: 'email',
        type: 'text',
    },
    {
        name: 'Wiadomość',
        id: 'message',
        type: 'text',
    },



    {
        name: 'Szerokość działki w metrach',
        id: 'plotWidth',
        type: 'text',
    },
    {
        name: 'Głębokość działki w metrach',
        id: 'plotDepth',
        type: 'text',
    },
    {
        name: 'Minimalna powierzchnia domu w metrach',
        id: 'areaMin',
        type: 'text',
    },
    {
        name: 'Maksymalna powierzchnia domu w metrach',
        id: 'areaMax',
        type: 'text',
    },
    {
        name: 'Minimalny kąt nachylenia dachu w stopniach',
        id: 'angleMin',
        type: 'text',
    },
    {
        name: 'Maksymalny kąt nachylenia dachu w stopniach',
        id: 'angleMax',
        type: 'text',
    },



    {
        name: 'Ilość kondygnacji',
        id: 'storey',
        type: 'text',
    },
    {
        name: 'Rodzaj dachu',
        id: 'roofType',
        type: 'text',
    },
    {
        name: 'Liczba garaży',
        id: 'numberOfGarages',
        type: 'text',
    },
    {
        name: 'Liczba sypialni',
        id: 'numberOfBedrooms',
        type: 'text',
    },



    {
        name: 'Antresola',
        id: 'entresol',
        type: 'checkbox',
    },
    {
        name: 'Spiżarnia',
        id: 'pantry',
        type: 'checkbox',
    },
    {
        name: 'Sauna',
        id: 'sauna',
        type: 'checkbox',
    },
    {
        name: 'Pralnia',
        id: 'laundry',
        type: 'checkbox',
    },
    {
        name: 'Zadaszony taras',
        id: 'terrace',
        type: 'checkbox',
    },
    {
        name: 'Kominek',
        id: 'fireplace',
        type: 'checkbox',
    },
    {
        name: 'Pomieszczenie rekreacyjne',
        id: 'recreationRoom',
        type: 'checkbox',
    },
    {
        name: 'Master bedroom',
        id: 'masterBedroom',
        type: 'checkbox',
    },
    {
        name: 'Poddasze do adaptacji',
        id: 'attic',
        type: 'checkbox',
    },
    {
        name: 'Wjazd od południa',
        id: 'southEntrance',
        type: 'checkbox',
    },



    {
        name: 'Data',
        id: 'date',
        type: 'text',
    }
    
];