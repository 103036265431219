import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getEstate, updateEstate} from "./estate.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";


class Estate extends Component{

    columns = fields;

   componentDidMount() {
       this.props.getDesignSimple();
       this.props.getEstate(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.data.id;
       if(data.designs) {
           data.designs = data.designs.map((el) => {
               return {id: el}
           });
       }
       this.props.updateEstate(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {
        this.columns[7].values=this.props.designs;
      return (
          <>
              <Page header={`Osiedle ${this.props.match.params.id}`}>
              { this.props.data && !this.props.data.content && this.props.designs && !this.props.designs.content &&
                <GenericForm
                    content={this.columns}
                    data={{
                        ...this.props.data,
                        designs: this.props.data.designs.map((e) => {return e.id.toString() }),
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({estate, design}) => ({
    data: estate.data,
    designs: design.data,
});

const mapDispatchToProps = {
    getEstate,
    updateEstate,
    getDesignSimple,
};

export default connect(mapStateToProps, mapDispatchToProps)(Estate);