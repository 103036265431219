import React, {Component} from "react";
import {connect} from "react-redux";
import {changeType, addCategory} from "./document.reducer";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import {Draft} from "./draft";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";



class Drafts extends Component{

    componentDidMount() {
        this.props.changeType(this.props.prefix, this.props.type, this.props.userId);
    }
    
    render() {
        return(
            <>

                { !this.props.disableAdd &&
                    <Grid container justify='center' spacing='1'>
                        <Grid item>
                            <Link to={`draft/${this.props.userId}/type/${this.props.type}`}>
                                <button className="btn btn-primary">Dodaj projekt</button>
                            </Link>
                        </Grid>
 
                    </Grid>
                }
                {this.props.data && this.props.data.length > 0 ? (Array.isArray(this.props.data) ?
                <List style={{width: "100%"}}>
                    {this.props.data.map((item, index) => (
                        <ListItem key={index}>
                            <Draft draft={item} type={this.props.type}/>
                        </ListItem>
                    ))}
                </List>
                    :
                    <Draft draft={this.props.data}/>)
                    :
                    <p>Brak dokumentów</p>
                }
            </>
        )
    }
}

const mapStateToProps = ({document}) => ({
    data: document.data,
});

const mapDispatchToProps = {
    changeType,
    addCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(Drafts);
