import {Grid} from "@material-ui/core";
import {addressData, hasInvoice, Message, Nip, ServicePoint, TopClient, invoiceData} from "./constants";
import React from "react";
import {renderField} from "../../../../modules/form/input";

export const Client = ({data, methods}) => {

    const invoice = methods.watch('invoice');
    const delivery = methods.watch('delivery');

    return (
        <Grid container spacing={2} justify='space-between'>

            <Grid item xs={12}>
                <label className='form-label' style={{fontWeight: "bold", fontSize: '24px'}}>Dane klienta</label>
            </Grid>

            {TopClient.map((item, index) =>
                <Grid item xs={6}>
                    {renderField(item, data, index, methods, false)}
                </Grid>
            )}

            {
                delivery && delivery === 'SERVICE_POINT' &&
                <Grid item xs={6}>
                    {renderField(ServicePoint, data, 0, methods, false)}
                </Grid>
            }

            <Grid item xs={12}>
                {renderField(Message, data, 0, methods, false)}
            </Grid>

            {addressData('sendData').map((item, index) =>
                <Grid item xs={6}>
                    {renderField(item, data, index, methods, false)}
                </Grid>
            )}
            <Grid item xs={12}>
                {renderField(hasInvoice, data, 0, methods, false)}
            </Grid>
            {
                invoice && <>
                    <Grid item xs={6}>
                        {renderField(Nip, data, 0, methods, false)}
                    </Grid>
                    {invoiceData('invoiceData').map((item, index) =>
                        <Grid item xs={6}>
                            {renderField(item, data, index, methods, false)}
                        </Grid>
                    )}
                </>
            }


        </Grid>
    );
}

export default Client;