import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../../modules/form/GenericForm';
import {getCategory, updateCategory} from "./category.reducer";
import {fields} from "./constants";
import Page from "../../../../tools/Page";


class Category extends Component{

   componentDidMount() {
       this.props.getCategory(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.data.id;
       this.props.updateCategory(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {
      return (
          <>
              <Page header={`Kategoria artykułu ${this.props.match.params.id}`}>
              { this.props.data &&
                <GenericForm
                    content={fields}
                    data={this.props.data}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({articleCategory}) => ({
   data: articleCategory.data
});

const mapDispatchToProps = {
    getCategory,
    updateCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);