import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Products from "./products";
import Client from "./client";
import Grid from "@material-ui/core/Grid";
import { renderField } from "../../../../modules/form/input";
import { userField } from "./constants";

export const PurchaseForm = ({ data, save, cancel, designs, addons, packages, user }) => {
    const methods = useForm({
        defaultValues: {
            ...data,
            invoice: data.invoiceData !== null,
            products: data.products ? data.products.map(el => {
                return {
                    ...el,
                    prodType: el.boughtDesign === null ? (el.boughtAddon === null ? 'package' : 'addon') : 'design',
                }
            }) : [],
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(save)}>
                <div className="col-xs-12 col-sm-9 col-md-8">
                    <div style={{ margin: '40px' }}>
                        <Grid container direction='column' spacing={10}>
                            {user &&
                                <Grid item>
                                    {renderField(userField, data, 0, methods, false)}
                                </Grid>
                            }
                            <Grid item>
                                <Products data={data} methods={methods} addons={addons} designs={designs}
                                    packages={packages} />
                            </Grid>
                            <Grid item>
                                <Client data={data} methods={methods} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-9 col-md-8 padding-bottom-30">
                    <div className="text-left">
                        <button type='button' className='btn' onClick={cancel}>Wróć</button>
                        <input className='btn btn-primary' type='submit' value='Zapisz' />
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}

export default PurchaseForm;