import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getArticle, updateArticle} from "./article.reducer";
import {getSimpleCategories} from "./category/category.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";
import uploadFile from "../../../tools/uploadFile";


class Article extends Component{

    columns = fields;

    state={
        images: [],
    }

   componentDidMount() {
       this.props.getArticle(this.props.match.params.id);
       this.props.getSimpleCategories();
   }


    handleFileUpload = (img) => {
        console.log(img);
        return uploadFile(`file/admin`, img).then(
            (result) => {
                this.setState((previous) => {
                    return {
                        images: [...previous.images, result]
                    }
                });
                return result;
            }
        )
    }

   save(data){
       data.id = this.props.data.id;
       data.category = {id: data.category};
       data.images = this.state.images.map(el => {
           return {
               id: el.id
           }
       })
       this.props.updateArticle(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {
       this.columns[1].callback = this.handleFileUpload;
       this.columns[4].values=this.props.categories;
      return (
          <>
              <Page header={`Artykuł - ${this.props.data && this.props.data.title}`}>
                  {/*{this.props.data && this.props.data.thread &&*/}
                  {/*<div className='pull-right'>*/}
                  {/*    <Link to={`/forum/thread/${this.props.data.thread.id}`}>*/}
                  {/*        <button className="btn btn-primary">Przejdź do wątku</button>*/}
                  {/*    </Link>*/}
                  {/*</div>*/}
                  {/*}*/}
              { this.props.data &&
                <GenericForm
                    content={this.columns}
                    data={{
                        ...this.props.data,
                        category: this.props.data.category ? this.props.data.category.id.toString() : '',
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({article, articleCategory}) => ({
    data: article.data,
    categories: articleCategory.data,
});

const mapDispatchToProps = {
    getArticle,
    updateArticle,
    getSimpleCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);