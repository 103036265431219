import {options} from "../filters/statusFilter";

export const addressData = (prefix) => [
    {
        name: 'Imię',
        id: `${prefix}.name`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Nazwisko',
        id: `${prefix}.surname`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Email',
        id: `${prefix}.email`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Telefon',
        id: `${prefix}.phone`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Firma',
        id: `${prefix}.company`,
        type: 'text',
    },
    {
        name: 'Adres (ulica, nr, nr lokalu)',
        id: `${prefix}.address`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Kod pocztowy',
        id: `${prefix}.postCode`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Miasto',
        id: `${prefix}.city`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Kraj',
        id: `${prefix}.country`,
        type: 'text',
        required: "To pole jest wymagane",
    },
];
export const invoiceData = (prefix) => [
    {
        name: 'Imię',
        id: `${prefix}.name`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Nazwisko',
        id: `${prefix}.surname`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Firma',
        id: `${prefix}.company`,
        type: 'text',
    },
    {
        name: 'Adres (ulica, nr, nr lokalu)',
        id: `${prefix}.address`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Kod pocztowy',
        id: `${prefix}.postCode`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Miasto',
        id: `${prefix}.city`,
        type: 'text',
        required: "To pole jest wymagane",
    },
    {
        name: 'Kraj',
        id: `${prefix}.country`,
        type: 'text',
        required: "To pole jest wymagane",
    },
];

export const hasInvoice = {
    name: 'Inne dane do faktury?',
    id: `invoice`,
    type: 'checkbox',
};

export const Nip = {
    name: 'Nip',
    id: `invoiceData.nip`,
    type: 'text',
};

export const TopClient = [
    {
        name: 'Status zamówienia',
        id: `status`,
        type: 'select',
        values: options.map((el) => {
            return {id: el.value, name: el.label}
        }),
        required: "To pole jest wymagane",
    },
    {
        name: 'Sposób Płatności',
        id: `payment`,
        type: 'select',
        values: [
            {
                id: 'ESERVICE',
                name: 'Eservice',
            },
            {
                id: 'TRANSFER',
                name: 'Przelew',
            },
            {
                id: 'ON_DELIVERY',
                name: 'Za pobraniem',
            },
            {
                id: 'CASH_PERSONAL_PICKUP',
                name: 'Płatność gotówką w biurze',
            }
        ],
        required: "To pole jest wymagane",
    },
    {
        name: 'Sposób dostawy',
        id: `delivery`,
        type: 'select',
        values: [
            {
                id: 'COURIER',
                name: 'Kurier',
            },
            {
                id: 'SERVICE_POINT',
                name: 'ServicePoint',
            },
            {
                id: 'PERSONAL',
                name: 'Odbiór osobisty',
            },
        ],
        required: "To pole jest wymagane",
    },
    {
        name: 'Rodzaj dostawy',
        id: 'deliveryType',
        type: 'select',
        values: [
            {
                id: 'BEFORE',
                name: 'Zamów przed 13:00'
            },
            {
                id: 'FIVE',
                name: 'dostawa 1-5 dni',
            },
            {
                id: 'DEFAULT',
                name: 'dostawa do 20 dni'
            }
        ]
    },
];

export const ServicePoint = {
    name: 'ServicePoint',
    id: `servicePointId`,
    type: 'text',
    required: "To pole jest wymagane",
};

export const Message = {
    name: 'Wiadomość',
    id: `message`,
    type: 'textarea',
};

export const ProductsFields = {
    name: 'Produkty',
    id: 'products',
    type: 'list',
    values: [
        {
            name: 'Lustrzane odbicie',
            id: 'mirror',
            type: 'checkbox'
        },
        {
            name: 'Status',
            id: `status`,
            type: 'select',
            values: [
                {
                    id: 'IN_PREPARATION',
                    name: 'W przygotowaniu',
                },
                {
                    id: 'WAITING',
                    name: 'Oczekuje na realizację',
                },
                {
                    id: 'SENT',
                    name: 'Wysłany',
                },
                {
                    id: 'READY_TO_PICKUP',
                    name: 'Gotowe do odbioru',
                },
                {
                    id: 'FINISHED',
                    name: 'Odebrane',
                },
                {
                    id: 'CANCELLED',
                    name: 'Anulowane',
                }
            ],
            required: "To pole jest wymagane",
        },
        {
            name: 'Rodzaj dostawy',
            id: 'deliveryType',
            type: 'select',
            values: [
                {
                    id: 'BEFORE',
                    name: 'Zamów przed 13:00'
                },
                {
                    id: 'FIVE',
                    name: 'dostawa 1-5 dni',
                },
                {
                    id: 'DEFAULT',
                    name: 'dostawa do 20 dni'
                }
            ]
        },
        {
            name: 'Data wysyłki',
            id: `plannedSendDate`,
            type: 'time',
            noTime: true
        },
        {
            name: 'Data odbioru',
            id: `pickUpDate`,
            type: 'time',
            noTime: true
        },
        {
            name: 'Id przesyłki',
            id: `shipmentId`,
            type: 'text',
        },
        {
            name: 'Id kuriera',
            id: `dispatchId`,
            type: 'text',
        },
        {
            name: 'Cena',
            id: `price`,
            type: 'number',
            required: "To pole jest wymagane",
        },
        {
            name: 'Ilość',
            id: `quantity`,
            type: 'number',
            step: '1',
            required: "To pole jest wymagane",
        }
    ],
};

export const userField = {
    id: 'user.id',
    name: 'Id użytkownika',
    type: 'text',
};

export const ProductType = {
    id: 'prodType',
    name: 'Rodzaj produktu',
    type: 'select',
    values: [
        {
            id: 'design',
            name: 'Projekt domu',
        },
        {
            id: 'addon',
            name: 'Dodatek',
        },
        {
            id: 'package',
            name: 'Pakiet dodatków',
        }
    ],
    required: "To pole jest wymagane",
};

export const DesignFields = (designs) => [
    {
        name: 'Projekt',
        id: `boughtDesign`,
        type: 'select',
        values: designs,
        required: "To pole jest wymagane",
    },
];

export const AddonFields = (designs, addons) => [
    {
        name: 'Projekt',
        id: `design`,
        type: 'select',
        values: designs,
        required: "To pole jest wymagane",
    },
    {
        name: 'Dodatek',
        id: `boughtAddon`,
        type: 'select',
        values: addons,
        required: "To pole jest wymagane",
    }
];

export const PackageFields = (designs, packages) => [
    {
        name: 'Projekt',
        id: `design`,
        type: 'select',
        values: designs,
        required: "To pole jest wymagane",
    },
    {
        name: 'Pakiet dodatkóœ',
        id: `boughtAddonPackage`,
        type: 'select',
        values: packages,
        required: "To pole jest wymagane",
    }
];
