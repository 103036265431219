import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getThread, updateThread} from "./thread.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";
import {getSimpleForumCategories} from "../category/forumCategory.reducer";
import {Link} from "react-router-dom";


class Thread extends Component{

   componentDidMount() {
       this.props.getSimpleForumCategories();
       this.props.getThread(this.props.match.params.id);
   }

   columns = fields;

   save(data){
       data.id = this.props.data.id;
       data.category = {id: data.category};
       this.props.updateThread(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {

       this.columns[1].values=this.props.categories;

      return (
          <>
              <Page header={`Wątek ${this.props.match.params.id}`}>
                  {this.props.data && this.props.data.id &&
                  <div className='pull-right'>
                      <Link to={`/forum/post?search=thread:${this.props.data.id}`}>
                          <button className="btn btn-primary">Zobacz posty</button>
                      </Link>
                  </div>
                  }
              { this.props.data && this.props.categories && Array.isArray(this.props.categories) &&
                <GenericForm
                    content={fields}
                    data={{
                        ...this.props.data,
                        category: this.props.data.category ? this.props.data.category.id.toString() : '',
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({thread, forumCategory}) => ({
   data: thread.data,
    categories: forumCategory.data,
});

const mapDispatchToProps = {
    getThread,
    updateThread,
    getSimpleForumCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(Thread);