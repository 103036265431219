import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteSale} from "./sale.reducer";
import Page from "../../../tools/Page";

class Sales extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'isActive', name: 'Aktywny'},
                {id: 'start', name: 'Data startowa'},
                {id: 'end', name: 'Data końcowa'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie promocje'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'plan'}
                    deleteFunction={this.props.deleteSale}
                    slug={'sale'}
                    add={'Dodaj nową promocję'}
                    disableSearch
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({sale}) => ({
    data: sale.data,
    pagination: sale.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteSale
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Sales);