import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_TEXTS: 'text/FETCH_ALL_TEXTS',
    FETCH_TEXT: 'text/FETCH_TEXT',
    UPDATE_TEXT: 'text/UPDATE_TEXT',
    DELETE_TEXT: 'text/DELETE_TEXT',
    CREATE_TEXT: 'text/CREATE_TEXT',
    CHANGE_PAGINATION: 'text/CHANGE_PAGINATION',
    RESET: 'text/RESET',
};

const initState = {
    loading: false,
    pagination: defaultSearchableParams(10),
    data: null,
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const TextState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_TEXTS):
        case REQUEST(ActionTypes.FETCH_TEXT):
            return{
                ...state,
                errorMessage: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_TEXT):
        case REQUEST(ActionTypes.DELETE_TEXT):
        case REQUEST(ActionTypes.UPDATE_TEXT):
            return{
                ...state,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_TEXTS):
        case FAILURE(ActionTypes.FETCH_TEXT):
        case FAILURE(ActionTypes.CREATE_TEXT):
        case FAILURE(ActionTypes.DELETE_TEXT):
        case FAILURE(ActionTypes.UPDATE_TEXT):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.CREATE_TEXT):
        case SUCCESS(ActionTypes.UPDATE_TEXT):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_TEXTS):
        case SUCCESS(ActionTypes.FETCH_TEXT):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_TEXT):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default TextState;

const apiUrl = 'cms/admin/text';

export const getAllStaticTexts = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_TEXTS,
    payload: connection(applyParamsToUrl(`cms/text`,getState().text.pagination), 'GET', null),
});


export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllStaticTexts());
    return result;
}

export const getStaticText = (id) =>({
    type: ActionTypes.FETCH_TEXT,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createStaticText = (staticText) => ({
    type: ActionTypes.CREATE_TEXT,
    payload: connection(`${apiUrl}`, 'POST', staticText)
});

export const updateStaticText = (staticText) => ({
    type: ActionTypes.UPDATE_TEXT,
    payload: connection(`${apiUrl}`, 'PUT', staticText)
});

export const deleteStaticText = (id) =>async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_TEXT,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllStaticTexts());
    return result;
};