import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getJournal, updateJournal} from "./journal.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";


class Journal extends Component{

    columns = fields;

   componentDidMount() {
       this.props.getDesignSimple();
       this.props.getJournal(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.data.id;
       data.design = {id: data.design};
       this.props.updateJournal(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {
       this.columns[4].values = this.props.designs;
      return (
          <>
              <Page header={`Dziennik ${this.props.match.params.id}`}>
              { this.props.data && !this.props.data.content && this.props.designs && Array.isArray(this.props.designs) &&
                <GenericForm
                    content={this.columns}
                    data={{
                        ...this.props.data,
                        design: this.props.data.design ? this.props.data.design.id.toString() : '',
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({journal, design}) => ({
    data: journal.data,
    designs: design.data,
});

const mapDispatchToProps = {
    getJournal,
    updateJournal,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(Journal);