import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_SPECIALS: 'special/FETCH_ALL_SPECIALS',
    FETCH_ALL_SIMPLE_SPECIALS: 'special/FETCH_ALL_SIMPLE_SPECIALS',
    FETCH_SPECIAL: 'special/FETCH_SPECIAL',
    UPDATE_SPECIAL: 'special/UPDATE_SPECIAL',
    DELETE_SPECIAL: 'special/DELETE_SPECIAL',
    CHANGE_PAGINATION: 'special/CHANGE_PAGINATION',
    CREATE_SPECIAL: 'special/CREATE_SPECIAL',
    RESET: 'special/RESET',
};

const initState = {
    loading: false,
    data: null,
    simple: [],
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const SpecialState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_SPECIALS):
        case REQUEST(ActionTypes.FETCH_SPECIAL):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_SPECIAL):
        case REQUEST(ActionTypes.DELETE_SPECIAL):
        case REQUEST(ActionTypes.UPDATE_SPECIAL):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_SPECIALS):
        case FAILURE(ActionTypes.FETCH_SPECIAL):
        case FAILURE(ActionTypes.CREATE_SPECIAL):
        case FAILURE(ActionTypes.DELETE_SPECIAL):
        case FAILURE(ActionTypes.FETCH_ALL_SIMPLE_SPECIALS):
        case FAILURE(ActionTypes.UPDATE_SPECIAL):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_SPECIAL):
        case SUCCESS(ActionTypes.UPDATE_SPECIAL):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_SPECIALS):
        case SUCCESS(ActionTypes.FETCH_SPECIAL):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_SPECIAL):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_SIMPLE_SPECIALS):
            return {
                ...state,
                simple: action.payload,
                loading: false,
            }
        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default SpecialState;

const apiUrl = 'special/admin';

export const getAllSpecials = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_SPECIALS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().special.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllSpecials());
    return result;
}

export const getSpecial = (id) =>({
    type: ActionTypes.FETCH_SPECIAL,
    payload: connection(`special/secured/${id}`, 'GET', null)
});

export const createSpecial = (special) => ({
    type: ActionTypes.CREATE_SPECIAL,
    payload: connection(`${apiUrl}`, 'POST', special)
});

export const updateSpecial = (special) => ({
    type: ActionTypes.UPDATE_SPECIAL,
    payload: connection(`${apiUrl}`, 'PUT', special)
});

export const deleteSpecial = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_SPECIAL,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllSpecials());
    return result;
};