import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteContact} from "./contact.reducer";
import Page from "../../../tools/Page";

class Contacts extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Nazwa'},
                {id: 'email', name: 'Adres email'},
                {id: 'message', name: 'Wiadomość'},
                {id: 'date', name: 'Data'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie formularze z Kontakt'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'kontakt'}
                    deleteFunction={this.props.deleteContact}
                    slug={'contact'}
                    skip={['date']}
                    downloadLink='form/order/admin/raport.xlsx'
                    disableAdd
                    disableEdit
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({contact}) => ({
    data: contact.data,
    pagination: contact.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteContact
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Contacts);