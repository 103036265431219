import { Switch } from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import AdvertCreator from "./advertCreator";
import Adverts from "./adverts";
import AdvertView from "./advert";


const Advert = ({ match }) => (
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={AdvertCreator} />
            <PrivateRoute exact path={`${match.url}/:id`} component={AdvertView} />
            <PrivateRoute exact path={`${match.url}`} component={Adverts} />
        </Switch>
    </>
);

export default Advert;