import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {getDesignSimple, massUpdate} from "./design.reducer";
import {getSimpleDesignCategories} from '../category/category.reducer';
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import {fields} from "./massUpdateFields";

class MassUpdate extends Component{

    componentDidMount() {
        this.props.getSimpleDesignCategories();
        this.props.getDesignSimple();
    }

    data = {
        details: {
            technicalDetails: {
                information: {

                },
                technology: {

                }
            },
            estimate: {

            }
        }
    }

    columns = fields;

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        if(data.categories) {
            data.categories = data.categories.map((el) => {
                return {id: el}
            });
        }
        if(data.designs) {
            data.designs = data.designs.map((el) => {
                return {id: el}
            });
        }
        if(data.design.details.estimate.editor.rows.length === 0){
            data.design.details.estimate.editor = null;
        }
        if(data.design.details.technicalDetails.editor.rows.length === 0){
            data.design.details.technicalDetails.editor = null;
        }
        this.props.massUpdate(data);
    }

    render() {
        this.columns[1].values = this.props.categories;
        this.columns[2].values = this.props.designs;
        return (
            <Page header={'Masowe zarządzanie projektami'}>
                { this.props.categories && Array.isArray(this.props.designs) &&
                    <GenericForm
                        content={this.columns}
                        data={this.data}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                }
                {this.props.updateSuccess &&
                <Redirect to={`/design`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({design, category}) => ({
    updateSuccess: design.updateSuccess,
    designs: design.data,
    categories: category.data,
});

const mapDispatchToProps = {
    massUpdate,
    getSimpleDesignCategories,
    getDesignSimple,
};

export default connect(mapStateToProps, mapDispatchToProps)(MassUpdate);