import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import ArchitectCreator from "./architectCreator";
import Architects from "./architects";
import ArchitectView from "./architect";

const Architect = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={ArchitectCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={ArchitectView}/>
            <PrivateRoute exact path={`${match.url}`} component={Architects}/>
        </Switch>
    </>
);

export default Architect;