import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getSimpleAddonsByDesign, updateDesignAddons} from "./details.reducer";
import {getSimpleAddons} from "../addons/addon.reducer";
import {addons} from "./constants";


class DesignAddons extends Component{

    columns = addons;

    componentDidMount() {
        this.props.getSimpleAddonsByDesign(this.props.designId);
        this.props.getSimpleAddons();
    }

    save(data){
        data.addons = data.addons.map(el => {return {id: el}})
        this.props.updateDesignAddons(this.props.designId, data);
    }


    render() {
        this.columns[0].values = this.props.addons;
        return (
            <>
                { this.props.data && !this.props.loading && this.props.addons && Array.isArray(this.props.addons) &&
                Array.isArray(this.props.data) &&
                <GenericForm
                    content={this.columns}
                    data={{
                        addons: this.props.data.map( el => el.id.toString()),
                    }}
                    save={this.save.bind(this)}
                    cancel={this.props.cancel}
                />
                }
            </>
        );
    }

}

const mapStateToProps = ({details, addon}) => ({
    data: details.data,
    loading: details.loading,
    addons: addon.data,
});

const mapDispatchToProps = {
    getSimpleAddonsByDesign,
    updateDesignAddons,
    getSimpleAddons,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignAddons);