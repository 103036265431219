import React, {Component} from "react";
import {connect} from 'react-redux';
import {createPurchase} from "./purchase.reducer";
import Page from "../../../tools/Page";
import PurchaseForm from "./details/purchaseForm";
import {getSimpleAddons} from "../../design/addons/addon.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {getSimplePackages} from "../../design/addonPackages/addonPackage.reducer";
import {adjustProducts} from "./purchase";
import {Redirect} from "react-router-dom";


class PurchaseCreator extends Component {

    componentDidMount() {
        this.props.getSimpleAddons();
        this.props.getSimplePackages();
        this.props.getDesignSimple();
    }

    save(data) {
        if(data.products) {
            data.products = adjustProducts(data.products);
        }
        this.props.createPurchase(data);
    }

    cancel() {
        this.props.history.goBack();
    }


    render() {
        return (
            <>
                <Page header={`Stwórz zamówienie`}>
                    { Array.isArray(this.props.designs) && Array.isArray(this.props.addons) && Array.isArray(this.props.packages) &&
                    <PurchaseForm
                        data={{}}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                        designs={this.props.designs}
                        addons={this.props.addons}
                        packages={this.props.packages}
                        user
                    />
                    }
                    {this.props.updateSuccess &&
                        <Redirect to={`/purchase/${this.props.data.id}`}/>
                    }
                </Page>
            </>
        );
    }

}

const mapStateToProps = ({purchase, design, addon, addonPackage}) => ({
    data: purchase.data,
    updateSuccess: purchase.updateSuccess,
    designs: design.data,
    addons: addon.data,
    packages: addonPackage.data,

});

const mapDispatchToProps = {
    getSimplePackages,
    getDesignSimple,
    getSimpleAddons,
    createPurchase
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseCreator);