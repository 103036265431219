import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Refunds from "./refunds";

const Refund = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}`} component={Refunds}/>
        </Switch>
    </>
);

export default Refund;