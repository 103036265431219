import React, {useEffect, useCallback, useState} from 'react';
import {useDropzone} from "react-dropzone";
import './fileUploader.scss';
import {Grid} from "@material-ui/core";
import {GridDnD} from "./dnd/gridDND";


const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const createFile = (file) =>{
    return {
        id: null,
        details: '',
        name: '',
        file: '',
        altValue: '',
        isMobileImage: file.isMobileImage,
        preview: URL.createObjectURL(file),
        fileName: file.name,
        path: file.path,
    }
};

const contains = (file, files) => {
    return files.filter(f => file.name === f.fileName).length >0;
};

const isImage = (file) => {
    if(file.endsWith("png") || file.endsWith("jpeg") || file.endsWith("jpg")){
        return true;
    }else{
        return false;
    }
}

const getFileName = (file) => {
    if(file.fileName){
        return file.fileName;
    }else{
        let elements = file.file.split('/');
        return elements[elements.length-1];
    }
}

async function handleNewFiles(files, acceptedFiles, multiple, onChange){
    let file;
    for(file of acceptedFiles){
        let created = createFile(file);
        if(!multiple){
            const base64 = await toBase64(file);
            created.file = `[${file.name}]${base64}`;
            created.orderNumber = 0;
            files = created;
        }else {
            if (!contains(file, files)) {
                const base64 = await toBase64(file);
                created.file = `[${file.name}]${base64}`;
                created.orderNumber = files.length;
                files = files.concat(created);
            }
        }
    }
    onChange(files);
}

export const mb =  1048576;


const FileUploaderController = ({multiple, onChange, content, value}) =>{

  
    const maxSize = content.maxSize || 1048576;

    const {isDragActive, getRootProps, getInputProps, fileRejections} = useDropzone({
        multiple,
        accept: content.accept || '',
        minSize: 0,
        maxSize: maxSize,
        onDrop: useCallback(acceptedFiles => {
            handleNewFiles(value, acceptedFiles, multiple, onChange)
        }, [value, multiple, onChange]),
    });
    
    const [updateCount, setUpdateCount] = useState(0);

    const updateFile = (file) =>{
        const temp = value;
        for (let i = 0; i < temp.length; i++) {
            if(file.id) {
                if (temp[i].id === file.id){
                    temp[i] = file;
                }
            }else {
                if(temp[i].preview === file.preview){
                    temp[i] = file;
                }
            }
        }
        onChange(temp);
    };

    const setName = (file) => (e) =>{
        file.name = e.target.value;
        updateFile(file);
    }

    const setDetails = (file) => (e) =>{
        file.details = e.target.value;
        updateFile(file);
    }

    const setAlt = (file) => (e) =>{
        file.altValue = e.target.value;
        updateFile(file);
    }
    const setTitle = (file) => (e) =>{
        file.title = e.target.value;
        updateFile(file);
    }

    const setIsMobileImage = (file) => (e) =>{
        file.isMobileImage = !file.isMobileImage;
        let updateCountNew = updateCount
        setUpdateCount(++updateCountNew)
        updateFile(file);
    }

    const removeFile = (file) => {
        let newValue;
        if(multiple) {
            if (file.id) {
                newValue = value.filter(f => f.id !== file.id);
            } else {
                newValue = value.filter(f => f.orderNumber !== file.orderNumber);
            }
        }else{
            newValue = null;
        }
        onChange(newValue);
    };

    const thumb = (file) =>(
        <div className='thumb-box'>
            <Grid container direction='row' alignItems='center' justify='space-between' spacing={7}>
                <Grid item xs={10}>
                    <div className='thumb'>
                        <div className='thumbInner'>
                            {isImage(file.fileName || file.file) ?
                                <img className='thumbImage'
                                     src={file.preview || file.file}
                                     alt="Thumbimage"
                                />
                                :
                                <p>{getFileName(file)}</p>
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction='column' justify='center' spacing={1}>
                        { (!content.image || content.image.name) &&
                        <Grid item>
                            <Grid container spacing={2} justify='space-between' alignItems='center'>
                                <Grid item xs={2}>
                                    <label className='form-label'>Nazwa</label>
                                </Grid>
                                <Grid item xs={10}>
                                    <input className='form-control' type='text' onChange={setName(file)}
                                           defaultValue={file.name}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {(!content.image || content.image.description) &&
                        <Grid item>
                            <Grid container spacing={2} justify='space-between' alignItems='center'>
                                <Grid item xs={2}>
                                    <label className='form-label'>Opis</label>
                                </Grid>
                                <Grid item xs={10}>
                                    <input className='form-control' type='text' onChange={setDetails(file)}
                                           defaultValue={file.details}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {!content.noAlt &&
                        <Grid item>
                            <Grid container spacing={2} justify='space-between' alignItems='center'>
                                <Grid item xs={2}>
                                    <label className='form-label'>Alt</label>
                                </Grid>
                                <Grid item xs={10}>
                                    <input className='form-control' type='text' onChange={setAlt(file)}
                                           defaultValue={file.altValue}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {content.documentTitle &&
                        <Grid item>
                            <Grid container spacing={2} justify='space-between' alignItems='center'>
                                <Grid item xs={2}>
                                    <label className='form-label'>Tytuł dokumentu</label>
                                </Grid>
                                <Grid item xs={10}>
                                    <input className='form-control' type='text' required onChange={setTitle(file)}
                                           defaultValue={file.documentTitle}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {(!content.isMobileImage || content.image.isMobileImage) &&
                        <Grid item>
                            <Grid container spacing={2} justify='space-between' alignItems='center'>
                                <Grid item xs={10}>
                                    <label className='form-label'>Wyświetlaj tylko dla wersji mobilnej<br/> <span style={{fontSize: '11px'}}>zalecana wielkość (575 x 1020px)</span></label>
                                </Grid>
                                <Grid item xs={2}>
                                    <input className='form-control onlyMobile' disabled={content.documentTitle ? true : false} type='checkbox' onChange={setIsMobileImage(file)}
                                           checked={file.isMobileImage} />
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {/* Remove file  */}
                        <Grid item>
                            <Grid container spacing={2} justify='space-between' alignItems='center'>
                                <Grid item xs={10}>
                                    <label className='form-label' style={{color: "red", cursor: "pointer", fontWeight: "bold", userSelect: "none"}} onClick={() => removeFile(file)}>Usuń</label>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );

    const saveOrder = (items) => {
        for (let i = 0; i < items.length; i++) {
            items[i].orderNumber = i;
        }
        return items
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(value);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        onChange(saveOrder(items));
    }

    const thumbs = () => (
        <GridDnD files={value.sort(function(a,b){return a.orderNumber - b.orderNumber})} renderFile={thumb} moveFiles={handleDragEnd} name={content.id} removeFile={removeFile}/>
    )

    // const thumbsOld = () => (
    //     <DragDropContext onDragEnd={handleDragEnd}>
    //         <Droppable droppableId='thumbs'>
    //             {
    //                 (provided) =>(
    //                     <ul className='thumbs' {...provided.droppableProps} ref={provided.innerRef}>
    //                         {
    //                             value.map((file, index) =>(
    //                                 <Draggable key={file.id || file.preview}
    //                                            draggableId={file.id ? file.id.toString() : file.preview} index={index}>
    //                                     {
    //                                         (secondProvided) => (
    //                                             <li ref={secondProvided.innerRef} {...secondProvided.draggableProps}
    //                                                 {...secondProvided.dragHandleProps}>
    //                                                 {thumb(file)}
    //                                             </li>
    //                                         )
    //                                     }
    //                                 </Draggable>
    //                             ))
    //                         }
    //                         {provided.placeholder}
    //                     </ul>
    //                 )
    //             }
    //         </Droppable>
    //     </DragDropContext>
    // )

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        if(multiple && value &&  value.length >0) {
            value.forEach(file => URL.revokeObjectURL(file.preview));
        }else if(value){

            URL.revokeObjectURL(value.preview);
        }
    }, [multiple, value]);


    return(
        <div>
            {!content.noAdd &&
            <div {...getRootProps()}>
                <input {...getInputProps()}/>
                {isDragActive ? "Upuść!" : 'Naciśnij tutaj lub upuść plik'}
                {fileRejections.length > 0 && fileRejections[0].file.size > maxSize &&
                <div>Plik jest za duży! Maksymalny rozmiar: {maxSize/mb}MB</div>}
                {fileRejections.length > 0 && fileRejections[0].file.size <= maxSize &&
                <div>Niepoprawny rodzaj pliku</div>}
            </div>
            }
            <div>
                {value && (multiple ? (value.length > 0 && thumbs()) : thumb(value)) }
            </div>
        </div>
    );

};

export default FileUploaderController;
