import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_REALIZATIONS: 'realization/FETCH_ALL_REALIZATIONS',
    FETCH_REALIZATION: 'realization/FETCH_REALIZATION',
    UPDATE_REALIZATION: 'realization/UPDATE_REALIZATION',
    DELETE_REALIZATION: 'realization/DELETE_REALIZATION',
    CREATE_REALIZATION: 'realization/CREATE_REALIZATION',
    CHANGE_PAGINATION: 'realization/CHANGE_PAGINATION',
    RESET: 'realization/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const RealizationState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_REALIZATIONS):
        case REQUEST(ActionTypes.FETCH_REALIZATION):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_REALIZATION):
        case REQUEST(ActionTypes.DELETE_REALIZATION):
        case REQUEST(ActionTypes.UPDATE_REALIZATION):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_REALIZATIONS):
        case FAILURE(ActionTypes.FETCH_REALIZATION):
        case FAILURE(ActionTypes.CREATE_REALIZATION):
        case FAILURE(ActionTypes.DELETE_REALIZATION):
        case FAILURE(ActionTypes.UPDATE_REALIZATION):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }

        case SUCCESS(ActionTypes.CREATE_REALIZATION):
        case SUCCESS(ActionTypes.UPDATE_REALIZATION):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_REALIZATIONS):
        case SUCCESS(ActionTypes.FETCH_REALIZATION):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_REALIZATION):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default RealizationState;

const apiUrl = 'community/realization';

export const getAllRealizations = () =>(dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_REALIZATIONS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().realization.pagination), 'GET', null),
});


export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllRealizations());
    return result;
}

export const getRealization = (id) =>({
    type: ActionTypes.FETCH_REALIZATION,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createRealization = (realization) => ({
    type: ActionTypes.CREATE_REALIZATION,
    payload: connection(`${apiUrl}/admin`, 'POST', realization)
});

export const updateRealization = (realization) => ({
    type: ActionTypes.UPDATE_REALIZATION,
    payload: connection(`${apiUrl}/admin`, 'PUT', realization)
});

export const deleteRealization = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_REALIZATION,
        payload: connection(`${apiUrl}/admin/${id}`, 'DELETE', null)
    });
    dispatch(getAllRealizations());
    return result;
};