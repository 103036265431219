import React, { Component } from "react";
import { connect } from "react-redux";
import GenericForm from "../../../modules/form/GenericForm";
import { getRedirect, updateRedirect } from "./redirect.reducer";
import { fields } from "./constants";
import Page from "../../../tools/Page";

class Redirect extends Component {
  componentDidMount() {
    this.props.getRedirect(this.props.match.params.id);
  }

  save(data) {
    data.id = this.props.data.id;
    this.props.updateRedirect(data);
  }

  cancel() {
    this.props.history.goBack();
  }

  render() {
    return (
      <>
        <Page header={`Przekierowanie ${this.props.match.params.id}`}>
          {this.props.data && (
            <GenericForm
              content={fields}
              data={this.props.data}
              save={this.save.bind(this)}
              cancel={this.cancel.bind(this)}
            />
          )}
        </Page>
      </>
    );
  }
}

const mapStateToProps = ({ redirect }) => ({
  data: redirect.data,
});

const mapDispatchToProps = {
  getRedirect,
  updateRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
