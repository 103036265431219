import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_NEWSLETTERS: 'newsletter/FETCH_ALL_NEWSLETTERS',
    DELETE_NEWSLETTER: 'newsletter/DELETE_NEWSLETTER',
    CHANGE_PAGINATION: 'newsletter/CHANGE_PAGINATION',
    RESET: 'newsletter/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    errorMessage: null,
};

const NewsletterState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_NEWSLETTERS):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
            };
        case REQUEST(ActionTypes.DELETE_NEWSLETTER):
            return{
                ...state,
                data: null,
                errorMessage: null,
            };

        case FAILURE(ActionTypes.FETCH_ALL_NEWSLETTERS):
        case FAILURE(ActionTypes.DELETE_NEWSLETTER):
            return {
                ...state,
                errorMessage: action.payload,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };
        case SUCCESS(ActionTypes.FETCH_ALL_NEWSLETTERS):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_NEWSLETTER):
            return {
                ...state,
                data: null,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default NewsletterState;

const apiUrl = 'form/newsletter/admin';

export const getAllNewsletters = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_NEWSLETTERS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().newsletter.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllNewsletters());
    return result;
};

export const deleteNewsletter = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_NEWSLETTER,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllNewsletters());
    return result;
};