import React, {useState} from "react";
import Editor from '@react-page/editor'
import slate from '@react-page/plugins-slate';
import customImagePlugin from "./ImagePlugin";

import divider from '@react-page/plugins-divider';
import spacer from '@react-page/plugins-spacer';
import video from '@react-page/plugins-video';

import {createStyles} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import '@react-page/editor/lib/index.css';
import '@react-page/plugins-slate/lib/index.css';
import '@react-page/plugins-spacer/lib/index.css';
import '@react-page/plugins-video/lib/index.css';

import './page-editor.scss';


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        toolbar: theme.mixins.toolbar,
        main: {
            flexGrow: 1,
            padding: theme.spacing(4),
            backgroundColor: '#eee',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(4),
            backgroundColor: 'white',
            maxWidth: 1050,
            margin: 'auto',
        },
    })
);


export const PageEditor = ({value, onChange, uploader}) => {
    const [content, setContent] = useState(value);

    const cellPlugins = [
        slate(),
        customImagePlugin(uploader),
        spacer,
        divider,
        video,
    ];

    const handleChange = (e) => {
        setContent(e);
        onChange(e);
    }

    return (
        <OuterLayer>
            <Editor cellPlugins={cellPlugins} value={content} onChange={handleChange}/>
        </OuterLayer>
    );
}

const OuterLayer = ({children}) => {

    const classes = useStyles();
    return (
        <div className='wysywig wysywig--article'>
        <div className={classes.root}>
            <main className={classes.main}>
                <div className={classes.toolbar}/>
                <div className={classes.content}>
                    {children}
                </div>

            </main>
        </div>
        </div>
    );

}

export default PageEditor;