import {useForm} from "react-hook-form";
import React from "react";

export function ResetForm({onSave}){

    const {register, handleSubmit} = useForm();


    return (
        <>
            <form onSubmit={handleSubmit(onSave)}>
                <label htmlFor="user_login">Email<br/></label>
                <input type="text" name="login" className="input" size="20" ref={register}/>
                <input className='btn btn-accent btn-block' type='submit' value='Resetuj hasło' />
            </form>
        </>
    );
}

export default ResetForm;