import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import ShipmentCreatorWeb from "./shipmentCreatorWeb";
import ShipmentCreatorWebEmpty from './shipmentCreatorWebEmpty';
import Shipments from './shipments';


const Purchase = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute  path={`${match.url}/add`} component={ShipmentCreatorWebEmpty}/>
            <PrivateRoute  path={`${match.url}/:source/:id`} component={ShipmentCreatorWeb}/>
            <PrivateRoute  exact path={`${match.url}`} component={Shipments}/>
        </Switch>
    </>
);

export default Purchase;