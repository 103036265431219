export const fields = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Opis',
        id: 'description',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Szczegóły',
        id: 'details',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Cena',
        id: 'price',
        type: 'number',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Sztuk w zestawie',
        id: 'maxCount',
        type: 'number',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Zdjęcie',
        id: 'image',
        type: 'file',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Meta tytuł',
        id: 'pageTitle',
        type: 'text',
    },
    {
        name: 'Meta opis',
        id: 'pageDescription',
        type: 'text',
    }
];