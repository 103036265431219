import {Switch} from 'react-router-dom';
import Architect from './architect';
import Article from './article';
import Estate from './estate';
import Manufacturer from './manufacturer';
import Company from './company';
import PrivateRoute from "../../tools/privateRoute";

const Abc = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/architect`} component={Architect}/>
            <PrivateRoute path={`${match.url}/article`} component={Article}/>
            <PrivateRoute path={`${match.url}/estate`} component={Estate}/>
            <PrivateRoute path={`${match.url}/manufacturer`} component={Manufacturer}/>
            <PrivateRoute path={`${match.url}/company`} component={Company}/>
        </Switch>
    </>
);

export default Abc;