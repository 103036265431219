import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_ARTICLES: 'article/FETCH_ALL_ARTICLES',
    FETCH_ARTICLE: 'article/FETCH_ARTICLE',
    UPDATE_ARTICLE: 'article/UPDATE_ARTICLE',
    DELETE_ARTICLE: 'article/DELETE_ARTICLE',
    CREATE_ARTICLE: 'article/CREATE_ARTICLE',
    CHANGE_PAGINATION: 'article/CHANGE_PAGINATION',
    RESET: 'article/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const ArticleState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_ARTICLES):
        case REQUEST(ActionTypes.FETCH_ARTICLE):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_ARTICLE):
        case REQUEST(ActionTypes.DELETE_ARTICLE):
        case REQUEST(ActionTypes.UPDATE_ARTICLE):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_ARTICLES):
        case FAILURE(ActionTypes.FETCH_ARTICLE):
        case FAILURE(ActionTypes.CREATE_ARTICLE):
        case FAILURE(ActionTypes.DELETE_ARTICLE):
        case FAILURE(ActionTypes.UPDATE_ARTICLE):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }

        case SUCCESS(ActionTypes.CREATE_ARTICLE):
        case SUCCESS(ActionTypes.UPDATE_ARTICLE):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_ARTICLES):
        case SUCCESS(ActionTypes.FETCH_ARTICLE):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_ARTICLE):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default ArticleState;

const apiUrl = 'abc/admin/article';

export const getAllArticles = () =>(dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_ARTICLES,
    payload: connection(applyParamsToUrl(`abc/article`,getState().article.pagination), 'GET', null),
});


export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllArticles());
    return result;
}

export const getArticle = (id) =>({
    type: ActionTypes.FETCH_ARTICLE,
    payload: connection(`abc/article/${id}`, 'GET', null)
});

export const createArticle = (article) => ({
    type: ActionTypes.CREATE_ARTICLE,
    payload: connection(`${apiUrl}`, 'POST', article)
});

export const updateArticle = (article) => ({
    type: ActionTypes.UPDATE_ARTICLE,
    payload: connection(`${apiUrl}`, 'PUT', article)
});

export const deleteArticle = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_ARTICLE,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllArticles());
    return result;
};