import React from 'react';
import {Switch} from 'react-router-dom';
import Contact from './contact';
import Newsletter from './newsletter';
import OrderCatalogue from './orderCatalogue';
import PrivateRoute from "../../tools/privateRoute";
import BoughtDesign from "./boughtDesign";
import OtherBoughtDesign from "./otherBoughtDesign";
import Marketing from './marketing';
import DesignSelection from './designSelection'

const Abc = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/contact`} component={Contact}/>
            <PrivateRoute path={`${match.url}/design-selection`} component={DesignSelection}/>
            <PrivateRoute path={`${match.url}/newsletter`} component={Newsletter}/>
            <PrivateRoute path={`${match.url}/marketing`} component={Marketing}/>
            <PrivateRoute path={`${match.url}/order-catalogue`} component={OrderCatalogue}/>
            <PrivateRoute path={`${match.url}/bought-here`} component={BoughtDesign}/>
            <PrivateRoute path={`${match.url}/bought-elsewhere`} component={OtherBoughtDesign}/>
        </Switch>
    </>
);

export default Abc;