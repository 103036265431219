export const fields = [
    {
        name: 'Strona internetowa',
        id: 'website',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Logo',
        id: 'logo',
        type: 'file',
    },
];