export const fields = [
    {
        name: 'Tytuł',
        id: 'title',
        type: 'text',
    },
    {
        name: 'Kategoria',
        id: 'category',
        type: 'select',
    },
    {
        name: 'Zablokowany?',
        id: 'locked',
        type: 'checkbox',
    },
];