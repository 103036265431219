import React, {useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Button, DialogActions, DialogTitle, Grid} from "@material-ui/core";
import './fileUploader.scss';
import Dialog from "@material-ui/core/Dialog";
import Select from "react-select";
import DialogContent from "@material-ui/core/DialogContent";

// value = list of singleElement
// const singleElement = {
//     orderNumber,
//     category,
// }

//content.values = list of all categories


const getCategoryOptions = (selected, all) => {
    console.log(selected);
    const ids = selected.map(el => el.category.id);
    const filtered = all.filter(el => !ids.includes(el.id));
    return filtered.map(el => {
        return {
            label: el.name,
            value: el,
        }
    })
}

const appendCategories = (originalList, toAppend) => {
    let i = originalList.length - 1;
    const result = toAppend.map(el => {
        i += 1;
        return {
            orderNumber: i,
            category: el.value,
        }
    });
    return [...originalList, ...result];
}


export const CategoriesOrdered = ({value, content, onChange, limit}) => {

    const [open, setOpen] = useState(false);

    const [append, setAppend] = useState([]);

    const [dialog, setDialog] = useState(false);

    const selectStyles = {menu: styles => ({...styles, zIndex: 999})};

    const removeCategory = (file) => {
        let newValue = value.filter(f => f.category.id !== file.category.id);
        onChange(newValue)
    };

    const thumb = (orderedCategory) => (
        <div className='thumb-box' style={{height: '120px'}}>
            <span className="clear" onClick={() => removeCategory(orderedCategory)}>
                    <i className='fa fa-times'/>
            </span>
            <Grid container direction='row' alignItems='center' justify='space-between' spacing={7}>
                <Grid item xs={2}>
                    <div className='thumb'>
                        <div className='thumbInner'>
                            {orderedCategory.category.image ?
                                <img className='thumbImage'
                                     src={orderedCategory.category.image.file}
                                     alt="Thumbimage"
                                />
                                :
                                <p>{orderedCategory.category.name}</p>
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <p>{orderedCategory.category.name}</p>
                </Grid>
            </Grid>
        </div>
    );

    const handleAppend = () => {
        const toSave = appendCategories(value, append);
        console.log(toSave);
        onChange(toSave);
        setAppend([]);
        setOpen(false);
    }

    const saveOrder = (items) => {
        for (let i = 0; i < items.length; i++) {
            items[i].orderNumber = i;
        }
        return items
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(value);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        onChange(saveOrder(items));
    }

    return (
        <>
            <Grid container justify='center'>
                <Grid item>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId='thumbs'>
                            {
                                (provided) => (
                                    <ul className='thumbs' {...provided.droppableProps} ref={provided.innerRef}
                                        style={{'list-style-type': 'none'}}>
                                        {
                                            value.map((item, index) => (
                                                <Draggable key={item.category.id}
                                                           draggableId={item.category.id.toString()} index={index}>
                                                    {
                                                        (secondProvided) => (
                                                            <li ref={secondProvided.innerRef} {...secondProvided.draggableProps}
                                                                {...secondProvided.dragHandleProps}>
                                                                {thumb(item)}
                                                            </li>
                                                        )
                                                    }
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </ul>
                                )
                            }
                        </Droppable>
                    </DragDropContext>
                </Grid>

                <Grid>
                    <button type='button' className='btn btn-primary' onClick={() => setOpen(true)}>Dodaj kategorie
                    </button>
                </Grid>

            </Grid>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle>{`Wybierz kategorie do dodania`}</DialogTitle>
                <DialogContent>
                    <div style={{minHeight: '50vh'}}>
                        <Select
                            isMulti={true}
                            closeMenuOnSelect={false}
                            options={getCategoryOptions(value, content.values)}
                            value={append}
                            onChange={(e) => {
                                console.log(limit);
                                if(limit && value.length + e.length > limit){
                                    setDialog(true);
                                }else{
                                    setAppend(e)
                                    setDialog(false)
                                }
                            }}
                            styles={selectStyles}
                        />
                        {limit && dialog && <span role="alert">{`Maksymalna ilość kategorii: ${limit}`}</span>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setOpen(false)}>
                        Anuluj
                    </Button>
                    <Button color="primary" onClick={handleAppend}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CategoriesOrdered;