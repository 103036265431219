import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericForm from "../../../modules/form/GenericForm";
import { getBoughtDesign } from './otherBoughtDesigns.reducer';
import Page from "../../../tools/Page";
import {fields} from "./constants";
import connection from '../../../tools/connection';



class OtherBoughtDesignView extends Component {
    constructor(props) {
        super(props);
        this.state = { url: null };
    }
    componentDidMount() {
        this.props.getBoughtDesign(this.props.match.params.id);
    }
    save() {

    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.props.data && this.props.data.file && this.props.data.file.file && this.getBoughtProof();
        }
    }
    getBoughtProof() {
        connection(this.props.data.file.file.split('api')[1], 'GET', null, true, false)
            .then(res => res.blob())
            .then(res => { this.setState({ url: URL.createObjectURL(res) }) });
    }
    cancel() {
        this.props.history.goBack();
    }
    render() {
        return (
            <Page header={`Zamówienie projektu ${this.props.match.params.id}`}>
                { this.props.data &&
                    <>
                        <GenericForm
                            content={fields}
                            data={this.props.data}
                            save={this.save.bind(this)}
                            cancel={this.cancel.bind(this)}
                            readOnly
                        />
                        {this.props.data && this.props.data.file && this.props.data.file.file && <a className="btn btn-primary" href={this.state.url} target="_blank" rel="noreferrer">Pobierz dowód zakupu</a> }
                    </>
                }                  
            </Page>
        );
    }
}



const mapStateToProps = ({otherBoughtDesign}) => ({
    data: otherBoughtDesign.data
});
const mapDispatchToProps = {
    getBoughtDesign,
};



export default connect(mapStateToProps, mapDispatchToProps)(OtherBoughtDesignView);