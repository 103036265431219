import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../tools/privateRoute";
import Discount from './discount';
import Special from './special';
import Template from './template';

const Abc = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/discount`} component={Discount}/>
            <PrivateRoute path={`${match.url}/special`} component={Special}/>
            <PrivateRoute path={`${match.url}/template`} component={Template}/>
        </Switch>
    </>
);

export default Abc;