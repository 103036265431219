import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import AddonPackageCreator from "./addonPackageCreator";
import AddonPackages from "./addonPackages";
import AddonPackageView from "./addonPackage";

const AddonPackage = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={AddonPackageCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={AddonPackageView}/>
            <PrivateRoute exact path={`${match.url}`} component={AddonPackages}/>
        </Switch>
    </>
);

export default AddonPackage;