import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_DESIGNS_SIMPLE: 'design/FETCH_ALL_DESIGNS_SIMPLE',
    FETCH_ALL_DESIGNS: 'design/FETCH_ALL_DESIGNS',
    FETCH_DESIGN: 'design/FETCH_DESIGN',
    FETCH_DESIGN_PRICE: 'design/FETCH_DESIGN_PRICE',
    UPDATE_DESIGN: 'design/UPDATE_DESIGN',
    MASS_UPDATE: 'design/MASS_UPDATE',
    DELETE_DESIGN: 'design/DELETE_DESIGN',
    CREATE_DESIGN: 'design/CREATE_DESIGN',
    CHANGE_PAGINATION: 'design/CHANGE_PAGINATION',
    RESET: 'design/RESET',
};

const initState = {
    loading: false,
    pagination: defaultSearchableParams(25),
    data: [],
    updating: false,
    updateSuccess: false,
    errorMessage: null,
    price: []
};

const DesignState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_DESIGNS):
        case REQUEST(ActionTypes.FETCH_ALL_DESIGNS_SIMPLE):
        case REQUEST(ActionTypes.FETCH_DESIGN):
            return{
                ...state,
                data: null,
                errorMessage: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.FETCH_DESIGN_PRICE):
            return{
                ...state,
                price: null,
                errorMessage: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_DESIGN):
        case REQUEST(ActionTypes.DELETE_DESIGN):
        case REQUEST(ActionTypes.UPDATE_DESIGN):
        case REQUEST(ActionTypes.MASS_UPDATE):
            return{
                ...state,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.MASS_UPDATE):
        case FAILURE(ActionTypes.FETCH_ALL_DESIGNS):
        case FAILURE(ActionTypes.FETCH_ALL_DESIGNS_SIMPLE):
        case FAILURE(ActionTypes.FETCH_DESIGN):
        case FAILURE(ActionTypes.FETCH_DESIGN_PRICE):
        case FAILURE(ActionTypes.CREATE_DESIGN):
        case FAILURE(ActionTypes.DELETE_DESIGN):
        case FAILURE(ActionTypes.UPDATE_DESIGN):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };

        case SUCCESS(ActionTypes.MASS_UPDATE):
            return {
                ...state,
                updateSuccess: true,
            }

        case SUCCESS(ActionTypes.CREATE_DESIGN):
        case SUCCESS(ActionTypes.UPDATE_DESIGN):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_DESIGNS):
        case SUCCESS(ActionTypes.FETCH_ALL_DESIGNS_SIMPLE):
        case SUCCESS(ActionTypes.FETCH_DESIGN):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.FETCH_DESIGN_PRICE):
            return {
                ...state,
                price: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_DESIGN):
            return {
                ...state,
                data: {},
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default DesignState;

export const getAllDesigns = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_DESIGNS,
    payload: connection(applyParamsToUrl('design', getState().design.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllDesigns());
    return result;
};

export const getDesign = (name) =>({
    type: ActionTypes.FETCH_DESIGN,
    payload: connection(`design/${name}`, 'GET', null)
});

export const createDesign = (design) => ({
    type: ActionTypes.CREATE_DESIGN,
    payload: connection('admin/design', 'POST', design)
});

export const updateDesign = (design) => ({
    type: ActionTypes.UPDATE_DESIGN,
    payload: connection('admin/design', 'PUT', design)
});

export const deleteDesign = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_DESIGN,
        payload: connection(`admin/design/${id}`, 'DELETE', null)
    });
    dispatch(getAllDesigns());
    return result;
};

export const getDesignSimple = () =>({
    type: ActionTypes.FETCH_ALL_DESIGNS_SIMPLE,
    payload: connection(`design/simple/all`, 'GET', null)
});

export const importData = (file) => ({
    type: ActionTypes.UPDATE_DESIGN,
    payload: connection('design/admin/import', 'PUT', file, true)
});

export const importProjections = (file) => ({
    type: ActionTypes.UPDATE_DESIGN,
    payload: connection('design/projection/admin/import', 'PUT', file, true)
});

export const massUpdate = (data) => ({
    type: ActionTypes.MASS_UPDATE,
    payload: connection('admin/design/multiple', 'PUT', data, true)
});

export const deleteVirtual = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_DESIGN,
        payload: connection(`design/admin/virtual/${id}`, 'DELETE', null)
    });
    dispatch(getDesign(id));
    return result;
};

export const getPrice = (id) =>({
    type: ActionTypes.FETCH_DESIGN_PRICE,
    payload: connection(`admin/archive-prices/design/${id}`, 'GET', null)
});

export const deletePriceData = (id) =>({
    type: ActionTypes.FETCH_DESIGN_PRICE,
    payload: connection(`admin/archive-prices/${id}`, 'DELETE', null)
});