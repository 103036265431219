import isPromise from "is-promise";
import { toast } from "react-toastify";

const notificationMiddleware = () => (next) => (action) => {
  if (!isPromise(action.payload)) {
    return next(action);
  }
  return next(action)
    .then((response) => {
      const type = response.action.type;
      if (type.includes("DELETE")) {
        toast.info("Obiekt usunięto");
      } else if (type.includes("CREATE")) {
        toast.success("Obiekt utworzono");
      } else if (type.includes("UPDATE")) {
        toast.success("Obiekt zapisano");
      }
      return response;
    })
    .catch((error) => {
      if (error.status === 500) {
        return "Błąd serwera";
      } else if (error.status === 401) {
        return "Sesja wygasła";
      } else if (error.status === 201) {
        return "Obiekt utworzono";
      } else {
        return error.text();
      }
    })
    .then((error) => {
      if (error === "Obiekt utworzono") toast.success(error);
      else {
        toast.error(error);
        if (error === "Sesja wygasła") {
          window.location.pathname = "/";
        }
      }
    });
};

export default notificationMiddleware;
