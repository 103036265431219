import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createUser} from "./user.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';


class UserCreator extends Component{

    columns = fields.slice(0, 7);

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        data.design = {id: data.design};
        this.props.createUser(data);
    }

    render() {
        return (
            <Page header={'Dodaj użytkownika'}>
                <GenericForm
                    content={this.columns}
                    data={{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                }
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({user}) => ({
    updateSuccess: user.updateSuccess,
    data: user.data,
});

const mapDispatchToProps = {
    createUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreator);