import React from "react";
import {useForm} from "react-hook-form";

export function LoginForm({onSave}){

    const {register, handleSubmit} = useForm();


    return (
        <>
            <form onSubmit={handleSubmit(onSave)}>
                <label htmlFor="user_login">Email<br/></label>
                    <input type="text" name="login" className="input" size="20" ref={register}/>
                <label htmlFor="user_pass">Hasło<br/></label>
                    <input type="password" name="password" className="input" size="20" ref={register}/>
                <p className="forgetmenot">
                    <label className="icheck-label form-label" htmlFor="rememberMe">
                        <input name="rememberMe"
                               type="checkbox"
                               className="icheck-minimal-aero"
                               ref={register}
                        />
                        Pozostań zalogowanym
                    </label>
                </p>
                <input className='btn btn-accent btn-block' type='submit' value='Zaloguj' />
            </form>
        </>
    );
}

export default LoginForm;