import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getOrderCatalogue} from "./orderCatalogue.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";



class OrderCatalogue extends Component {
    componentDidMount() {
        this.props.getOrderCatalogue(this.props.match.params.id);
    }
    save(){

    }
    cancel(){
        this.props.history.goBack();
    }
    render() {
        return (
            <>
                <Page header={`Zamówienie katalogu ${this.props.match.params.id}`}>
                    { this.props.data &&
                    <GenericForm
                        content={fields}
                        data={this.props.data}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                        readOnly
                    />
                    }
                </Page>
            </>
        );
    }
}



const mapStateToProps = ({orderCatalogue}) => ({
    data: orderCatalogue.data
});
const mapDispatchToProps = {
    getOrderCatalogue,
};



export default connect(mapStateToProps, mapDispatchToProps)(OrderCatalogue);