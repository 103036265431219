import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Item} from './Item';

const SortableItem = (props) => {
    const {
      attributes,
      isDragging,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.item.id});
  
    const style = {
      position: 'relative',
      display: 'inline-block',
      opacity: isDragging ? 0 : undefined,
      transform: CSS.Translate.toString(transform),
      transition,
    };
    
    const closeStyle ={
        position: 'absolute',
        color: 'red',
        right: '3px',
        top: '13px',
        zIndex: 99999,
    }
    const mobileStyle ={
      position: "absolute",
      color: "red",
      left: "0",
      bottom: "13px",
      zIndex: 99999,
      height: "50px",
      width: "100%"
  }

  const btnHandler ={
    position: 'absolute',
    top: '30px',
    right: '30px' ,
    zIndex: 9,
    cursor: 'all-scroll',
    background: '#f1f1f1',
    border: 0,
    padding: '10px'
  }

  const handlemMobile = (e) => 
  {
    e.target.parentElement.querySelector('.onlyMobile').click()
  }

    return (
      <Item
        ref={setNodeRef}
        style={style}
        
      >
        <button style={btnHandler} {...listeners} {...attributes}>
        <i className="fa fa-arrows" aria-hidden="true"></i>
        </button>
            <span style={closeStyle} className="clear" onClick={()=> props.removeFile(props.item)}>
                    <i className='fa fa-times'/>
            </span>
            <span style={mobileStyle} className="clear" onClick={(e) => handlemMobile(e)}>
              {/* <i className="fa fa-mobile"></i> */}
            </span>
            <div>
              {props.renderFile(props.item)}
            </div>
      </Item>
    );
}

export default SortableItem;