import React, {Component} from "react";
import {connect} from 'react-redux';
import {changePagination, deleteDesign} from "./design.reducer";
import GenericTable from "../../../modules/table/GenericTable";
import Page from "../../../tools/Page";

class Designs extends Component{

    columnList = () =>{
        return [
            {id: 'name', name: 'Nazwa'},
            {id: 'creationDate', name: 'Data utworzenia'},
            {id: 'likeNumber', name: 'Liczba polubień'},
            {id: 'price', name: 'Cena'}
        ]
    };

    render() {
        return(
            <Page header='Wszystkie Projekty'>
                <GenericTable changePage={this.props.changePagination}
                              params={this.props.pagination}
                              data={this.props.designs}
                              columns={this.columnList()}
                              title={'projekt'}
                              deleteFunction={this.props.deleteDesign}
                              slug={'design'}
                              add={'Stwórz nowy projekt'}
                              skip={['creationDate','likeNumber', 'price']}
                              downloadLink='design/admin/raport.xlsx'
                              downloadLink2='design/projection/admin/report.xlsx'
                              import
                              mass
                              getXML={true}
                />
            </Page>
        );
    }
}

const mapStateToProps =({design}) =>({
    designs: design.data,
    pagination: design.pagination,
});

const mapDispatchToProps = {
    changePagination,
    deleteDesign
};

export default connect(mapStateToProps, mapDispatchToProps)(Designs);

