import {backend} from "./connection";

const uploadFile = (url, image) =>{
    const formData = new FormData();
    formData.append("file", image);
    return fetch(backend + url, {
        method: 'POST',
        headers:{
            'Authorization': localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') || 'pl',
        },
        body: formData,
    })
        .then(response => {
            if(response.status !== 200){
                return Promise.reject(response);
            }else{
                    return response.json();
            }
        })
};
export default uploadFile;