import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getAddonPackage, updateAddonPackage} from "./addonPackage.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";


class AddonPackage extends Component{

    columns = fields;

   componentDidMount() {
       this.props.getAddonPackage(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.data.id;
       this.props.updateAddonPackage(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {

      return (
          <>
              <Page header={`Pakiet dodatków ${this.props.match.params.id}`}>
              { this.props.data &&
                <GenericForm
                    content={this.columns}
                    data={{
                        ...this.props.data,
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({addonPackage}) => ({
    data: addonPackage.data,
});

const mapDispatchToProps = {
    getAddonPackage,
    updateAddonPackage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonPackage);