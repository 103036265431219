import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import OtherBoughtDesigns from "./otherBoughtDesigns";
import OtherBoughtDesignView from "./otherBoughtDesignView";

const OtherBoughtDesign = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}`} component={OtherBoughtDesigns}/>
            <PrivateRoute path={`${match.url}/:id`} component={OtherBoughtDesignView}/>
        </Switch>
    </>
);

export default OtherBoughtDesign;