import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteAddonPackage} from "./addonPackage.reducer";
import Page from "../../../tools/Page";

class AddonPackages extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Nazwa'},
                {id: 'price', name: 'Cena'},
                {id: 'description', name: 'Opis'}
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie pakiety dodatków'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'pakiet dodatków'}
                    deleteFunction={this.props.deleteAddonPackage}
                    skip={['price']}
                    slug={'addonPackage'}
                    add={'Dodaj nowy pakiet dodatków'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({addonPackage}) => ({
    data: addonPackage.data,
    pagination: addonPackage.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteAddonPackage
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (AddonPackages);