import {Switch} from 'react-router-dom';
import Designs from './designs';
import DesignCreator from './designCreator';
import DesignMain from "../mainDesign";
import PrivateRoute from "../../../tools/privateRoute";
import ImportData from "./importData";
import MassUpdate from "./massUpdate";

const Design = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={DesignCreator}/>
            <PrivateRoute exact path={`${match.url}/update`} component={MassUpdate}/>
            <PrivateRoute exact path={`${match.url}/import`} component={ImportData}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={DesignMain}/>
            <PrivateRoute exact path={`${match.url}`} component={Designs}/>
        </Switch>
    </>
);

export default Design;