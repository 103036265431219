const technicalFields = (prefix) =>[
    {
        name: 'Energooszczędność',
        id: `${prefix}.editorEnergy`,
        type: 'page',
    },
    {
        name: 'Wskaźnik Euco+w (kWh/m2 rok)',
        id: `${prefix}.euco`,
        type: 'number',
    },
    {
        name: 'Wskaźnik Ep (kWh/m2 rok)',
        id: `${prefix}.ep`,
        type: 'number',
    },
    {
        name: 'Rozwiązania alternatywne',
        id: `${prefix}.editor`,
        type: 'page',
        // values: [
        //     {
        //         name: 'Podpunkt',
        //         id: 'value',
        //         type: 'textarea',
        //     }
        // ]
    },
    {
        name: 'Informacje',
        id: `${prefix}.information`,
        type: 'class',
        values: [
            {
                name: 'Powierzchnia użytkowa',
                id: `${prefix}.information.areaUse`,
                type: 'number',
            },
            {
                name: 'Powierzchnia garażu',
                id: `${prefix}.information.areaGarage`,
                type: 'number',
            },
            {
                name: 'Powierzchnia podłóg (bez garażu)',
                id: `${prefix}.information.areaNoGarage`,
                type: 'number',
            },
            {
                name: 'Powierzchnia zabudowy',
                id: `${prefix}.information.areaBuilding`,
                type: 'number',
            },
            {
                name: 'Powierzchnia całkowita',
                id: `${prefix}.information.areaFull`,
                type: 'number',
            },
            {
                name: 'Kubatura brutto',
                id: `${prefix}.information.volume`,
                type: 'number',
            },
            {
                name: 'Wysokość budynku',
                id: `${prefix}.information.height`,
                type: 'number',
            },
            {
                name: 'Powierzchnia dachu',
                id: `${prefix}.information.areaRoof`,
                type: 'number',
            },
            {
                name: 'Kąt nachylenia dachu',
                id: `${prefix}.information.roofAngle`,
                type: 'number',
            },
            {
                name: 'Wysokość do okapu dachu',
                id: `${prefix}.information.heightUnderHood`,
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki, X',
                id: `${prefix}.information.minimalPlotX`,
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki, Y',
                id: `${prefix}.information.minimalPlotY`,
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki po adaptacji, X',
                id: `${prefix}.information.minimalPlotAdaptX`,
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki po adaptacji, Y',
                id: `${prefix}.information.minimalPlotAdaptY`,
                type: 'number',
            },
            {
                name: 'Minimalne wymiary działki po adaptacji - opis',
                id: `${prefix}.information.editorMinimalPlot`,
                type: 'page',
            },
            {
                name: 'Minimalne wymiary budynku, X',
                id: `${prefix}.information.minimalBuildingX`,
                type: 'number',
            },
            {
                name: 'Minimalne wymiary budynku, Y',
                id: `${prefix}.information.minimalBuildingY`,
                type: 'number',
            },
        ]
    },
    {
        name: 'Technologia',
        id: `${prefix}.technology`,
        type: 'class',
        values: [
            {
                name: 'Fundamenty',
                id: `${prefix}.technology.foundations`,
                type: 'text',
            },
            {
                name: 'Strop',
                id: `${prefix}.technology.ceiling`,
                type: 'text',
            },
            {
                name: 'Ściany',
                id: `${prefix}.technology.walls`,
                type: 'text',
            },
            {
                name: 'Dach',
                id: `${prefix}.technology.roof`,
                type: 'text',
            },
            {
                name: 'Elewacja',
                id: `${prefix}.technology.elevation`,
                type: 'text',
            },
            {
                name: 'Ogrzewanie',
                id: `${prefix}.technology.heating`,
                type: 'text',
            },
            {
                name: 'Wentylacja',
                id: `${prefix}.technology.ventilation`,
                type: 'text',
            },
            {
                name: 'Rolety podtynkowe',
                id: `${prefix}.technology.blinds`,
                type: 'text',
            }
        ]
    }
];

const estimateFields = (prefix) => [
    {
        name: 'Opis kosztorysu',
        id: `${prefix}.editor`,
        type: 'page',
    },
    {
        name: 'Stan surowy zamknięty - koszty minimalne',
        id: `${prefix}.basicMin`,
        type: 'number',
    },
    {
        name: 'Stan surowy zamknięty - koszty średnie',
        id: `${prefix}.basicMean`,
        type: 'number',
    },
    {
        name: 'Stan surowy zamknięty metodą gospodarczą - koszty minimalne',
        id: `${prefix}.basicEconomicMin`,
        type: 'number',
    },
    {
        name: 'Stan surowy zamknięty metodą gospodarczą - koszty średnie',
        id: `${prefix}.basicEconomicMean`,
        type: 'number',
    },
    {
        name: 'Prace wykończeniowe - koszty minimalne',
        id: `${prefix}.finishingMin`,
        type: 'number',
    },
    {
        name: 'Prace wykończeniowe - koszty średnie',
        id: `${prefix}.finishingMean`,
        type: 'number',
    },
    {
        name: 'Instalacje - koszty minimalne',
        id: `${prefix}.installationMin`,
        type: 'number',
    },
    {
        name: 'Instalacje - koszty średnie',
        id: `${prefix}.installationMean`,
        type: 'number',
    },
];

const design = (prefix) =>[
    // {
    //     name: 'Nazwa',
    //     id: `${prefix}.name`,
    //     type: 'text',
    // },
    {
        name: 'Numer',
        id: `${prefix}.number`,
        type: 'text',
    },
    {
        name: 'Wariant',
        id: `${prefix}.variant`,
        type: 'text',
    },
    {
        name: 'Cena',
        id: `${prefix}.price`,
        type: 'number',
    },
    {
        name: 'Promocja',
        id: `${prefix}.onSale`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Liczba łazienek',
        id: `${prefix}.numberOfBathrooms`,
        type: 'number',
    },
    {
        name: 'Liczba sypialni',
        id: `${prefix}.numberOfBedrooms`,
        type: 'number',
    },
    {
        name: 'Liczba miejsc postojowych',
        id: `${prefix}.numberOfGarages`,
        type: 'number',
    },
    {
        name: 'Poddasze do adaptacji',
        id: `${prefix}.attic`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Antresola',
        id: `${prefix}.entresol`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Wjazd od południa',
        id: `${prefix}.southEntrance`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Pralnia',
        id: `${prefix}.laundry`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Sauna',
        id: `${prefix}.sauna`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Spiżarnia',
        id: `${prefix}.pantry`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Kominek',
        id: `${prefix}.fireplace`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'master bedroom',
        id: `${prefix}.masterBedroom`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'pomieszczenie rekreacyjne',
        id: `${prefix}.recreationRoom`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Zadaszony taras',
        id: `${prefix}.terrace`,
        type: 'select',
        values: [
            {
                id: false,
                name: 'Nie'
            },
            {
                id: true,
                name: 'Tak'
            }
        ]
    },
    {
        name: 'Kondygnacja',
        id: `${prefix}.storey`,
        type: 'select',
        values: [
            {
                id: 'SINGLE_STOREY',
                name: 'Dom parterowy',
            },
            {
                id: 'MULTI_STOREY',
                name: 'Dom piętrowy'
            },
            {
                id: 'ATTIC_HOUSE',
                name: 'Dom z poddaszem'
            }
        ]
    },
    {
        name: 'Rodzaj dachu',
        id: `${prefix}.roofType`,
        type: 'select',
        values:[
            {
                id: 'FLAT',
                name: 'Płaski'
            },
            {
                id: 'GABLE',
                name: 'Dwuspadowy',
            },
            {
                id: 'HIP',
                name: 'Wielospadowy'
            }
        ]
    },
    // {
    //     name: 'Slug',
    //     id: `${prefix}.slug`,
    //     type: 'text',
    // },
    {
        name: 'Link do \'Zobacz produkty\'',
        id: `${prefix}.linkToProducts`,
        type: 'text',
    },
    {
        name: 'Link do edytora rzutów',
        id: `${prefix}.linkToProjections`,
        type: 'text',
    },
    {
        name: 'Link do edytora rzutów - lustro',
        id: `${prefix}.linkToProjectionsMirror`,
        type: 'text',
        //mirror: true,
    },
    {
        name: 'Rodzaj dostawy',
        id: `${prefix}.deliveryType`,
        type: 'select',
        values: [
            {
                id: 'BEFORE',
                name: 'Zamów przed 13:00'
            },
            {
                id: 'FIVE',
                name: 'dostawa 1-5 dni',
            },
            {
                id: 'DEFAULT',
                name: 'dostawa do 20 dni'
            }
        ]
    },
    {
        name: 'Wyłączyć lustro',
        id: `${prefix}enableMirror`,
        type: 'checkbox',
    },
    ...estimateFields(`${prefix}.details.estimate`),
    ...technicalFields(`${prefix}.details.technicalDetails`),
];

export const fields = [
    {
        name: 'Czy wszystkie projekty',
        id: 'all',
        type: 'checkbox',
    },
    {
        name: 'Kategorie',
        id: 'categories',
        type: 'multiSelect',
    },
    {
        name: 'Projekty',
        id: 'designs',
        type: 'multiSelect',
    },
    ...design('design'),
]