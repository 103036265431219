export default function translateProp(prop) {
    if (prop !== false && prop !== undefined) {
        switch (prop) {
            case 'SINGLE_STOREY': return 'Domy parterowe';
            case 'ATTIC_HOUSE'  : return 'Domy z poddaszem';
            case 'MULTI_STOREY' : return 'Domy piętrowe';
            case 'FLAT'         : return 'Płaski';
            case 'GABLE'        : return 'Skośny';
            case 'HIP'          : return 'Inny';
            case 0              : return '0';
            case 1              :
            case 'ONE'          : return '1';
            case 2              : 
            case 'TWO'          : return '2';
            case 'THREE'        : return '3';
            case 'FOUR_AND_MORE': return '4+';
            default             : return prop;
        }
    }
    return false;
}