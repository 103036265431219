import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_SALES: 'sale/FETCH_ALL_SALES',
    FETCH_SALE: 'sale/FETCH_SALE',
    DELETE_SALE: 'sale/DELETE_SALE',
    CREATE_SALE: 'sale/CREATE_SALE',
    UPDATE_SALE: 'sale/UPDATE_SALE',
    CHANGE_PAGINATION: 'sale/CHANGE_PAGINATION',
    RESET: 'sale/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const SaleState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_SALES):
        case REQUEST(ActionTypes.FETCH_SALE):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_SALE):
        case REQUEST(ActionTypes.UPDATE_SALE):
        case REQUEST(ActionTypes.DELETE_SALE):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_SALES):
        case FAILURE(ActionTypes.FETCH_SALE):
        case FAILURE(ActionTypes.CREATE_SALE):
        case FAILURE(ActionTypes.UPDATE_SALE):
        case FAILURE(ActionTypes.DELETE_SALE):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };
        case SUCCESS(ActionTypes.UPDATE_SALE):
        case SUCCESS(ActionTypes.CREATE_SALE):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_SALES):
        case SUCCESS(ActionTypes.FETCH_SALE):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_SALE):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default SaleState;

const apiUrl = 'sale/admin';

export const getAllSales = () =>(dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_SALES,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().sale.pagination), 'GET', null),
});


export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllSales());
    return result;
};

export const getSale = (id) =>({
    type: ActionTypes.FETCH_SALE,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const updateSale = (sale) => ({
    type: ActionTypes.CREATE_SALE,
    payload: connection(`${apiUrl}`, 'PUT', sale)
});

export const createSale = (sale) => ({
    type: ActionTypes.CREATE_SALE,
    payload: connection(`${apiUrl}`, 'POST', sale)
});

export const deleteSale = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_SALE,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllSales());
    return result;
};