export const fields = [
    {
        name: 'Data dodania',
        id: 'date',
        type: 'time',
    },
    {
        name: 'Użytkownik',
        id: 'user',
        type: 'text',
    },
    {
        name: 'Projekt',
        id: 'design.name',
        type: 'text',
    },
    {
        name: 'Wiadomość',
        id: 'content',
        type: 'textarea',
    },
];