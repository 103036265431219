import {Component} from 'react';
import { connect } from 'react-redux';
import {getAllAddons, getSimpleAddons, updateAddonsOrder, changePagination} from "../addons/addon.reducer";
import Page from "../../../tools/Page";
import DndTable from '../../../modules/form/dnd-table/DndTable';
import { COLUMNS } from './constants';
import sortByProp from '../../../tools/sortByProp';

class AddonOrder extends Component {    
    componentDidMount() {
        if (!Array.isArray(this.props.data))
            this.props.getSimpleAddons();
    }
    save(addons) {
        const orderedIds = addons.map(addon => addon.id);
        this.props.updateAddonsOrder(orderedIds);
    }
    render() {
        return (
            <Page header='Kolejność wyświetlania dodatków'>
                { Array.isArray(this.props.data) && 
                    <DndTable
                        columns={COLUMNS}
                        data={sortByProp(this.props.data, 'orderNumber')}
                        save={this.save.bind(this)}
                    />
                }
            </Page>
        );
    }
}


const mapStateToProps = ({addon}) => ({
    data: addon.data,
    pagination: addon.pagination
});

const mapDispatchToProps = {
    changePagination,
    getAllAddons,
    getSimpleAddons,
    updateAddonsOrder
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (AddonOrder);