import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createArchitect} from "./architect.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class ArchitectCreator extends Component{

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        console.log(data);
        this.props.createArchitect(data);
    }

    render() {

        return (
            <Page header={'Dodaj architekta'}>
                <GenericForm
                    content={fields}
                    data = {{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({architect}) => ({
    updateSuccess: architect.updateSuccess,
    data: architect.data
});

const mapDispatchToProps = {
    createArchitect
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchitectCreator);