import { resetPasswordInit } from "./account.reducer";
import { connect } from "react-redux";
import React, { Component } from "react";
import ResetForm from "./resetForm";
import { Link } from "react-router-dom";

class ForgotPassword extends Component {
  componentDidMount() {
    document.body.classList.add("login_page");
  }

  componentWillUnmount() {
    document.body.classList.remove("login_page");
    window.location.reload();
  }

  handleReset = (data) => {
    this.props.resetPasswordInit(data.login);
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="login-wrapper row">
          <div
            id="login"
            className="login loginpage col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4"
          >
            <h1>
              <Link to="/" title="Login Page" tabIndex="-1">
                Complete Admin
              </Link>
            </h1>

            <ResetForm onSave={this.handleReset} />

            {this.props.reset && <p>Email resetujący hasło został wysłany</p>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  reset: auth.reset,
});

const mapDispatchToProps = {
  resetPasswordInit,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
