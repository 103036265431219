import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_ESTATES: 'estate/FETCH_ALL_ESTATES',
    FETCH_ESTATE: 'estate/FETCH_ESTATE',
    UPDATE_ESTATE: 'estate/UPDATE_ESTATE',
    DELETE_ESTATE: 'estate/DELETE_ESTATE',
    CREATE_ESTATE: 'estate/CREATE_ESTATE',
    CHANGE_PAGINATION: 'estate/CHANGE_PAGINATION',
    RESET: 'estate/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const EstateState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_ESTATES):
        case REQUEST(ActionTypes.FETCH_ESTATE):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_ESTATE):
        case REQUEST(ActionTypes.DELETE_ESTATE):
        case REQUEST(ActionTypes.UPDATE_ESTATE):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_ESTATES):
        case FAILURE(ActionTypes.FETCH_ESTATE):
        case FAILURE(ActionTypes.CREATE_ESTATE):
        case FAILURE(ActionTypes.DELETE_ESTATE):
        case FAILURE(ActionTypes.UPDATE_ESTATE):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.CREATE_ESTATE):
        case SUCCESS(ActionTypes.UPDATE_ESTATE):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_ESTATES):
        case SUCCESS(ActionTypes.FETCH_ESTATE):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_ESTATE):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default EstateState;

const apiUrl = 'abc/admin/estate';

export const getAllEstates = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_ESTATES,
    payload: connection(applyParamsToUrl(`abc/estate`,getState().estate.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllEstates());
    return result;
}

export const getEstate = (id) =>({
    type: ActionTypes.FETCH_ESTATE,
    payload: connection(`abc/estate/${id}`, 'GET', null)
});

export const createEstate = (estate) => ({
    type: ActionTypes.CREATE_ESTATE,
    payload: connection(`${apiUrl}`, 'POST', estate)
});

export const updateEstate = (estate) => ({
    type: ActionTypes.UPDATE_ESTATE,
    payload: connection(`${apiUrl}`, 'PUT', estate)
});

export const deleteEstate = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_ESTATE,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllEstates())
    return result
};