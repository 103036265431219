import React, {Component} from "react";
import Page from "../../tools/Page";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import User from "./users/user";
import Drafts from "./documents/drafts";
import BanUser from "./users/banUser";
import DiscountUser from "./users/discountUser";
import * as CONSTANT from "./constants";

export class MainUser extends Component{
    
    constructor(props) {
        super(props);
        this.state = { panel: 0 }
    }
    handleChange = (event, newValue) =>{
        this.props.history.push({ search: `?tab=${CONSTANT.TABS[Number(newValue)]}` });
        this.setState({ panel: newValue });
    };
    componentDidMount() {
        this.chooseTabFromUrl();
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props)
            this.chooseTabFromUrl();
    }
    chooseTabFromUrl() {
        const urlSearchValue = this.props.history.location.search.split('=')[1];
        this.setState({ panel: CONSTANT.TABS.indexOf(urlSearchValue) });
    }
    cancel(){
        this.props.history.goBack();
    }
    render() {
        return (
            <Page info='' header={`Użytkownik ${this.props.match.params.id}`}>
                <AppBar position='static'>
                    <Tabs value={this.state.panel} onChange={this.handleChange}>
                        <Tab label='Ogólne'               id='simple-tab-0' />
                        <Tab label='Rysunki projektowe'   id='simple-tab-1' />
                        <Tab label='Pliki edytora rzutów' id='simple-tab-2' />
                        <Tab label='Kosztorys'            id='simple-tab-3' />
                        <Tab label='Rabaty'               id='simple-tab-4' />
                        <Tab label='Ban'                  id='simple-tab-5' />
                    </Tabs>
                </AppBar>
                <TabPanel index={0} value={this.state.panel}>
                    <User userId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                </TabPanel>
                <TabPanel index={1} value={this.state.panel}>
                    <Drafts prefix='user/documents/admin' type="drawing"    history={this.props.history} userId={this.props.match.params.id} cancel={this.cancel.bind(this)} />
                </TabPanel>
                <TabPanel index={2} value={this.state.panel}>
                    <Drafts prefix='user/documents/admin' type="projection" history={this.props.history} userId={this.props.match.params.id} cancel={this.cancel.bind(this)} />
                </TabPanel>
                <TabPanel index={3} value={this.state.panel}>
                    <Drafts prefix='user/documents/admin' type="estimate"   history={this.props.history} userId={this.props.match.params.id} cancel={this.cancel.bind(this)} />
                </TabPanel>
                <TabPanel index={4} value={this.state.panel}>
                    <DiscountUser userId={this.props.match.params.id}/>
                </TabPanel>
                <TabPanel index={5} value={this.state.panel}>
                    <BanUser userId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                </TabPanel>
            </Page>
        );
    }
}

function TabPanel(props){
    const {children, value, index} = props;

    return(
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default MainUser;