import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteMarketing} from "./marketing.reducer";
import Page from "../../../tools/Page";

class Marketings extends Component {

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'email', name: 'Adres email'},
                {id: 'date', name: 'Data'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie zgody marketingowe'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'marketing'}
                    deleteFunction={this.props.deleteMarketing}
                    slug={'marketing'}
                    skip={['date']}
                    downloadLink='form/marketing-consent/admin/raport.xlsx'
                    disableAdd
                    disableEdit
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({marketing}) => ({
    data:       marketing.data,
    pagination: marketing.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteMarketing
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Marketings);