import React, {Component} from 'react';
import {connect} from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteUser} from "./user.reducer";
import { getSimpleRoles } from '../roles/role.reducer';
import Page from "../../../tools/Page";
import {Grid} from "@material-ui/core";
import Select from "react-select";
import {selectStyles} from "../../offers/discount/discounts";

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keys: [
                {id: 'name', name: 'Imię'},
                {id: 'surname', name: 'Nazwisko'},
                {id: 'email', name: 'Email'},
                {id: 'phone', name: 'Numer telefonu'},
                {id: 'language', name: 'Język'},
                {id: 'authority', name: 'Uprawnienia'}
            ],
            search: '',
            filter: '',
            options: []
        }
    }
    
    componentDidMount() {
        if (!Array.isArray(this.props.roles)) {
            this.props.getSimpleRoles().then(res => {
                this.createSelectOptions();  
            });
        }
        else 
            this.createSelectOptions();
    }

    createSelectOptions = () => {

        // APPENDS STATIC OPTIONS FOR FILTERING BY COUNSELOR 
        const options = [
            {
                value: "null",
                label: 'Brak doradcy'
            },
            {
                value: "notNull",
                label: 'Posiada doradcę'
            }
        ];

        // APPENDS OPTIONS FOR FILTERING BY ROLES
        for (const role of this.props.roles) {
            options.push({
                value: role.id,
                label: role.name
            });
        };

        // UPDATES STATE WITH NEW OPTIONS
        this.setState({
            options: options
        });
    }

    buildSearch = (search) => {
        const filter = this.state.filter !== '' ? this.state.filter : '';
        const params = search.split(',');
        params[0] = filter;

        this.setState({
            search: params[0]
        })
        return params.join(',');
    }

    search = () => {
        const params = this.props.pagination;
        params.search = this.buildSearch(params.search);
        this.props.changePagination(params);
    }

    handleChange = (e) => {
        const value = e.value ? e.value : '';
        const filterPattern = typeof value === "number" ? `role:${e.value}` : `advisor:${e.value}`;

        this.setState({
            filter: filterPattern

        }, () => this.search());
    }

    render() {
        return (
            <>
                <Page header='Wszyscy użytkownicy'>
                    <Grid container direction='column' spacing={4} style={{width: '100%'}}>
                        <Grid item style={{width: '100%'}}>
                            <Select
                                closeMenuOnSelect={true}
                                options={this.state.options}
                                onChange={this.handleChange.bind(this)}
                                styles={selectStyles}
                                placeholder='Filtruj'
                                isClearable={true}
                            />
                        </Grid>
                        <Grid item>
                            <GenericTable
                                data={this.props.data}
                                changePage={this.props.changePagination}
                                params={this.props.pagination}
                                columns={this.state.keys}
                                title={'użytkownik'}
                                deleteFunction={this.props.deleteUser}
                                skip={['authority']}
                                slug={'users'}
                                add={'Dodaj nowego użytkownika'}
                                search={this.state.search}
                            />
                        </Grid>
                    </Grid>
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({user, role}) => ({
    data: user.data,
    pagination: user.pagination,
    roles: role.data
});

const mapDispatchToProps = {
    changePagination,
    deleteUser,
    getSimpleRoles
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);