import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {document} from "./constants";
import {getAllDocuments, addDocument} from "./document.reducer";
import {connect} from "react-redux";

class DocumentCreator extends Component{

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        const requestBody = data.files.map(file => ({ title: file.title, file: file }));
        this.props.addDocument(requestBody, this.props.match.params.id);
        this.props.getAllDocuments();
        this.cancel();
    }

    render() {

        return (
            <Page header={'Dodaj dokumenty'}>
                <GenericForm
                    content={document}
                    data={{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
            </Page>
        );
    }
}


const mapStateToProps = ({document}) => ({
    updateSuccess: document.updateSuccess,
    data: document.data
});

const mapDispatchToProps = {
    addDocument,
    getAllDocuments
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCreator);