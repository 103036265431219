import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_ARCHITECTS: 'architect/FETCH_ALL_ARCHITECTS',
    FETCH_ARCHITECT: 'architect/FETCH_ARCHITECT',
    UPDATE_ARCHITECT: 'architect/UPDATE_ARCHITECT',
    DELETE_ARCHITECT: 'architect/DELETE_ARCHITECT',
    CHANGE_PAGINATION: 'architect/CHANGE_PAGINATION',
    CREATE_ARCHITECT: 'architect/CREATE_ARCHITECT',
    RESET: 'architect/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const ArchitectState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_ARCHITECTS):
        case REQUEST(ActionTypes.FETCH_ARCHITECT):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_ARCHITECT):
        case REQUEST(ActionTypes.DELETE_ARCHITECT):
        case REQUEST(ActionTypes.UPDATE_ARCHITECT):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_ARCHITECTS):
        case FAILURE(ActionTypes.FETCH_ARCHITECT):
        case FAILURE(ActionTypes.CREATE_ARCHITECT):
        case FAILURE(ActionTypes.DELETE_ARCHITECT):
        case FAILURE(ActionTypes.UPDATE_ARCHITECT):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_ARCHITECT):
        case SUCCESS(ActionTypes.UPDATE_ARCHITECT):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };
        case SUCCESS(ActionTypes.FETCH_ALL_ARCHITECTS):
        case SUCCESS(ActionTypes.FETCH_ARCHITECT):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_ARCHITECT):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default ArchitectState;

const apiUrl = 'abc/admin/architect';

export const getAllArchitects = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_ARCHITECTS,
    payload: connection(applyParamsToUrl(`abc/architect`,getState().architect.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllArchitects());
    return result;
};


export const getArchitect = (id) =>({
    type: ActionTypes.FETCH_ARCHITECT,
    payload: connection(`abc/architect/${id}`, 'GET', null)
});

export const createArchitect = (architect) => ({
    type: ActionTypes.CREATE_ARCHITECT,
    payload: connection(`${apiUrl}`, 'POST', architect)
});

export const updateArchitect = (architect) => ({
    type: ActionTypes.UPDATE_ARCHITECT,
    payload: connection(`${apiUrl}`, 'PUT', architect)
});

export const deleteArchitect = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_ARCHITECT,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllArchitects());
    return result;
};