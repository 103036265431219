import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import CompanyCreator from "./companyCreator";
import Companies from "./companies";
import CompanyView from "./company";

const Company = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={CompanyCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={CompanyView}/>
            <PrivateRoute exact path={`${match.url}`} component={Companies}/>
        </Switch>
    </>
);

export default Company;