import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination} from "./page.reducer";
import Page from "../../../tools/Page";

class Pages extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [{id: 'cmsName', name: 'Nazwa'}],
        }
    }
    render() {
        return (
            <>
                <Page header='Wszystkie strony'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    slug={'page'}
                    disableDelete
                    disableAdd
                    slugName
                />
                </Page>
            </>
        );
    }
}

// const mapStateToProps = ({page}) => ({
//     data: page.data,
//     pagination: page.pagination
// });
const mapStateToProps = (state) => {
    console.log(state);
    return {
        data: state.page.data,
        pagination: state.page.pagination
    }
};

const mapDispatchToProps = {
    changePagination,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Pages);