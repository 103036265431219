import Select from "react-select";
import React from "react";

export const mapStatusToString = (type) => {
    switch (type) {
        case 'WAITING':
            return 'oczekuje na płatność';
        case 'IN_PROGRESS':
            return 'w trakcie realizacji';
        case 'PAID_IN_PROGRESS':
            return 'opłacone, w trakcie realizacji';
        case 'SENT':
            return 'wysłane';
        case 'READY_TO_PICKUP':
            return 'oczekuje na odbiór';
        case 'FINISHED':
            return 'zrealizowane';
        case 'CANCELLED':
            return 'anulowane';
        default:
            return '';
    }
}

export const options = [
    {
        value: "WAITING",
        label: mapStatusToString('WAITING')
    },
    {
        value: "IN_PROGRESS",
        label: mapStatusToString('IN_PROGRESS')
    },
    {
        value: "PAID_IN_PROGRESS",
        label: mapStatusToString('PAID_IN_PROGRESS')
    },
    {
        value: "SENT",
        label: mapStatusToString('SENT')
    },
    {
        value: "READY_TO_PICKUP",
        label: mapStatusToString('READY_TO_PICKUP')
    },
    {
        value: "FINISHED",
        label: mapStatusToString('FINISHED')
    },
    {
        value: "CANCELLED",
        label: mapStatusToString('CANCELLED')
    }
];

const selectStyles = {menu: styles => ({...styles, zIndex: 999})};

export const StatusFilter = ({set, handleChange}) => {

    const handleStatusChange = (e) => {
        set(() => {
                if(e) {
                    return {
                        status: 'status:' + e.value,
                    }
                }else{
                    return {
                        status: '',
                    }
                }
            },
            () => {
                handleChange()
            });
    }

    return (
        <Select
            closeMenuOnSelect={true}
            options={options}
            onChange={handleStatusChange}
            styles={selectStyles}
            placeholder='Status zamówienia'
            isClearable={true}
        />
    )
}

export default StatusFilter;