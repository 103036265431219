import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteEstate} from "./estate.reducer";
import Page from "../../../tools/Page";

class Estates extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                    {id: 'name', name: 'Nazwa'},
                    {id: 'website', name: 'Strona internetowa'},
                    {id: 'location', name: 'Adres'},
                    {id: 'developer', name: 'Deweloper'}
                ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie osiedla'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'osiedle'}
                    deleteFunction={this.props.deleteEstate}
                    slug={'estate'}
                    add={'Dodaj nowe osiedle'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({estate}) => ({
    data: estate.data,
    pagination: estate.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteEstate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Estates);