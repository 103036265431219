import React, { Component } from "react";
import { resetPasswordFinish } from "./account.reducer";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ActivateForm from "./activateForm";
import { Link } from "react-router-dom";
class Activate extends Component {
  componentDidMount() {
    document.body.classList.add("login_page");
  }

  componentWillUnmount() {
    document.body.classList.remove("login_page");
    window.location.reload();
  }

  handleReset = (data) => {
    const result = {
      key: this.props.location.search.split("=")[1],
      password: data.password,
    };
    this.props.resetPasswordFinish(result);
  };

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: "/static-content/text" },
    };

    return (
      <div className="container-fluid">
        <div className="login-wrapper row">
          <div
            id="login"
            className="login loginpage col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4"
          >
            <h1>
              <Link to="/" title="Login Page" tabIndex="-1">
                Complete Admin
              </Link>
            </h1>

            <ActivateForm onSave={this.handleReset} />

            {this.props.reset && this.props.session && (
              <Redirect push to={from} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  session: auth.sessionFetched,
  reset: auth.reset,
});

const mapDispatchToProps = {
  resetPasswordFinish,
};

export default connect(mapStateToProps, mapDispatchToProps)(Activate);
