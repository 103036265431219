import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {Redirect} from "react-router-dom";
import {fields} from "./constants";
import {getFromPurchase, getFromProduct, createShipmentForProduct, createShipmentForPurchase} from "./shipment-service.reducer";
import {connect} from "react-redux";

class ShipmentCreatorServicePoint extends Component{

    componentDidMount() {
        if(this.props.match.params.source === 'product'){
            this.props.getFromProduct(this.props.match.params.id);
        }else {
            this.props.getFromPurchase(this.props.match.params.id);
        }
    }


    cancel(){
        this.props.history.goBack();
    }

    save(data){
        data.shipmentInfo.billing.billingAccountNumber = this.props.data.shipmentInfo.billing.billingAccountNumber;
        if(this.props.match.params.source === 'product'){
            this.props.createShipmentForProduct(this.props.match.params.id, data);
        }else {
            this.props.createShipmentForPurchase(this.props.match.params.id, data);
        }
    }

    render() {
        return(
            <Page header={'Dodaj przesyłkę service point'}>
                {this.props.data &&
                    <GenericForm
                        content={fields}
                        data={{
                            ...this.props.data,
                            pieceList: {
                                item: [{}],
                            }
                        }}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                }
                {this.props.updateSuccess &&
                <Redirect to={`/shipment/web`}/>
                }
            </Page>
        );
    }
}

const mapStateToProps = ({shipmentService}) => ({
    data: shipmentService.data,
    updateSuccess: shipmentService.updateSuccess,
});

const mapDispatchToProps = {
    getFromPurchase,
    getFromProduct,
    createShipmentForPurchase,
    createShipmentForProduct,

};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentCreatorServicePoint)