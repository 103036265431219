import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import './_dnd-table.scss';

const DndTable = ({ columns, data, save }) => {
  const [draggableList, setDraggableList] = useState([]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setDefaultList() }, []);

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...draggableList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setDraggableList(updatedList);
  };
  const setDefaultList = () => {
    if (data)
      setDraggableList(data);  
  }
  const saveChanges = () => {
    save(draggableList);
  }
  return (
    <DragDropContext onDragEnd={handleDrop}>
      <div className='text-right'>
        <Grid container justify='flex-end' spacing={2}>
          <Grid item>
              <button className="btn btn-primary" onClick={saveChanges}>Zapisz kolejność</button>
          </Grid>
        </Grid>
      </div>
      <TableContainer>
        <Table>

          <TableHead className="list-container list-container--header">
            <TableRow className="item-container item-container--header">

            { columns.map((item, i) => (
              <TableCell key={i}>{item.name}</TableCell>
            ))}

            </TableRow>
          </TableHead>

          <Droppable droppableId="list-container">
            {provided => ( 
              <TableBody className="list-container" ref={provided.innerRef} {...provided.droppableProps}>
                { draggableList.map((item, i) => (
                  <Draggable key={item.id} draggableId={String(item.id)} index={i}>
                    {provided => (
                      <TableRow className="item-container" ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} hover key={i}>
                      { columns.map((value, i2) => (
                        <TableCell key={i2} dangerouslySetInnerHTML={{__html: value.id === 'orderNumber' ? i+1 : item[value.id]}} />
                      ))}
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>

        </Table>
      </TableContainer>
    </DragDropContext>
  )
}

export default DndTable;