import React, { Component } from "react";
import { connect } from "react-redux";
import GenericTable from "../../../modules/table/GenericTable";
import {
  changePagination,
  deletePurchase,
  togglePayment,
} from "./purchase.reducer";
import Page from "../../../tools/Page";
import { Grid } from "@material-ui/core";
import StatusFilter, { mapStatusToString } from "./filters/statusFilter";
import PaymentFilter from "./filters/PaymentFilter";
import { DateSpanFilter } from "./filters/DateSpanFilter";
import DeliveryTypeFilter, {
  mapDeliveryTypeToString,
} from "./filters/DeliveryTypeFilter";

class Purchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [
        { id: "purchaseDate", name: "Data" },
        {
          id: "id",
          name: "Id",
          link: true,
        },
        { id: "username", name: "Użytkownik" },
        { id: "phone", name: "Telefon" },
        { id: "value", name: "Wartość" },
        { id: "status", name: "Status", mapper: mapStatusToString },
        { id: "paid", name: "Płatność", check: true },
        { id: "sendDate", name: "Data wysyłki" },
        { id: "pickUpDate", name: "Data odbioru" },
        {
          id: "deliveryType",
          name: "Rodzaj dostawy",
          mapper: mapDeliveryTypeToString,
        },
      ],
      status: "",
      payment: "",
      sendFrom: "",
      sendTo: "",
      pickFrom: "",
      pickTo: "",
      deliveryType: "",
    };
  }

  buildSearch = (search) => {
    const {
      status,
      payment,
      sendFrom,
      sendTo,
      pickFrom,
      pickTo,
      deliveryType,
    } = this.state;
    const params = search.split(",");
    params[0] = status;
    params[1] = payment;
    params[2] = sendFrom;
    params[3] = sendTo;
    params[4] = pickFrom;
    params[5] = pickTo;
    params[6] = deliveryType;
    return params.join(",");
  };

  handleChange = () => {
    const params = this.props.pagination;
    params.search = this.buildSearch(params.search);
    this.props.changePagination(params);
  };

  handleSendDate = (e) => {
    if (e.from !== undefined) {
      this.setState({ sendFrom: e.from }, () => this.handleChange());
    } else if (e.to !== undefined) {
      this.setState({ sendTo: e.to }, () => this.handleChange());
    }
  };

  handlePickDate = (e) => {
    if (e.from !== undefined) {
      this.setState({ pickFrom: e.from }, () => this.handleChange());
    } else if (e.to !== undefined) {
      this.setState({ pickTo: e.to }, () => this.handleChange());
    }
  };

  render() {
    return (
      <>
        <Page header="Wszystkie zamówienia">
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              direction="column"
              spacing={4}
              style={{ width: "100%" }}
            >
              <Grid item style={{ width: "100%" }}>
                <Grid container spacing={2} justify="space-between">
                  <Grid item xs={4}>
                    <StatusFilter
                      set={this.setState.bind(this)}
                      handleChange={this.handleChange.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <PaymentFilter
                      set={this.setState.bind(this)}
                      handleChange={this.handleChange.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DeliveryTypeFilter
                      set={this.setState.bind(this)}
                      handleChange={this.handleChange.bind(this)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <Grid container justify="space-evenly">
                  <Grid item>
                    <DateSpanFilter
                      update={this.handleSendDate.bind(this)}
                      name="sendDate"
                      title="Data wysyłki:"
                    />
                  </Grid>
                  <Grid item>
                    <DateSpanFilter
                      update={this.handlePickDate.bind(this)}
                      name="pickUpDate"
                      title="Data odbioru:"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <GenericTable
                  data={this.props.data}
                  changePage={this.props.changePagination}
                  params={this.props.pagination}
                  columns={this.state.keys}
                  title={"zakup"}
                  skip={["purchaseDate", "finished"]}
                  deleteFunction={this.props.deletePurchase}
                  slug={"/purchase"}
                  add={"Dodaj nowe zamówienie"}
                  // disableAdd
                  disableEdit
                  disableDelete
                  flagDelivery
                  toggle={this.props.togglePayment}
                  search={`${this.state.status},${this.state.payment},${this.state.sendFrom},${this.state.sendTo},${this.state.pickFrom},${this.state.pickTo},${this.state.deliveryType}`}
                />
              </Grid>
            </Grid>
          </div>
        </Page>
      </>
    );
  }
}

const mapStateToProps = ({ purchase }) => ({
  data: purchase.data,
  pagination: purchase.pagination,
});

const mapDispatchToProps = {
  changePagination,
  deletePurchase,
  togglePayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchases);
