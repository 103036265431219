import Select from "react-select";
import React from "react";

export const mapDeliveryTypeToString = (type) => {
    switch (type) {
        case 'BEFORE':
            return 'Zamów przed 13:00';
        case 'FIVE':
            return 'dostawa 1-5 dni';
        case 'DEFAULT':
            return 'dostawa do 20 dni';
        default:
            return '';
    }
}
export const options = [
    {
        value: "BEFORE",
        label: mapDeliveryTypeToString('BEFORE')
    },
    {
        value: "FIVE",
        label: mapDeliveryTypeToString('FIVE')
    },
    {
        value: "DEFAULT",
        label: mapDeliveryTypeToString('DEFAULT')
    },
];

const selectStyles = {menu: styles => ({...styles, zIndex: 999})};

export const DeliveryTypeFilter = ({set, handleChange}) => {

    const handleStatusChange = (e) => {
        set(() => {
                if(e) {
                    return {
                        deliveryType: 'deliveryType:' + e.value,
                    }
                }else{
                    return {
                        deliveryType: '',
                    }
                }
            },
            () => {
                handleChange()
            });
    }

    return (
        <Select
            closeMenuOnSelect={true}
            options={options}
            onChange={handleStatusChange}
            styles={selectStyles}
            placeholder='Rodzaj zamówienia'
            isClearable={true}
        />
    )
}

export default DeliveryTypeFilter;