import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";

export const ActionTypes = {
    FETCH_FROM_PURCHASE: 'shipment-web/FETCH_FROM_PURCHASE',
    FETCH_MY_SHIPMENTS: 'shipment-web/FETCH_ALL_SHIPMENTS',
    CREATE_SHIPMENT: 'shipment-web/CREATE_SHIPMENT',
    DELETE_SHIPMENT: 'shipment-web/DELETE_SHIPMENT',
};

const initState = {
    data: null,
    errorMessage: null,
    updateSuccess: false,
};

const ShipmentWebState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.DELETE_SHIPMENT):
        case REQUEST(ActionTypes.FETCH_MY_SHIPMENTS):
        case REQUEST(ActionTypes.FETCH_FROM_PURCHASE):
            return{
                ...initState
            };
        case REQUEST(ActionTypes.CREATE_SHIPMENT):
            return {
                ...state,
                updateSuccess: false,
                errorMessage: null,
            };
        case FAILURE(ActionTypes.DELETE_SHIPMENT):
        case FAILURE(ActionTypes.FETCH_MY_SHIPMENTS):
        case FAILURE(ActionTypes.CREATE_SHIPMENT):
        case FAILURE(ActionTypes.FETCH_FROM_PURCHASE):
            return {
                ...state,
                errorMessage: action.payload,
                updateSuccess: false,
            };

        case SUCCESS(ActionTypes.CREATE_SHIPMENT):
            return {
                ...state,
                data: action.payload,
                updateSuccess: true,
            }
        case SUCCESS(ActionTypes.DELETE_SHIPMENT):
        case SUCCESS(ActionTypes.FETCH_MY_SHIPMENTS):
        case SUCCESS(ActionTypes.FETCH_FROM_PURCHASE):
            return {
                ...state,
                data: action.payload,
            }
        default:
            return state;
    }
};

export default ShipmentWebState;

const apiUrl = 'shipment/admin';

export const getFromPurchase = (id) =>({
    type: ActionTypes.FETCH_FROM_PURCHASE,
    payload: connection(`${apiUrl}/purchase/${id}`, 'GET', null),
});

export const getFromProduct = (id) =>({
    type: ActionTypes.FETCH_FROM_PURCHASE,
    payload: connection(`${apiUrl}/product/${id}`, 'GET', null),
});

export const createShipment = (data) =>({
    type: ActionTypes.CREATE_SHIPMENT,
    payload: connection(`${apiUrl}`, 'POST', data),
});

export const createShipmentForPurchase = (id, data) =>({
    type: ActionTypes.CREATE_SHIPMENT,
    payload: connection(`${apiUrl}/purchase/${id}`, 'POST', data),
});

export const createShipmentForProduct = (id, data) =>({
    type: ActionTypes.CREATE_SHIPMENT,
    payload: connection(`${apiUrl}/product/${id}`, 'POST', data),
});

export const getMyShipments = (from, to, offset) =>({
    type: ActionTypes.FETCH_MY_SHIPMENTS,
    payload: connection(`${apiUrl}/${from}/${to}/${offset}`, 'GET', null),
})

export const deleteShipment = (id) => ({
    type: ActionTypes.DELETE_SHIPMENT,
    payload: connection(`${apiUrl}/${id}`, 'DELETE', null),
})
