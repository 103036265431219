import { Switch } from 'react-router-dom';
import Designs from './design';
import PrivateRoute from "../../tools/privateRoute";
import Category from './category';
import Addon from './addons';
import AddonPackage from "./addonPackages";
import Sale from './sale';
import Opinion from './opinion';
import AllDownloadables from './details/allDownloadables';
import AddonOrder from './addonOrder';

const Design = ({ match }) => (
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/category`} component={Category} />
            <PrivateRoute path={`${match.url}/addon`} component={Addon} />
            <PrivateRoute path={`${match.url}/addonOrder`} component={AddonOrder} />
            <PrivateRoute path={`${match.url}/addonPackage`} component={AddonPackage} />
            <PrivateRoute path={`${match.url}/sale`} component={Sale} />
            <PrivateRoute path={`${match.url}/opinion`} component={Opinion} />
            <PrivateRoute path={`${match.url}/downloadable`} component={AllDownloadables} />
            <PrivateRoute path={`${match.url}`} component={Designs} />

        </Switch>
    </>
);

export default Design;