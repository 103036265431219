import React from "react";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

const WYSIWYGEditor = (props) => {

    const onEditorStateChange = editor => {
        props.onChange(editor);
    };

    return (
        <div>
            <SunEditor
                disable={props.readOnly}
                showToolbar={!props.readOnly}
                setContents={props.value}
                height='400px'
                width='100%'
                onChange={onEditorStateChange}
                setOptions={{
                    buttonList:  [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        ['paragraphStyle', 'blockquote'],
                        ['bold', 'underline', 'italic', 'subscript', 'superscript'],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                        ['table', 'link'],
                        ['fullScreen', 'showBlocks', 'codeView'],
                        ['preview'],
                    ]
                }}
            />
        </div>
    );
};

export default WYSIWYGEditor;