import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import Page from "../../../tools/Page";
import {getPost, answerPost} from "../../forum/post/post.reducer";
import {fields} from "./constants";
import {Redirect} from "react-router-dom";


class Opinion extends Component {

    componentDidMount() {
        this.props.getPost(this.props.match.params.id);
    }

    save(data) {
        this.props.answerPost(this.props.match.params.id, data.answer);
    }

    cancel() {
        this.props.history.goBack();
    }


    render() {
        return (
            <>
                <Page
                    header={`Opinia ${this.props.match.params.id} - projekt ${this.props.data?.thread?.id}`}>
                    {this.props.data && this.props.data.author &&
                    <>
                        <GenericForm
                            content={fields}
                            data={{
                                ...this.props.data,
                                user: `${this.props.data.author.firstName} ${this.props.data.author.lastName}`,
                            }}
                            viewOnly
                            readOnly
                        />
                        <GenericForm
                            content={[{id: 'answer', name: "Odpowiedź", type: 'textarea'}]}
                            data={{}}
                            save={this.save.bind(this)}
                            cancel={this.cancel.bind(this)}
                        />
                    </>
                    }
                    {
                        this.props.updateSuccess && <Redirect to={`/design/opinion`}/>
                    }
                </Page>
            </>
        );
    }

}

const mapStateToProps = ({post}) => ({
    data: post.data,
    updateSuccess: post.updateSuccess,
});

const mapDispatchToProps = {
    getPost,
    answerPost
};

export default connect(mapStateToProps, mapDispatchToProps)(Opinion);