import React, {Component} from "react";
import Page from "../../../tools/Page";
import {fields, optionalFields} from "./constants";
import {createDiscount} from "./discount.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {getSimpleDesignCategories} from "../../design/category/category.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import DiscountForm from "./discountForm";

class DiscountCreator extends Component {

    componentDidMount() {
        this.props.getDesignSimple();
        this.props.getSimpleDesignCategories();
        //this.props.getUserSimple();
    }

    cancel() {
        this.props.history.goBack();
    }

    save(data) {
        if (data.designs) {
            data.designs = data.designs.map((el) => ({id: el}));
        }
        if (data.categories) {
            data.categories = data.categories.map((el) => ({id: el}));
        }
        this.props.createDiscount(data);
    }

    columns = optionalFields;

    render() {
        this.columns[0].values = this.props.categories;
        this.columns[1].values = this.props.designs;
        //this.fields[1].values = this.props.users;
        return (
            <Page header={'Dodaj rabat'}>
                {this.props.designs && !this.props.designs.content && this.props.categories &&
                !this.props.categories.content &&
                <DiscountForm
                    optional={this.columns}
                    content={fields}
                    data={{
                        user: {
                            id: this.props.match.params.id,
                        }
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                }
                {this.props.updateSuccess &&
                <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({discount, design, category, user}) => ({
    updateSuccess: discount.updateSuccess,
    data: discount.data,
    designs: design.data,
    categories: category.data,
    //users: user.data
});

const mapDispatchToProps = {
    createDiscount,
    getDesignSimple,
    getSimpleDesignCategories,
    //getUserSimple,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCreator);