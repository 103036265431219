import { combineReducers } from 'redux';



import textReducer              from '../entities/content/Text/text.reducer';
import imageReducer             from '../entities/content/Image/image.reducer';
import accountReducer           from '../modules/account/account.reducer';
import designReducer            from '../entities/design/design/design.reducer';
import detailsReducer           from '../entities/design/details/details.reducer';
import categoryReducer          from "../entities/design/category/category.reducer";
import architectReducer         from "../entities/abc/architect/architect.reducer";
import estateReducer            from "../entities/abc/estate/estate.reducer";
import articleReducer           from "../entities/abc/article/article.reducer";
import articleCategoryReducer   from "../entities/abc/article/category/category.reducer";
import pageReducer              from "../entities/content/page/page.reducer";
import companyReducer           from "../entities/abc/company/company.reducer";
import manufacturerReducer      from "../entities/abc/manufacturer/manufacturer.reducer";
import contactReducer           from "../entities/forms/contact/contact.reducer";
import designSelectionReducer   from '../entities/forms/designSelection/designSelection.reducer';
import newsletterReducer        from "../entities/forms/newsletter/newsletter.reducer";
import marketingReducer         from "../entities/forms/marketing/marketing.reducer";
import userReducer              from "../entities/users/users/user.reducer";
import addonReducer             from "../entities/design/addons/addon.reducer";
import addonPackageReducer      from "../entities/design/addonPackages/addonPackage.reducer";
import mediaReducer             from "../entities/content/media/media.reducer";
import purchaseReducer          from "../entities/purchase/purchase/purchase.reducer";
import discountReducer          from "../entities/offers/discount/discount.reducer";
import saleReducer              from "../entities/design/sale/sale.reducer";
import documentReducer          from "../entities/users/documents/document.reducer";
import roleReducer              from "../entities/users/roles/role.reducer";
import postReducer              from "../entities/forum/post/post.reducer";
import threadReducer            from "../entities/forum/thread/thread.reducer";
import forumCategoryReducer     from "../entities/forum/category/forumCategory.reducer";
import orderCatalogueReducer    from "../entities/forms/orderCatalogue/orderCatalogue.reducer";
import journalReducer           from "../entities/community/journal/journal.reducer";
import realizationReducer       from "../entities/community/realization/realization.reducer";
import partnerReducer           from "../entities/content/partner/partner.reducer";
import specialReducer           from "../entities/offers/special/special.reducer";
import shipmentReducer          from "../entities/shipment/webapi/shipment-web.reducer";
import shipmentServiceReducer   from "../entities/shipment/servicepoint/shipment-service.reducer";
import refundReducer            from "../entities/purchase/refund/refund.reducer";
import exchangeReducer          from "../entities/purchase/exchange/exchange.reducer";
import templateReducer          from "../entities/offers/template/template.reducer";
import boughtDesignReducer      from "../entities/forms/boughtDesign/boughtDesign.reducer";
import OtherBoughtDesignReducer from "../entities/forms/otherBoughtDesign/otherBoughtDesigns.reducer";
import redirectReducer          from "../entities/tools/redirect/redirect.reducer";



const RootReducer = combineReducers({
    text:              textReducer,
    image:             imageReducer,
    auth:              accountReducer,
    design:            designReducer,
    details:           detailsReducer,
    category:          categoryReducer,
    architect:         architectReducer,
    estate:            estateReducer,
    article:           articleReducer,
    articleCategory:   articleCategoryReducer,
    page:              pageReducer,
    company:           companyReducer,
    manufacturer:      manufacturerReducer,
    contact:           contactReducer,
    designSelection:   designSelectionReducer,
    newsletter:        newsletterReducer,
    marketing:         marketingReducer,
    user:              userReducer,
    addon:             addonReducer,
    addonPackage:      addonPackageReducer,
    media:             mediaReducer,
    purchase:          purchaseReducer,
    discount:          discountReducer,
    sale:              saleReducer,
    document:          documentReducer,
    role:              roleReducer,
    post:              postReducer,
    thread:            threadReducer,
    forumCategory:     forumCategoryReducer,
    orderCatalogue:    orderCatalogueReducer,
    journal:           journalReducer,
    realization:       realizationReducer,
    partner:           partnerReducer,
    special:           specialReducer,
    shipmentWeb:       shipmentReducer,
    shipmentService:   shipmentServiceReducer,
    refund:            refundReducer,
    exchange:          exchangeReducer,
    template:          templateReducer,
    boughtDesign:      boughtDesignReducer,
    otherBoughtDesign: OtherBoughtDesignReducer,
    redirect:          redirectReducer,
});



export default RootReducer;