import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Templates from "./templates";
import TemplateView from "./template";

const Template = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/:id`} component={TemplateView}/>
            <PrivateRoute exact path={`${match.url}`} component={Templates}/>
        </Switch>
    </>
);

export default Template;