import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteJournal} from "./journal.reducer";
import Page from "../../../tools/Page";

class Journals extends Component{

    constructor(props) {
        super(props);
        this.state = {
            keys: [
                {id: 'author', name: 'Autor', value: 'id', slug: '/users'},
                {id: 'title', name: 'Tytuł'},
                {id: 'views', name: 'Wyświetlenia'},
                {id: 'likeNumber', name: 'Liczba polubień'},
                // {id: 'design', name: 'Projekt', value: 'name', slug:'/design'},
            ],
        }
    }

    render() {
        console.log(this.props)
        return (
            <>
                <Page header='Wszystkie dzienniki'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'dziennik'}
                    deleteFunction={this.props.deleteJournal}
                    slug={'journal'}
                    disableAdd
                    skip={["views", "likeNumber", "design", "creationDate"]}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({journal}) => ({
    data: journal.data,
    pagination: journal.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteJournal
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Journals);