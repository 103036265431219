import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Newsletters from "./newsletters";

const Newsletter = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}`} component={Newsletters}/>
        </Switch>
    </>
);

export default Newsletter;