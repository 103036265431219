import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import OrderCatalogues from "./orderCatalogues";
import OrderCatalogueView from "./orderCatalogue";

const OrderCatalogue = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/:id`} component={OrderCatalogueView}/>
            <PrivateRoute exact path={`${match.url}`} component={OrderCatalogues}/>
        </Switch>
    </>
);

export default OrderCatalogue;