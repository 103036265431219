import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import EstateCreator from "./estateCreator";
import Estates from "./estates";
import EstateView from "./estate";

const Estate = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={EstateCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={EstateView}/>
            <PrivateRoute exact path={`${match.url}`} component={Estates}/>
        </Switch>
    </>
);

export default Estate;