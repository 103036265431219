import React, { Component } from "react";
import { connect } from "react-redux";
import { loginRequest } from "./account.reducer";
import { Link, Redirect } from "react-router-dom";
import LoginForm from "./LoginForm";

class Login extends Component {
  componentDidMount() {
    document.body.classList.add("login_page");
  }

  componentWillUnmount() {
    document.body.classList.remove("login_page");
    window.location.reload();
  }

  handleLogin = (data) => {
    this.props.login(data.login, data.password, data.rememberMe);
  };

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: "/design" },
    };

    return (
      <div className="container-fluid">
        <div className="login-wrapper row">
          <div
            id="login"
            className="login loginpage col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4"
          >
            <h1>
              <Link to="/" title="Login Page" tabIndex="-1">
                HomeKoncept
              </Link>
            </h1>

            <LoginForm onSave={this.handleLogin} />

            <p id="nav">
              <Link to={"/reset"} className="pull-left">
                Nie pamiętasz hasła?
              </Link>
            </p>

            {this.props.session && this.props.isAuthenticated && (
              <Redirect push to={from} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  session: auth.sessionFetched,
  isAuthenticated: auth.isAuthenticated,
  loginFailed: auth.loginFailed,
});

const mapDispatchToProps = {
  login: loginRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
