import {FormProvider, useForm} from "react-hook-form";
import renderInput from "../../../modules/form/input";
import {Grid} from "@material-ui/core";

const SaleForm = (props) => {

    const {data, cancel, save} = props;
    console.log(props);
    const methods = useForm({defaultValues: data});

    const renderField = (label, value, content) => (
        <div className='form-group text-left'>
            <>
                <label className='form-label' style={{fontWeight: "bold"}}>{label}</label>
                {renderInput(content, value, methods, false)}
            </>
        </div>
    );

    const simpleContent = (id, type) => {
        return {
            id: id,
            type: type,
            required: "To pole jest wymagane!"
        };
    }

    const saleTypesValues = [
        {
            id: 'ALL_SALE',
            name: 'Promocja na wszystkie projekty',
        },
        {
            id: 'CATEGORY_SALE',
            name: 'Promocja na wybrane kategorie',
        },
        {
            id: 'NOT_ALL_SALE',
            name: 'Promocja na wybrane projekty',
        }
    ]

    const saleType = methods.watch('saleType');

    return(
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(save)}>
                <div className="col-xs-12 col-sm-9 col-md-8">

                    {renderField('Początek', data.start, simpleContent('start', 'time'))}
                    {renderField('Koniec', data.end, simpleContent('end','time'))}
                    {renderField('Zdjęcie', data.image, {id: 'image', type: 'file'})}
                    {renderField('Kategorie', data.categories, {id: 'categories', type: 'multiSelect', values: props.categories})}
                    {renderField('Projekty', data.designs, {id: 'designs', type: 'multiSelect', values: props.designs})}
                    {renderField('Typ promocji', data.saleType, {id: 'saleType', type: 'select', values: saleTypesValues, required: 'To pole jest wymagane!'})}
                    
                    {saleType &&
                        <>
                            { (saleType !== 'ADDON_1_ZL' && saleType !== 'VOUCHER' && saleType !== 'VOUCHER_OR_DISCOUNT') &&
                                <div className='form-group text-left'>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item>
                                            {renderInput(simpleContent('isPercent', 'checkbox'), data.isPercent, methods, false)}
                                        </Grid>
                                        <Grid>
                                            <label className='form-label' style={{fontWeight: "bold"}}>Czy jest to
                                                wartość
                                                procentowa?</label>
                                        </Grid>
                                    </Grid>
                                </div>
                            }

                            { saleType !== 'ADDON_1_ZL' && saleType !== 'VOUCHER_OR_DISCOUNT' &&
                                renderField('Wartość', data.value, simpleContent('value', 'number'))
                            }

                            { saleType === 'ADDON_DISCOUNT' &&
                                renderField('Dodatki', data.addons, {id: 'addons', type: 'multiSelect', values: props.addons})
                            }
                            { saleType === 'ADDON_PACKAGE_DISCOUNT' &&
                                renderField('Pakiety dodatków', data.packages, {id: 'packages', type: 'multiSelect', values: props.packages})
                            }

                    </>
                    }




                </div>
                <div className="col-xs-12 col-sm-9 col-md-8 padding-bottom-30">
                    <div className="text-left">
                        <button type='button' className='btn' onClick={cancel}>Wróć</button>
                        <input className='btn btn-primary' type='submit' value='Zapisz'/>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}

export default SaleForm;