import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_POSTS: 'post/FETCH_ALL_POSTS',
    FETCH_POST: 'post/FETCH_POST',
    UPDATE_POST: 'post/UPDATE_POST',
    DELETE_POST: 'post/DELETE_POST',
    CHANGE_PAGINATION: 'post/CHANGE_PAGINATION',
    CREATE_POST: 'post/CREATE_POST',
    RESET: 'post/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const PostState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_POSTS):
        case REQUEST(ActionTypes.FETCH_POST):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_POST):
        case REQUEST(ActionTypes.DELETE_POST):
        case REQUEST(ActionTypes.UPDATE_POST):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_POSTS):
        case FAILURE(ActionTypes.FETCH_POST):
        case FAILURE(ActionTypes.CREATE_POST):
        case FAILURE(ActionTypes.DELETE_POST):
        case FAILURE(ActionTypes.UPDATE_POST):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_POST):
        case SUCCESS(ActionTypes.UPDATE_POST):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };
        case SUCCESS(ActionTypes.FETCH_ALL_POSTS):
        case SUCCESS(ActionTypes.FETCH_POST):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_POST):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default PostState;

const apiUrl = 'forum/post';

export const getAllPosts = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_POSTS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().post.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllPosts());
    return result;
};

export const answerPost = (id, response) => ({
    type: ActionTypes.UPDATE_POST,
    payload: connection(`forum/secured/post/${id}`, 'POST', response, true)
});


export const getPost = (id) =>({
    type: ActionTypes.FETCH_POST,
    payload: connection(`${apiUrl}/admin/${id}`, 'GET', null)
});

export const updatePost = (post) => ({
    type: ActionTypes.UPDATE_POST,
    payload: connection(`forum/secured/post`, 'PUT', post)
});

export const deletePost = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_POST,
        payload: connection(`forum/admin/post/${id}`, 'DELETE', null)
    });
    dispatch(getAllPosts());
    return result;
};