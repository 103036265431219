import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getPage, getPageName, updatePage} from "./page.reducer";
import {changePagination} from "../../design/category/category.reducer";
import Page from "../../../tools/Page";
import {mb} from "../../../modules/form/FileUploaderController";
import uploadFile from "../../../tools/uploadFile";


class PageView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: 'pl',
            tempFiles: {},
            editorSuffix: '-editor-images'
        }
    }

    componentDidMount() {
        const params = this.props.pag;
        params.size = 200;

        this.props.changePagination(params);
        this.props.getPage(this.props.match.params.name);
        this.props.getPageName(this.props.match.params.name);
    }

    handleFileUpload =(name) => (img) => {
        console.log(img);
        return uploadFile(`file/admin`, img).then(
            (result) => {
                this.setState((previous) => {
                    const oldTemp = previous.tempFiles;
                    const oldImages = oldTemp[name] || [];
                    oldTemp[name] = [...oldImages, result]
                    return {
                        tempFiles: oldTemp
                    }
                });
                return result;
            }
        )
    }

    save(data) {
        const result = [];
        Object.keys(data).forEach(
            (e) => {
                if(!e.endsWith(this.state.editorSuffix)) {
                    const value = {};
                    value.id = this.props.data[e].id;
                    value.type = this.props.data[e].type;
                    switch (value.type) {
                        case 'page':
                            value.editor = data[e];
                            value.images = data[`${e}${this.state.editorSuffix}`]
                            value.files = this.state.tempFiles[e];
                            break;
                        case 'file':
                            value.image = data[e];
                            break;
                        case 'files':
                            value.images = data[e];
                            break;
                        case "category":
                        case "multiSelect":
                            value.pinnedDesignCategories = data[e];
                            break;
                        default:
                            value.content = data[e];
                            break;
                    }
                    result.push(value);
                }
            }
        );
        console.log(result);
        this.props.updatePage(result);
    }

    cancel() {
        this.props.history.goBack();
    }

    createFields(data) {
        return Object.keys(data).map((e) =>
            this.createField(data[e], e)
        ).flat();
    }

    createField(data, name) {
        if(this.props.match.params.name === 'mainPage' && name === 'images' ){
            return {
                name: data.name,
                type: data.type,
                id: name,
                maxSize: 20 * mb
            }

        }else if (data.type === 'category') {
            const result = {
                name: data.name,
                type: data.type,
                id: name,
                values: this.props.categories.content
            }
            if (this.props.match.params.name === 'toolbarCategories') {
                result.limit = 15;
            }
            return result;
        }else if(data.type === 'page'){
            return [
                {
                    name: data.name,
                    type: data.type,
                    id: name,
                    callback: this.handleFileUpload(name),
                },
                {
                    name: `Zdjęcia do edytora - ${data.name}`,
                    type: 'files',
                    id: `${name}${this.state.editorSuffix}`,
                    image: true,
                    noAlt: true,
                    noAdd: true,
                }
            ]
        } else {
            return {
                name: data.name,
                type: data.type,
                id: name
            }
        }
    }

    createData(data) {
        const newObj = {};
        Object.keys(data).forEach((e) => {
            if(data[e].type === 'page'){
                newObj[e] = data[e].content;
                newObj[`${e}${this.state.editorSuffix}`] = data[e].images;
                return newObj;
            }else {
                return newObj[e] = data[e].content;
            }
        });
        return newObj;
    }

    getPage() {
        this.props.getPage(this.props.match.params.name);
    }

    handleLanguageChange(e) {
        this.setState({
                lang: e.target.value
            },
            () => this.getData()
        );
    }

    render() {
        console.log(this.props.pageName)
        return (
            <>
                <Page header={`Strona - ${this.props.pageName && this.props.pageName.content[0].cmsName}`} name={'pageInfo'}>
                    {this.props.data && !this.props.data.content && this.props.categories
                    && !this.props.loading &&
                    <GenericForm
                        content={this.createFields(this.props.data)}
                        data={this.createData(this.props.data)}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                    }
                </Page>
            </>
        );
    }

}

const mapStateToProps = ({page, category}) => ({
    data: page.data,
    loading: page.loading,
    categories: category.data,
    pag: category.pagination,
    pageName: page.dataName
});

const mapDispatchToProps = {
    getPage,
    updatePage,
    changePagination,
    getPageName
};

export default connect(mapStateToProps, mapDispatchToProps)(PageView);