import Select from "react-select";
import React from "react";

export const options = [
    {
        value: true,
        label: "Zapłacone"
    },
    {
        value: false,
        label: 'Niezapłacone'
    },
];

const selectStyles = {menu: styles => ({...styles, zIndex: 999})};

export const PaymentFilter = ({set, handleChange}) => {

    const handleStatusChange = (e) => {
        set(() => {
                if(e) {
                    return {
                        status: 'paid:' + e.value,
                    }
                }else{
                    return {
                        status: '',
                    }
                }
            },
            () => {
                handleChange()
            });
    }

    return (
        <Select
            closeMenuOnSelect={true}
            options={options}
            onChange={handleStatusChange}
            styles={selectStyles}
            placeholder='Płatność'
            isClearable={true}
        />
    )
}

export default PaymentFilter;