import React, {useState} from "react";

export const CustomCheckbox = ({onChange, value, id, callback}) => {

    const [checked, setChecked] = useState(value);

    const handleChange= () => {
        const val = !checked;
        setChecked(val);
        if(callback) {
            callback(id);
        }
        if(onChange) {
            onChange(val);
        }
    }

    return (
        <input className='iCheck' type='checkbox'
               checked={checked} onChange={handleChange}/>
    )
};