import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Opinions from "./opinions";
import OpinionView from "./opinion";

const Opinion = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/:id`} component={OpinionView}/>
            <PrivateRoute exact path={`${match.url}`} component={Opinions}/>
        </Switch>
    </>
);

export default Opinion;