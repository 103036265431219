import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createEstate} from "./estate.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class EstateCreator extends Component{

    columns = fields;

    componentDidMount() {
        this.props.getDesignSimple();
    }

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        if(data.designs) {
            data.designs = data.designs.map((i) => ({id: i}));
        }
        this.props.createEstate(data);
    }

    render() {
        this.columns[7].values=this.props.designs;
        return (
            <Page header={'Dodaj osiedle'}>
                {this.props.designs && !this.props.designs.content &&
                <GenericForm
                    content={fields}
                    data={{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                }
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({estate, design}) => ({
    updateSuccess: estate.updateSuccess,
    data: estate.data,
    designs: design.data,
});

const mapDispatchToProps = {
    createEstate,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(EstateCreator);