import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Threads from "./threads";
import ThreadView from "./thread";

const Thread = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/:id`} component={ThreadView}/>
            <PrivateRoute exact path={`${match.url}`} component={Threads}/>
        </Switch>
    </>
);

export default Thread;