import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_THREADS: 'thread/FETCH_ALL_THREADS',
    FETCH_THREAD: 'thread/FETCH_THREAD',
    UPDATE_THREAD: 'thread/UPDATE_THREAD',
    DELETE_THREAD: 'thread/DELETE_THREAD',
    CHANGE_PAGINATION: 'thread/CHANGE_PAGINATION',
    CREATE_THREAD: 'thread/CREATE_THREAD',
    RESET: 'thread/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const ThreadState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_THREADS):
        case REQUEST(ActionTypes.FETCH_THREAD):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_THREAD):
        case REQUEST(ActionTypes.DELETE_THREAD):
        case REQUEST(ActionTypes.UPDATE_THREAD):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_THREADS):
        case FAILURE(ActionTypes.FETCH_THREAD):
        case FAILURE(ActionTypes.CREATE_THREAD):
        case FAILURE(ActionTypes.DELETE_THREAD):
        case FAILURE(ActionTypes.UPDATE_THREAD):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_THREAD):
        case SUCCESS(ActionTypes.UPDATE_THREAD):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };
        case SUCCESS(ActionTypes.FETCH_ALL_THREADS):
        case SUCCESS(ActionTypes.FETCH_THREAD):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_THREAD):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default ThreadState;

const apiUrl = 'forum/thread';

export const getAllThreads = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_THREADS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().thread.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllThreads());
    return result;
};

export const getThread = (id) =>({
    type: ActionTypes.FETCH_THREAD,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const updateThread = (thread) => ({
    type: ActionTypes.UPDATE_THREAD,
    payload: connection(`forum/admin/thread`, 'PUT', thread)
});

export const deleteThread = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_THREAD,
        payload: connection(`forum/admin/thread/${id}`, 'DELETE', null)
    });
    dispatch(getAllThreads());
    return result;
};