import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Grid} from "@material-ui/core";

class DiscountUser extends Component {

    render() {
        return (
            <Grid container justify='center' spacing={2} >
                <Grid item>
                    <Link to={`/offers/discount?search=user:${this.props.userId}`}>
                        <button className="btn btn-primary">Zobacz rabaty użytkownika</button>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to={`/offers/discount/add/${this.props.userId}`}>
                        <button className="btn btn-primary">Dodaj rabat</button>
                    </Link>
                </Grid>
            </Grid>
        );
    }
}

export default DiscountUser;