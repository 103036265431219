import React, {Component} from "react";
import {connect} from 'react-redux';
import {changePagination} from "./details.reducer";
import GenericTable from "../../../modules/table/GenericTable";
import Page from "../../../tools/Page";

class AllDownloadables extends Component{

    columnList = () =>{
        return [
            {id: 'downloadable', name: 'Id projektu', link: true},
            {id: 'name', name: 'Nazwa'},
            {id: 'numberOfSubscribers', name: 'Liczba subskrypcji'}
        ]
    };

    render() {
        return(
            <Page header='Wszystkie pliki do pobrania'>
                <GenericTable changePage={this.props.changePagination}
                              params={this.props.pagination}
                              data={this.props.data}
                              columns={this.columnList()}
                              skip={['downloadable','numberOfSubscribers']}
                              slug={'/design'}
                              disableAdd
                              disableDelete
                              disableEdit
                />
            </Page>
        );
    }
}

const mapStateToProps =({details}) =>({
    data: details.data,
    pagination: details.pagination,
});

const mapDispatchToProps = {
    changePagination
};

export default connect(mapStateToProps, mapDispatchToProps)(AllDownloadables);

