import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import RedirectCreator from "./redirectCreator";
import Redirects from "./redirects";
import RedirectView from "./redirect";

const Redirect = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={RedirectCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={RedirectView}/>
            <PrivateRoute exact path={`${match.url}`} component={Redirects}/>
        </Switch>
    </>
);

export default Redirect;