export const fields = [
  {
    name: "Nazwa",
    id: "name",
    type: "text",
    required: "To pole jest wymagane",
  },
  {
    name: "Opis",
    id: "description",
    type: "text",
  },
  {
    name: "Premium (Pakiety premium naliczają darmową wysyłkę do zamówienia)",
    id: "premium",
    type: "checkbox",
  },
  {
    name: "Stara cena",
    id: "previousPrice",
    type: "number",
  },
  {
    name: "Aktualna cena",
    id: "price",
    type: "number",
    required: "To pole jest wymagane",
  },
  {
    name: "Zdjęcie",
    id: "image",
    type: "file",
  },
  {
    name: "Dodatki",
    id: "addons",
    type: "multiSelect",
    required: "To pole jest wymagane",
  },
];
