import {FormProvider, useForm} from "react-hook-form";
import {renderField} from "../../../modules/form/input";

export const DiscountForm = ({save, cancel, data, content, optional}) =>{

    const methods = useForm({defaultValues: data});

    const type = methods.watch('type');

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(save)}>
                <div className="col-xs-12 col-sm-9 col-md-8">

                    {content && content.length > 0 && content.map(
                        (item, index) => renderField(item, data, index, methods, false))
                    }
                    {type && type === 'HK_DESIGN' &&
                    <>
                        {optional && optional.length > 0 && optional.map(
                            (item, index) => renderField(item, data, index, methods, false))
                        }
                    </>
                    }
                </div>
                <div className="col-xs-12 col-sm-9 col-md-8 padding-bottom-30">
                    <div className="text-left">
                        <button type='button' className='btn' onClick={cancel}>Wróć</button>
                        <input className='btn btn-primary' type='submit' value='Zapisz'/>
                    </div>
                </div>
            </form>
        </FormProvider>
    );

}

export default DiscountForm;