import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Contacts from "./contacts";

const Contact = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}`} component={Contacts}/>
        </Switch>
    </>
);

export default Contact;