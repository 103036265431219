import React, {useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import {Button, List} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {ConfirmDelete} from "../../../tools/confirmDelete";
import {useDispatch} from "react-redux";
import {deleteDocument, deleteDraftCategory} from "./document.reducer";
import {handleDownload} from "../../../tools/download";
import {Link} from "react-router-dom";

export const Document = ({data, type}) => {

    const [open, setOpen] = useState(true);

    const [modal, setModal] = useState(false);

    const [id, setId] = useState();

    const dispatch = useDispatch();

    const handleClick = () => {
        setOpen(!open);
    };

    const openModal = (newId) => {
        setId(newId);
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const handleDelete = () => {
        dispatch(deleteDocument(id));
        closeModal();
    }; 


    const [catModal, setCatModal] = useState(false);

    const [catId, setCatId] = useState();

    const openCatModal = (newId) => {
        setCatId(newId);
        setCatModal(true);
    };

    const closeCatModal = () => {
        setCatModal(false);
    };

    const handleCatDelete = () => {
        dispatch(deleteDraftCategory(catId));
        closeModal();
    };

    const getItemTitle = file => {
        const splittedFilePath = file.file.file.split('/');
        return splittedFilePath[splittedFilePath.length - 1];
    }

    const renderList = (data) => (
        <List component="div">
            {data && data.documents && data.documents.length > 0 &&
            data.documents.map((item, index) => (
                <Grid container justify='space-between'>
                    <Grid item>
                        <ListItem key={index} style={{paddingLeft: '4'}}>
                            {item.title}
                        </ListItem>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems='center'>
                            <Grid item>
                                {console.log(item)}
                                <button className="btn btn-primary" onClick={() =>
                                    handleDownload(`user/documents/secured/file/${item.id}`, getItemTitle(item))}>Pobierz
                                </button>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => openModal(item.id)} size='sm'>
                                                <span className='btn btn-danger'><i
                                                    className="fa fa-trash"/> Usuń</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))
            }

        </List>
    )

    return (
        <>
            {type === 'estimate' ?
                renderList(data)
                :
                <>
                    < ListItem style={{paddingLeft: '20'}} button onClick={handleClick}>
                        <ListItemText primary={data.name}/>
                        <Link to={`/users/document/${data.id}`}>
                            <button className="btn btn-primary">Dodaj dokumenty</button>
                        </Link>
                        <Button onClick={() => openCatModal(data.id)} size='sm'>
                          <span className='btn btn-danger'><i className="fa fa-trash"/> Usuń</span>
                        </Button>
                    </ListItem>
                    <Collapse style={{paddingLeft: '15px'}} in={open} timeout="auto" unmountOnExit>
                        {renderList(data)}
                    </Collapse>
                </>
            }
            <ConfirmDelete
                object={"dokument"}
                id={id}
                open={modal}
                close={closeModal}
                delete={handleDelete}
            />
            <ConfirmDelete
                object={"kategorię"}
                id={catId}
                open={catModal}
                close={closeCatModal}
                delete={handleCatDelete}
            />
        </>
    )
};