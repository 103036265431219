import { Switch } from 'react-router-dom';
import Text from './Text';
import Page from './page';
import Media from "./media";
import Partner from './partner';
import PrivateRoute from "../../tools/privateRoute";
import Addon from './advert';

const Content = ({ match }) => (
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/page`} component={Page} />
            <PrivateRoute path={`${match.url}/text`} component={Text} />
            {/*<PrivateRoute path={`${match.url}/image`} component={Image}/>*/}
            <PrivateRoute path={`${match.url}/media`} component={Media} />
            <PrivateRoute path={`${match.url}/partner`} component={Partner} />
            <PrivateRoute path={`${match.url}/advert`} component={Addon} />
        </Switch>
    </>
);

export default Content;