export const fields = [
    {
        name: 'Imię',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Nazwisko',
        id: 'surname',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Email',
        id: 'email',
        type: 'text',
        required: 'To pole jest wymagane',
        // readOnly: true
    },
    {
        name: 'Awatar',
        id: 'avatar',
        type: 'file',
    },
    {
        name: 'Nick',
        id: 'nick',
        type: 'text',
    },
    {
        name: 'Telefon',
        id: 'phone',
        type: 'text',
    },
    {
        name: 'Uprawnienia',
        id: 'authority',
        type: 'select',
        required: 'To pole jest wymagane',
        values: [
            {
                id: 'ROLE_USER',
                name: 'Użytkownik',
            },
            {
                id: 'ROLE_EDITOR',
                name: 'Redaktor',
            },
            {
                id: 'ROLE_COUNSELOR',
                name: 'Doradca',
            },
            {
                id: 'ROLE_ADMIN',
                name: 'Administrator',
            },
            {
                id: 'ROLE_OWNER',
                name: 'Właściciel strony',
            }
        ]
    },
    // {
    //     name: 'Rola',
    //     id: 'role',
    //     type: 'select',
    // },
    {
        name: 'Doradca',
        id: 'advisor',
        type: 'select',
    },
    {
        name: 'Przypisany projekt',
        id: 'design',
        type: 'select',
    },
];

export const discounts = [
    {
        name: 'Rabaty',
        id: 'discount',
        type: 'multiSelect',
    },
];

export const ban = [
    {
        name: 'Koniec banu',
        id: 'banDate',
        type: 'time',
    }
];