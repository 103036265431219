import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getDesignCategory, updateDesignCategory} from "./category.reducer";
import {getDesignSimple} from "../design/design.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";


class Category extends Component{

   componentDidMount() {
       this.props.getDesignSimple();
       this.props.getDesignCategory(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.match.params.id;
       data.designs = data.designs.map((el) => ({id: el}));
       this.props.updateDesignCategory(data);
   }

    cancel(){
        this.props.history.goBack();
    }

    columns = fields;

    render() {
        this.columns[6].values = this.props.designs;
        console.log(this.props.data)
      return (
          <>
              <Page header={`Kategoria - ${this.props.data && this.props.data.name}`}>
              { this.props.data && this.props.data.designs && this.props.designs &&
                <GenericForm
                    content={this.columns}
                    data={{
                        ...this.props.data,
                        designs: this.props.data.designs.map((el) => el.id.toString()),
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({category, design}) => ({
    data: category.data,
    designs: design.data,
});

const mapDispatchToProps = {
    getDesignCategory,
    updateDesignCategory,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);