import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteRefund} from "./refund.reducer";
import Page from "../../../tools/Page";

class Refunds extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'purchase', name: 'Zamówienie', value: 'id', slug: '/purchase'},
                {id: 'user', name: 'Użytkownik', value: 'id', slug: '/users'},
                {id: 'reason', name: 'Powód'},
                {id: 'accountNumber', name: 'Numer konta'},
                {id: 'total', name: 'Do zwrotu'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie zwroty'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'zwrot'}
                    deleteFunction={this.props.deleteRefund}
                    slug={'refund'}
                    disableAdd
                    disableEdit
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({refund}) => ({
    data: refund.data,
    pagination: refund.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteRefund
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Refunds);