import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteBoughtDesign} from "./boughtDesign.reducer";
import Page from "../../../tools/Page";

class BoughtDesigns extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Imię'},
                {id: 'lastName', name: 'Nazwisko'},
                {id: 'email', name: 'Adres email'},
                {id: 'phone', name: 'Telefon'},
                {id: 'design', name: 'Projekt', value: 'name', slug:'/design'},
                {id: 'date', name: 'Data'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Zgłoszenia zakupionych projektów w HK'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'boughtDesign'}
                    deleteFunction={this.props.deleteBoughtDesign}
                    slug={'bought-here'}
                    skip={['date']}
                    downloadLink='form/bought-here/admin/raport.xlsx'
                    disableAdd
                    disableEdit
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({boughtDesign}) => ({
    data: boughtDesign.data,
    pagination: boughtDesign.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteBoughtDesign
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (BoughtDesigns);