import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {Redirect} from "react-router-dom";
import {fields} from "./constants";
import {createShipment} from "./shipment-service.reducer";
import {connect} from "react-redux";

class ShipmentCreatorServicePointEmpty extends Component {

    cancel() {
        this.props.history.goBack();
    }

    save(data) {
        this.props.createShipment(data);
    }

    render() {
        return (
            <Page header={'Dodaj przesyłkę service point'}>
                <GenericForm
                    content={fields}
                    data={{
                        pieceList: {
                            item: [{}],
                        }
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                <Redirect to={`/shipment/web`}/>
                }
            </Page>
        );
    }
}

const mapStateToProps = ({shipmentService}) => ({
    data: shipmentService.data,
    updateSuccess: shipmentService.updateSuccess,
});

const mapDispatchToProps = {
    createShipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentCreatorServicePointEmpty)