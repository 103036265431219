import React, { Component } from "react";
import { connect } from "react-redux";
import GenericForm from "../../../modules/form/GenericForm";
import { getImages, updateImages } from "./details.reducer";
import { getDesignSimple } from "../design/design.reducer";
import { images } from "./constants";

class ImagesDesign extends Component {
  columns = images;

  componentDidMount() {
    this.props.getDesignSimple();
    this.props.getImages(this.props.designId);
  }

  async save(data) {
    data.id = parseInt(this.props.designId);
    await this.props.updateImages(data);
    await this.props.getImages(this.props.designId);
  }

  render() {
    this.columns[0].values = this.props.designs;
    return (
      <>
        {this.props.data &&
          !this.props.loading &&
          this.props.designs &&
          Array.isArray(this.props.designs) && (
            <GenericForm
              content={this.columns}
              data={{
                ...this.props.data,
              }}
              save={this.save.bind(this)}
              cancel={this.props.cancel}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = ({ details, design }) => ({
  data: details.data,
  loading: details.loading,
  designs: design.data,
});

const mapDispatchToProps = {
  getImages,
  updateImages,
  getDesignSimple,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesDesign);
