import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createDesign} from "./design.reducer";
import {getSimpleDesignCategories} from '../category/category.reducer';
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class designCreator extends Component{

    columns = fields;

    componentDidMount() {
        this.props.getSimpleDesignCategories();
    }

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        if(data.categories) {
            data.categories = data.categories.map((el) => {
                return {id: el}
            });
        }
        this.props.createDesign(data);
    }

    render() {
        this.columns[3].values=this.props.categories;
        return (
            <Page info='Ogólne' header={'Swtórz projekt'}>
                <GenericForm
                    content={this.columns}
                    data = {{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                    <Redirect to={`/design/${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({design, category}) => ({
    updateSuccess: design.updateSuccess,
    data: design.data,
    categories: category.data,
});

const mapDispatchToProps = {
    createDesign,
    getSimpleDesignCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(designCreator);