import connection from '../../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_CATEGORIES: 'articleCategory/FETCH_ALL_CATEGORIES',
    FETCH_SIMPLIFIED_CATEGORIES: 'articleCategory/FETCH_SIMPLIFIED_CATEGORIES',
    FETCH_CATEGORY: 'articleCategory/FETCH_CATEGORY',
    UPDATE_CATEGORY: 'articleCategory/UPDATE_CATEGORY',
    DELETE_CATEGORY: 'articleCategory/DELETE_CATEGORY',
    CHANGE_PAGINATION: 'articleCategory/CHANGE_PAGINATION',
    CREATE_CATEGORY: 'articleCategory/CREATE_CATEGORY',
    RESET: 'articleCategory/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const ArticleCategoryState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_CATEGORIES):
        case REQUEST(ActionTypes.FETCH_SIMPLIFIED_CATEGORIES):
        case REQUEST(ActionTypes.FETCH_CATEGORY):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_CATEGORY):
        case REQUEST(ActionTypes.DELETE_CATEGORY):
        case REQUEST(ActionTypes.UPDATE_CATEGORY):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_CATEGORIES):
        case FAILURE(ActionTypes.FETCH_CATEGORY):
        case FAILURE(ActionTypes.CREATE_CATEGORY):
        case FAILURE(ActionTypes.FETCH_SIMPLIFIED_CATEGORIES):
        case FAILURE(ActionTypes.DELETE_CATEGORY):
        case FAILURE(ActionTypes.UPDATE_CATEGORY):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.CREATE_CATEGORY):
        case SUCCESS(ActionTypes.UPDATE_CATEGORY):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_CATEGORIES):
        case SUCCESS(ActionTypes.FETCH_CATEGORY):
        case SUCCESS(ActionTypes.FETCH_SIMPLIFIED_CATEGORIES):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_CATEGORY):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default ArticleCategoryState;

const apiUrl = 'abc/admin/article/category';

export const getAllCategories = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_CATEGORIES,
    payload: connection(applyParamsToUrl(`abc/article/category`,getState().articleCategory.pagination), 'GET', null),
});


export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllCategories());
    return result;
}

export const getSimpleCategories= () =>({
    type: ActionTypes.FETCH_SIMPLIFIED_CATEGORIES,
    payload: connection('abc/article/category/simple', 'GET', null),
});

export const getCategory = (id) =>({
    type: ActionTypes.FETCH_CATEGORY,
    payload: connection(`abc/article/category/${id}`, 'GET', null)
});

export const createCategory = (category) => ({
    type: ActionTypes.CREATE_CATEGORY,
    payload: connection(`${apiUrl}`, 'POST', category)
});

export const updateCategory = (category) => ({
    type: ActionTypes.UPDATE_CATEGORY,
    payload: connection(`${apiUrl}`, 'PUT', category)
});

export const deleteCategory = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_CATEGORY,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllCategories());
    return result;
};