export const fields = [
    {
        name: 'Tytuł',
        id: 'title',
        type: 'text',
    },
    {
        name: 'Opis',
        id: 'content',
        type: 'textarea',
    },
    {
        name: 'Artykuł',
        id: 'article',
        type: 'file',
    },
    {
        name: 'Link',
        id: 'url',
        type: 'text',
    },
    {
        name: 'Zdjęcie',
        id: 'image',
        type: 'file',
    },
];