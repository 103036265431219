import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../../tools/privateRoute";
import CategoryCreator from "./categoryCreator";
import Categories from "./categories";
import CategoryView from "./category";

const Category = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={CategoryCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={CategoryView}/>
            <PrivateRoute exact path={`${match.url}`} component={Categories}/>
        </Switch>
    </>
);

export default Category;