import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";

export const ActionTypes = {
    FETCH_ALL_DOCUMENTS: 'document/FETCH_ALL_DOCUMENTS',
    DELETE_DOCUMENT: 'document/DELETE_DOCUMENT',
    TRASH_DOCUMENT: 'document/TRASH_DOCUMENT',
    ADD_DOCUMENT: 'document/CREATE_DOCUMENT',
    ADD_DRAFT: 'document/CREATE_DRAFT',
    CHANGE_TYPE: 'document/CHANGE_TYPE',
    RESET: 'document/RESET',
};

const initState = {
    loading: false,
    data: null,
    updating: false,
    userId: null,
    type: null,
    prefix: null,
    updateSuccess: false,
    errorMessage: null,
};

const DocumentState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_DOCUMENTS):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.DELETE_DOCUMENT):
        case REQUEST(ActionTypes.ADD_DRAFT):
        case REQUEST(ActionTypes.ADD_DOCUMENT):
        case REQUEST(ActionTypes.TRASH_DOCUMENT):
            return{
                ...state,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_DOCUMENTS):
        case FAILURE(ActionTypes.DELETE_DOCUMENT):
        case FAILURE(ActionTypes.ADD_DOCUMENT):
        case FAILURE(ActionTypes.ADD_DRAFT):
        case FAILURE(ActionTypes.TRASH_DOCUMENT):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.ADD_DOCUMENT):
        case SUCCESS(ActionTypes.ADD_DRAFT):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_DOCUMENTS):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_DOCUMENT):
        case SUCCESS(ActionTypes.TRASH_DOCUMENT):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };
        case ActionTypes.CHANGE_TYPE:
            return {
                ...state,
                userId: action.payload.userId,
                type: action.payload.type,
                prefix: action.payload.prefix,
            };
        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default DocumentState;

const apiUrl = 'user/documents/admin';

export const getAllDocuments = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_DOCUMENTS,
    payload: connection(`${getState().document.prefix}/${getState().document.type}/${getState().document.userId}`, 'GET', null),
});

export const changeType = (prefix, type, userId) => async(dispatch) =>{
    const result = await dispatch({
        type: ActionTypes.CHANGE_TYPE,
        payload: {prefix, type, userId},
    });
    dispatch(getAllDocuments());
    return result;
};

export const addDocument = (document, id) => ({
    type: ActionTypes.ADD_DOCUMENT,
    payload: connection(`user/documents/secured/${id}`, 'POST', document)
});

export const addCategory = (category, id) => ({
    type: ActionTypes.ADD_DOCUMENT,
    payload: connection(`user/documents/secured/category/${id}`, 'POST', category)
});

// export const addDraft = (draft, userId) => ({
//     type: ActionTypes.ADD_DRAFT,
//     payload: connection(`${apiUrl}/draft/${userId}`, 'POST', draft)
// });

export const addDesign = (userId, design, type) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.ADD_DRAFT,
        payload: connection(`${apiUrl}/design/${userId}/type/${type}`, 'POST', design, true)
    });
    dispatch(getAllDocuments());
    return result;
};

// export const addAddon = (userId, addon) => ({
//     type: ActionTypes.ADD_DRAFT,
//     payload: connection(`${apiUrl}/addon/${userId}`, 'POST', addon)
// });
//
// export const addPackage = (userId, addonPackage) => ({
//     type: ActionTypes.ADD_DRAFT,
//     payload: connection(`${apiUrl}/package/${userId}`, 'POST', addonPackage)
// });

export const deleteDraft= (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_DOCUMENT,
        payload: connection(`user/documents/secured/draft/${id}`, 'DELETE', null)
    });
    dispatch(getAllDocuments());
    return result;
};

export const deleteDraftCategory= (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_DOCUMENT,
        payload: connection(`user/documents/secured/category/${id}`, 'DELETE', null)
    });
    dispatch(getAllDocuments());
    return result;
};

export const deleteDocument = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_DOCUMENT,
        payload: connection(`user/documents/secured/document/${id}`, 'DELETE', null)
    });
    dispatch(getAllDocuments());
    return result;
};

export const trashDocument = (id) => ({
    type: ActionTypes.TRASH_DOCUMENT,
    payload: connection(`user/documents/secured/trash/${id}`, 'PUT', null)
});