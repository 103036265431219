import React, {Component} from "react";
import Dropzone from "react-dropzone";
import {backend} from "../../../tools/connection";
import {toast} from "react-toastify";
import {deleteVirtual, getDesign} from "../design/design.reducer";
import {connect} from "react-redux";
import {Button} from "@material-ui/core";

class UploadVirtualWalk extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            path: '',
        }
    }

    componentDidMount() {
        this.props.getDesign(this.props.designId);
    }

    onDrop = (data) => {
        this.setState({file: data[0]});
    }

    onChange = (e) => {
        this.setState({path: e.target.value});
    }


    save(e) {
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append("path", this.state.path);

        fetch(`${backend}design/admin/virtual/${this.props.designId}`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
            },
            body: formData,
        }).then(response => {
            if (response.status !== 200) {
                toast.error("Nie udało się załadować pliku!");
            } else {
                toast.success("Plik zaimportowano");
                this.props.getDesign(this.props.designId);
            }
        });
    }

    render() {
        return (
            <>
                {this.props.data && this.props.data.virtualWalk &&
                <>
                    <a href={`${backend}virtual/${this.props.designId}/${this.props.data.virtualWalk}`} target="_blank" rel="noreferrer">
                        <button className='btn btn-primary'>Zobacz wirtualny spacer</button>
                    </a>
                    <Button onClick={() => this.props.deleteVirtual(this.props.designId)}>
                        <span className='btn btn-danger'><i
                            className="fa fa-trash"/> Usuń</span>
                    </Button>
                </>
                }
                <div className="col-xs-12 col-sm-9 col-md-8">

                    <div className='form-group text-left'>
                        <label className='form-label' style={{fontWeight: "bold"}}>Zaimportuj plik zip zawierający
                            wirtualny spacer</label>
                        <Dropzone onDropAccepted={this.onDrop}
                                  accept={'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip'}>
                            {({getRootProps, getInputProps, fileRejections}) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Naciśnij lub upuść plik</p>
                                        {fileRejections.length > 0 && <div>Wybrano zły plik</div>}
                                    </div>
                                    <div>
                                        {this.state.file && <div>Plik załadowano</div>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                        <label className='form-label' style={{fontWeight: "bold"}}>Ścieżka do pliku startowego.
                            Przykład: jeśli zaimportowano plik HomeKoncept86.zip w którym znajduje się plik index.html,
                            to
                            należy tutaj wpisać: HomeKoncept86/index.html</label>
                        <input type='text' className='form-control' onChange={this.onChange}/>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-9 col-md-8 padding-bottom-30">
                    <div className="text-left">
                        <button type='button' className='btn' onClick={this.props.cancel}>Wróć</button>
                        <button className={'btn btn-primary'} onClick={this.save.bind(this)}>Zapisz</button>
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = ({design}) => ({
    data: design.data,
});

const mapDispatchToProps = {
    getDesign,
    deleteVirtual
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadVirtualWalk);
