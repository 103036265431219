import React, {Component} from "react";
import Page from "../../../../tools/Page";
import GenericForm from "../../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createCategory} from "./category.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class CategoryCreator extends Component{

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        this.props.createCategory(data);
    }

    render() {

        return (
            <Page header={'Dodaj kategorię artykułu'}>
                <GenericForm
                    content={fields}
                    data = {{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({articleCategory}) => ({
    updateSuccess: articleCategory.updateSuccess,
    data: articleCategory.data
});

const mapDispatchToProps = {
    createCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCreator);