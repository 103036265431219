import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteRole} from "./role.reducer";
import Page from "../../../tools/Page";

class Roles extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Nazwa'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie role'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'rola'}
                    deleteFunction={this.props.deleteRole}
                    slug={'roles'}
                    add={'Dodaj nową rolę'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({role}) => ({
    data: role.data,
    pagination: role.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteRole
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Roles);