import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getAddon, updateAddon} from "./addon.reducer";
import {fields} from "./constants";
import {getDesignSimple} from "../design/design.reducer";
import Page from "../../../tools/Page";


class Addon extends Component{

   componentDidMount() {
       this.props.getDesignSimple();
       this.props.getAddon(this.props.match.params.id);
   }

   columns = fields;

   save(data){
       data.id = this.props.data.id;
       data.designs = data.designs.map((el) => ({id: el}));
       this.props.updateAddon(data);
   }

    cancel(){
        this.props.history.goBack();
    }
    
    render() {
        this.columns[5].values = this.props.designs;
        return (
            <Page info='' header={`Dodatek - ${this.props.data && this.props.data.name.replace('<p>', '').replace('</p>', '')}`}>
                { this.props.data && this.props.data.designs && this.props.designs && !this.props.designs.content &&
                    <GenericForm
                        content={this.columns}
                        data={{
                            ...this.props.data,
                            designs: this.props.data.designs.map((el) => el.id.toString()),
                        }}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                }
            </Page>
        );
   }

}

const mapStateToProps = ({addon, design}) => ({
    data: addon.data,
    designs: design.data,
});

const mapDispatchToProps = {
    getAddon,
    updateAddon,
    getDesignSimple,
};

export default connect(mapStateToProps, mapDispatchToProps)(Addon);