import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_ROLES: 'role/FETCH_ALL_ROLES',
    FETCH_SIMPLE: 'role/FETCH_SIMPLE',
    FETCH_ROLE: 'role/FETCH_ROLE',
    UPDATE_ROLE: 'role/UPDATE_ROLE',
    DELETE_ROLE: 'role/DELETE_ROLE',
    CHANGE_PAGINATION: 'role/CHANGE_PAGINATION',
    CREATE_ROLE: 'role/CREATE_ROLE',
    RESET: 'role/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const RoleState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_ROLES):
        case REQUEST(ActionTypes.FETCH_SIMPLE):
        case REQUEST(ActionTypes.FETCH_ROLE):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_ROLE):
        case REQUEST(ActionTypes.DELETE_ROLE):
        case REQUEST(ActionTypes.UPDATE_ROLE):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_ROLES):
        case FAILURE(ActionTypes.FETCH_ROLE):
        case FAILURE(ActionTypes.CREATE_ROLE):
        case FAILURE(ActionTypes.FETCH_SIMPLE):
        case FAILURE(ActionTypes.DELETE_ROLE):
        case FAILURE(ActionTypes.UPDATE_ROLE):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_ROLE):
        case SUCCESS(ActionTypes.UPDATE_ROLE):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };
        case SUCCESS(ActionTypes.FETCH_ALL_ROLES):
        case SUCCESS(ActionTypes.FETCH_ROLE):
        case SUCCESS(ActionTypes.FETCH_SIMPLE):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_ROLE):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default RoleState;

const apiUrl = 'role/admin';

export const getAllRoles = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_ROLES,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().role.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllRoles());
    return result;
};

export const getSimpleRoles = () => ({
    type: ActionTypes.FETCH_SIMPLE,
    payload: connection(`${apiUrl}/simple`, 'GET', null)
});


export const getRole = (id) =>({
    type: ActionTypes.FETCH_ROLE,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createRole = (role) => ({
    type: ActionTypes.CREATE_ROLE,
    payload: connection(`${apiUrl}`, 'POST', role)
});

export const updateRole = (role) => ({
    type: ActionTypes.UPDATE_ROLE,
    payload: connection(`${apiUrl}`, 'PUT', role)
});

export const deleteRole = (id) => async (dispatch) => {
    console.log("fired");
    const result = await dispatch({
        type: ActionTypes.DELETE_ROLE,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllRoles());
    return result;
};