import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createAddonPackage} from "./addonPackage.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class AddonPackageCreator extends Component{

    columns = fields;

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        data.addons = data.addons.map((el) => {return {id: el}});
        this.props.createAddonPackage(data);
    }

    render() {
        return (
            <Page header={'Dodaj pakiet dodatków'}>
                    <GenericForm
                        content={this.columns}
                        data={{}}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({addonPackage}) => ({
    updateSuccess: addonPackage.updateSuccess,
    data: addonPackage.data,
});

const mapDispatchToProps = {
    createAddonPackage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonPackageCreator);