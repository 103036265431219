import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Journals from "./journals";
import JournalView from './journal';


const Journal = ({match}) =>(
    <>
        <Switch>
            {/*<PrivateRoute exact path={`${match.url}/add`} component={JournalCreator}/>*/}
            <PrivateRoute exact path={`${match.url}/:id`} component={JournalView}/>
            <PrivateRoute exact path={`${match.url}`} component={Journals}/>
        </Switch>
    </>
);

export default Journal;