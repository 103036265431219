import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import notificationMiddleware from "./notificationMiddleware";

import rootReducer from './rootReducer';

const defaultMiddlewares = [
    thunkMiddleware,
    notificationMiddleware,
    promiseMiddleware,
];

const initStore = (initialState, middlewares = []) => createStore(rootReducer, initialState,
    compose(applyMiddleware(...middlewares, ...defaultMiddlewares)));


export default initStore;