import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getRotationImages, updateRotationImages} from "./details.reducer";
import {rotationImages} from "./constants";


class RotationImages extends Component{


    componentDidMount() {
        this.props.getRotationImages(this.props.designId);
    }

    save(data){
        this.props.updateRotationImages(this.props.designId, data);
    }


    render() {
        return (
            <>
                { this.props.data && !this.props.loading &&
                <GenericForm
                    content={rotationImages}
                    data={this.props.data}
                    save={this.save.bind(this)}
                    cancel={this.props.cancel}
                />
                }
            </>
        );
    }

}

const mapStateToProps = ({details}) => ({
    data: details.data,
    loading: details.loading,
});

const mapDispatchToProps = {
    getRotationImages,
    updateRotationImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(RotationImages);