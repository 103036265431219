export const draft = {
    design: [{
        name: 'Projekt',
        id: 'design',
        type: 'select',
    }],
    addon:[{
        name: 'dodatek',
        id: 'addon',
        type: 'select',
    }],
    package: [{
        name: 'pakiet dodatków',
        id: 'package',
        type: 'select',
    }]
};

export const category = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text'
    }
];

export const document = [
    {
        name: 'Pliki (.jpg, .png, .pdf)',
        id: 'files',
        type: 'files',
        image: true,
        noAlt: true,
        documentTitle: true
    }
];