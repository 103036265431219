import React, { Component } from "react";
import { connect } from 'react-redux';
import { getPurchase, updatePurchase } from "./purchase.reducer";
import Page from "../../../tools/Page";
import { Link } from "react-router-dom";
import PurchaseForm from "./details/purchaseForm";
import { getSimpleAddons } from "../../design/addons/addon.reducer";
import { getDesignSimple } from "../../design/design/design.reducer";
import { getSimplePackages } from "../../design/addonPackages/addonPackage.reducer";

class Purchase extends Component {
    componentDidMount() {
        this.props.getPurchase(this.props.match.params.id);
        this.props.getSimpleAddons();
        this.props.getSimplePackages();
        this.props.getDesignSimple();
    }
    getProductProp(product, prop) {
        if (product.boughtAddon !== null)
            return product.boughtAddon[prop];
        if (product.boughtAddonPackage !== null)
            return product.boughtAddonPackage[prop];
        if (product.boughtDesign !== null)
            return product.boughtDesign[prop];
    }
    save(data) {
        if (data.products)
            data.products = adjustProducts(data.products);
        data.id = Number(this.props.data.id);
        data.products = data.products.map((product, index) => (
            { ...product, design: { id: this.props.data.products[index].design ? this.props.data.products[index].design.id : this.props.data.products[index].boughtDesign.id } }
        ));
        this.props.updatePurchase(data);
    }

    cancel() {
        this.props.history.goBack();
    }


    render() {
        return (
            <>
                <Page header={`Zamówienie ${this.props.match.params.id}`}>
                    {this.props.data && this.props.data.delivery !== 'PERSONAL' &&
                        <Link to={`/shipment/${this.props.data.delivery === 'COURIER' ? 'web' : 'servicepoint'}/purchase/${this.props.match.params.id}`} style={{ display: 'flex', marginLeft: '60px' }}>
                            <button className="btn btn-primary">Dodaj przesyłkę</button>
                        </Link>
                    }
                    {this.props.data && Array.isArray(this.props.designs) && Array.isArray(this.props.addons) && Array.isArray(this.props.packages) &&
                        <PurchaseForm
                            data={this.props.data}
                            save={this.save.bind(this)}
                            cancel={this.cancel.bind(this)}
                            designs={this.props.designs}
                            addons={this.props.addons}
                            packages={this.props.packages}
                        />
                    }
                </Page>
            </>
        );
    }

}

export const adjustProducts = (products) => {
    return products.map(el => {
        return {
            ...el,
            design: el.design && { id: el.design },
            boughtAddon: el.boughtAddon && { id: el.boughtAddon },
            boughtDesign: el.boughtDesign && { id: el.boughtDesign },
            boughtAddonPackage: el.boughtAddonPackage && { id: el.boughtAddonPackage },
        }
    })
}

const mapStateToProps = ({ purchase, design, addon, addonPackage }) => ({
    data: purchase.data,
    designs: design.data,
    addons: addon.data,
    packages: addonPackage.data,

});

const mapDispatchToProps = {
    getPurchase,
    updatePurchase,
    getSimplePackages,
    getDesignSimple,
    getSimpleAddons
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchase);