const sortByProp = (arrayToSort, prop) => {
    return arrayToSort.sort((a, b) => {
        if (a[prop] > b[prop])
            return 1;
        else if (a[prop] < b[prop])
            return -1;
        return 0;
    });
}

export default sortByProp;