import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_PARTNERS: 'partner/FETCH_ALL_PARTNERS',
    FETCH_PARTNER: 'partner/FETCH_PARTNER',
    UPDATE_PARTNER: 'partner/UPDATE_PARTNER',
    DELETE_PARTNER: 'partner/DELETE_PARTNER',
    CHANGE_PAGINATION: 'partner/CHANGE_PAGINATION',
    CREATE_PARTNER: 'partner/CREATE_PARTNER',
    RESET: 'partner/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const PartnerState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_PARTNERS):
        case REQUEST(ActionTypes.FETCH_PARTNER):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_PARTNER):
        case REQUEST(ActionTypes.DELETE_PARTNER):
        case REQUEST(ActionTypes.UPDATE_PARTNER):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_PARTNERS):
        case FAILURE(ActionTypes.FETCH_PARTNER):
        case FAILURE(ActionTypes.CREATE_PARTNER):
        case FAILURE(ActionTypes.DELETE_PARTNER):
        case FAILURE(ActionTypes.UPDATE_PARTNER):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_PARTNER):
        case SUCCESS(ActionTypes.UPDATE_PARTNER):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_PARTNERS):
        case SUCCESS(ActionTypes.FETCH_PARTNER):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_PARTNER):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };
        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default PartnerState;

const apiUrl = 'cms/partner';

export const getAllPartners = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_PARTNERS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().partner.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllPartners());
    return result;
}


export const getPartner = (id) =>({
    type: ActionTypes.FETCH_PARTNER,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createPartner = (partner) => ({
    type: ActionTypes.CREATE_PARTNER,
    payload: connection(`cms/admin/partner`, 'POST', partner)
});

export const updatePartner = (partner) => ({
    type: ActionTypes.UPDATE_PARTNER,
    payload: connection(`cms/admin/partner`, 'PUT', partner)
});

export const deletePartner = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_PARTNER,
        payload: connection(`cms/admin/partner/${id}`, 'DELETE', null)
    });
    dispatch(getAllPartners());
    return result;
};