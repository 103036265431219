import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deletePost} from "./post.reducer";
import Page from "../../../tools/Page";

class Posts extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'date', name: 'Data'},
                {id: 'author', name: 'Użytkownik', value: 'name', slug:'/users'}
            ],
            search: this.props.location.search.split('search=')[1],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie posty'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'post'}
                    deleteFunction={this.props.deletePost}
                    slug={'post'}
                    disableAdd
                    search={this.state.search || ''}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({post}) => ({
    data: post.data,
    pagination: post.pagination
});

const mapDispatchToProps = {
    changePagination,
    deletePost
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Posts);