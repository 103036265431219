import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import Page from "../../../tools/Page";
import {changePagination} from "../../forum/post/post.reducer";
import {deletePost} from "../../forum/post/post.reducer";

class Opinions extends Component{

    constructor(props) {
        super(props);
        this.state={
            data: this.props.data,
            keys: [
                {id: 'date', name: 'Data'},
                {id: 'author', name: 'Imię', value: 'name'},
                {id: 'author', name: 'Nazwisko', value: 'surname'}
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie opinie'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    deleteFunction={this.props.deletePost}
                    slug={'opinion'}
                    disableAdd
                    search='isOpinion:true,auth:ROLE_USER'
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({post}) => ({
    data: post.data,
    pagination: post.pagination
});

const mapDispatchToProps = {
    changePagination,
    deletePost,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Opinions);