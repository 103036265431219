import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteArchitect} from "./architect.reducer";
import Page from "../../../tools/Page";

class Architects extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Nazwa'},
                {id: 'website', name: 'Strona internetowa'},
                {id: 'emailOne', name: 'Email'},
                {id: 'address', name: 'Adres'}
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszyscy architekci'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'architekt'}
                    deleteFunction={this.props.deleteArchitect}
                    slug={'architect'}
                    add={'Dodaj nowego architekta'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({architect}) => ({
    data: architect.data,
    pagination: architect.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteArchitect
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Architects);