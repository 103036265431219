import React, {Component} from "react";
import Page from "../../tools/Page";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Design from "./design/design";
import Description from "./details/description";
import Downloadable from "./details/downloadable";
import Estimate from "./details/estimate";
import Projection from "./details/projection";
import Technical from "./details/technical";
import Similar from './details/similar';
import UploadVirtualWalk from "./details/uploadVirtualWalk";
import {Grid} from "@material-ui/core";
import DesignAddons from "./details/addons";
import RotationImages from "./details/rotations";
import {fields} from "./design/constants";
import {getDesign} from "./design/design.reducer";
import {connect} from "react-redux";
import './design/_design.scss';
import ImagesDesign from "./details/imagesDesign";

export class MainDesign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            panel: 0,
        }
    }

    columns = fields;

    componentDidMount() {
        this.props.getDesign(this.props.match.params.id);
        console.log(this.props.data)
    }

    handleChange = (event, newValue) => {
        this.setState({
            panel: newValue,
        })
    };

    cancel() {
        this.props.history.goBack();
    }

    render() {
        return (
            <Page info='' header={`Projekt domu ${this.props.data && this.props.data.name}`} styles={{flexGrow: 1, display: 'flex'}}>
                <Grid container>
                    <Grid item xs={2}>
                        <Tabs value={this.state.panel} onChange={this.handleChange}
                              orientation='vertical' variant='scrollable'>
                            <Tab label='Ogólne' id='simple-tab-0'/>
                            <Tab label='Rzuty' id='simple-tab-1'/>
                            <Tab label='Dane techniczne' id='simple-tab-2'/>
                            <Tab label='Kosztorys' id='simple-tab-3'/>
                            <Tab label='Opis' id='simple-tab-4'/>
                            <Tab label='Pliki do pobrania' id='simple-tab-5'/>
                            <Tab label='Dodatki' id='simple-tab-6'/>
                            <Tab label='Wirtualny spacer' id='simple-tab-7'/>
                            <Tab label='Obroty 3D' id='simple-tab-8'/>
                            <Tab label='Zdjęcia' id='simple-tab-9'/>
                            <Tab label='Podobne projekty' id='simple-tab-10'/>
                        </Tabs>
                    </Grid>
                    <Grid item xs={10}>
                        <>
                        <TabPanel index={0} value={this.state.panel}>
                            <Design designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={1} value={this.state.panel}>
                            <Projection designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={2} value={this.state.panel}>
                            <Technical designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={3} value={this.state.panel}>
                            <Estimate designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={4} value={this.state.panel}>
                            <Description designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={5} value={this.state.panel}>
                            <Downloadable designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={6} value={this.state.panel}>
                            <DesignAddons designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={7} value={this.state.panel}>
                            <UploadVirtualWalk designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={8} value={this.state.panel}>
                            <RotationImages  designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={9} value={this.state.panel}>
                            <ImagesDesign designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                        <TabPanel index={10} value={this.state.panel}>
                            <Similar designId={this.props.match.params.id} cancel={this.cancel.bind(this)}/>
                        </TabPanel>
                            </>
                    </Grid>
                </Grid>
            </Page>
        );
    }
}

export function TabPanel(props) {
    const {children, value, index} = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const mapStateToProps = ({design, category}) => ({
    data: design.data,
});

const mapDispatchToProps = {
    getDesign,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainDesign);

//export default MainDesign;