import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import PartnerCreator from "./partnerCreator";
import Partners from "./partners";
import PartnerView from "./partner";

const Partner = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={PartnerCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={PartnerView}/>
            <PrivateRoute exact path={`${match.url}`} component={Partners}/>
        </Switch>
    </>
);

export default Partner;