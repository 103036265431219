import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { TAB_NAMES, USER_ROLES, AUTHED_TABS } from "./user_access_constants";


const Page = (props) => {
    const userRole = useSelector(state => state.auth.user.authority);
    const history = useHistory();
    const tabName = String(history.location.pathname.split('/')[1]).toUpperCase()?.replaceAll('-', '_');
    const checkUserAuthority = () => {
        if (userRole !== USER_ROLES.ADMIN  && userRole !== USER_ROLES.OWNER && !AUTHED_TABS[userRole].includes(TAB_NAMES[tabName])) {
            history.push('/');
            toast.error('Brak uprawnień!');
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { checkUserAuthority() }, [history]);

    

    return (
        <>
            <div className='col-xs-12'>
                <div className="page-title">

                    <div className="pull-left">
                        <h1 className="title">{props.header}</h1>
                    </div>

                </div>
            </div>
            <div className={props.name ? `col-xs-12 ${props.name}` : "col-xs-12"}>
                <div className="col-xs-12">
                    <section className="box ">
                        <header className="panel_header">
                            <h2 className="title pull-left">{props.info}</h2>
                        </header>
                        <div className="content-body">
                            <div className="row">
                                {props.children}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}



export default Page;