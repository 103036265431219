import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteForumCategory} from "./forumCategory.reducer";
import Page from "../../../tools/Page";

class ForumCategories extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Nazwa'}
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie kategorie forum'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'kategorię forum'}
                    deleteFunction={this.props.deleteForumCategory}
                    slug={'category'}
                    add={'Dodaj nową kategorię forum'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({forumCategory}) => ({
    data: forumCategory.data,
    pagination: forumCategory.pagination,
});

const mapDispatchToProps = {
    changePagination,
    deleteForumCategory
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (ForumCategories);