import React, {Component} from "react";
import {getMyShipments, deleteShipment} from "./shipment-web.reducer";
import {connect} from "react-redux";
import Page from "../../../tools/Page";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter,
    TableHead, TablePagination,
    TableRow,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ConfirmDelete} from "../../../tools/confirmDelete";

class Shipments extends Component {

    constructor(props) {
        super(props);
        let daysAgo = new Date();
        daysAgo.setDate(daysAgo.getDate() - 90);

        this.state = {
            keys: [
                {id: 'shipmentId', name: 'Identyfikator przesyłyki'},
                {id: 'created', name: 'Data utworzenia'},
                {id: 'shipper', name: 'Nadawca', value: 'name' },
                {id: 'receiver', name: 'Odbiorca', value: 'name' },
                {id: 'orderStatus', name: 'Status' }
            ],
            id: null,
            open: false,
            page: 0,
            from: daysAgo.toJSON().slice(0,10).replace(/-/g,'-'),
            to: new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            offset: 0,
        }
    }

    closeModal = () => {
        this.setState({
            open: false
        })
    };

    openModal = (id) => {
        this.setState({
            id: id,
            open: true
        });
    };

    handleDelete = () =>{
        this.setState({
            open: false
        },
            () => this.props.deleteShipment(this.state.id)
        );
        this.getData();
    };

    changePage = (e, page) => {
        this.setState({
                page: page,
                offset: page* 100,
        },
            () => this.getData()
        );
    }

    getData = () =>{
        this.props.getMyShipments(this.state.from, this.state.to, this.state.offset);
    }

    componentDidMount() {
        this.getData();
    }

    getCount = () => {
        if(this.props.data){
            if(this.props.data.length === 100){
                // dont know exact number of shipments
                return this.state.offset + 101;
            }else{
                return this.state.offset + this.props.data.length;
            }
        }else{
            return 0;
        }
    }

    render() {
        const {data} = this.props;
        return (
            <Page header='Przesyłki z ostatnich 90 dni'>
                <>
                    <div className='text-right'>
                        <Grid container justify='flex-end' spacing={2}>
                            <Grid item>
                                <Link to={`web/add`}>
                                    <button className="btn btn-primary">Dodaj przesyłkę kurierską</button>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to={`servicepoint/add`}>
                                    <button className="btn btn-primary">Dodaj przesyłkę servicePoint</button>
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {this.state.keys.map((item, i) => (
                                        <TableCell key={i}>
                                                {item.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data && Array.isArray(data) && data.map((item, i) =>(
                                    <TableRow hover key={i}>
                                        {this.state.keys.map((value, i2)=>(
                                            value.value ?
                                                (<TableCell key={i2}>
                                                        {item[value.id] && (item[value.id])[value.value]}
                                                    </TableCell>
                                                )
                                                :
                                                (<TableCell key={i2}>
                                                        {item[value.id]}
                                                    </TableCell>
                                                )
                                        ))}
                                        {/*<TableCell align={'right'}>*/}
                                        {/*    <Button onClick={() => this.openModal(item.shipmentId)} size='sm'>*/}
                                        {/*        <span className='btn btn-danger'><i*/}
                                        {/*            className="fa fa-trash"/> Usuń</span>*/}
                                        {/*    </Button>*/}
                                        {/*</TableCell>*/}
                                    </TableRow>
                                ))}
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    {data &&
                                    <TablePagination
                                        rowsPerPageOptions={[100]}
                                        count={this.getCount()}
                                        rowsPerPage={100}
                                        page={this.state.page}
                                        onChangePage={this.changePage}
                                    />
                                    }
                                </TableRow>
                            </TableFooter>
                        </Table>

                    </TableContainer>
                    <ConfirmDelete
                        object={'przesyłkę'}
                        id={this.state.id}
                        open={this.state.open}
                        close={this.closeModal.bind(this)}
                        delete={this.handleDelete.bind(this)}
                    />
                </>
            </Page>
        );
    }
}


const mapStateToProps = ({shipmentWeb}) => ({
    data: shipmentWeb.data,
});

const mapDispatchToProps = {
    getMyShipments,
    deleteShipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shipments)