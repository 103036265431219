import React, {forwardRef} from 'react';

export const Item = forwardRef(({ style, ...props}, ref) => {
    return (
      <div style={{padding: 5, ...style}} {...props} ref={ref}>
         {props.children}
      </div>
    );
});

