import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import SpecialCreator from "./specialCreator";
import Specials from "./specials";
import SpecialView from "./special";

const Special = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={SpecialCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={SpecialView}/>
            <PrivateRoute exact path={`${match.url}`} component={Specials}/>
        </Switch>
    </>
);

export default Special;