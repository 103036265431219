import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import ManufacturerCreator from "./manufacturerCreator";
import Manufacturers from "./manufacturers";
import ManufacturerView from "./manufacturer";

const Manufacturer = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={ManufacturerCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={ManufacturerView}/>
            <PrivateRoute exact path={`${match.url}`} component={Manufacturers}/>
        </Switch>
    </>
);

export default Manufacturer;