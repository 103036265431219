import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import DiscountCreator from "./discountCreator";
import Discounts from "./discounts";
import DiscountView from "./discount";

const Discount = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add/:id?`} component={DiscountCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={DiscountView}/>
            <PrivateRoute exact path={`${match.url}`} component={Discounts}/>
        </Switch>
    </>
);

export default Discount;