import React from "react";
import {useFieldArray} from "react-hook-form";
import renderInput from "./input";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import './ListForm.css';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Mirror} from "../../entities/design/design/icons/mirror";

export const ListForm = ({content, methods, previous}) => {

    const name = previous ? previous : content.id;

    const {control} = methods;

    const {fields, append, remove, move} = useFieldArray({
            control,
            name: name,
            keyName: 'randomKeyName'
        }
    );

    function getListElement(outerItem, index, handleProps) {
        return (
            <>
                <span {...handleProps} style={{margin: '8px', width: '100%', position: 'relative', top: '40px', zIndex: '900', left: "10px"}}>
                    <i className='fa fa-bars'/>
                </span>
            <div className='thumb-box' style={{
            backgroundColor: content.nested ?
                '#e7e7e7' :
                '#d0cece'
        }}>
            <Grid container justify='space-between' alignItems='center' spacing={10}>
                <Grid item xs={10}>
                    <input hidden ref={methods.register()} name={`${name}[${index}].id`}
                           value={outerItem.id} readOnly/>
                    <input hidden ref={methods.register()} name={`${name}[${index}].orderNumber`}
                           value={index} readOnly/>
                    <Grid container spacing={2}>
                        {content.values && content.values.map((item, it) => (
                            <Grid item xs={10} sm={getSize(item.type)}>
                                <Box ml={4} style={{width: '100%'}}>
                                    <label className='form-label'>{item.mirror && item.mirror === true ? <div className="mirrorType"><Mirror /> {item.name}</div> : item.name}</label>
                                    {renderInput(item, outerItem[item.id]
                                        , methods, false, `${name}[${index}].${item.id}`,
                                        `${name}[${index}].${item.id}`)}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <span className="clear" onClick={() => remove(index)}>
                    <i className='fa fa-times'/>
                </span>
            </Grid>
        </div>
                </>);
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        move(result.source.index, result.destination.index);
    }

    const elements = () => (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={name}>
                {
                    (provided) => (
                        <ul className='elements' {...provided.droppableProps} ref={provided.innerRef}
                            style={{'list-style-type': 'none'}}>
                            {
                                fields.map((element, index) => (
                                    <Draggable
                                        // key={element.randomKeyName}
                                        // draggableId={element.id && element.id.toString()}
                                        key={`${name}-${element.randomKeyName}`}
                                        draggableId={`${name}-item-${element.randomKeyName}`}
                                        index={index}>
                                        {
                                            (secondProvided) => (
                                                <li key={element.randomKeyName} ref={secondProvided.innerRef} {...secondProvided.draggableProps}>
                                                    {getListElement(element, index, secondProvided.dragHandleProps)}
                                                </li>
                                            )
                                        }
                                    </Draggable>
                                ))
                            }
                            {provided.placeholder}
                        </ul>
                    )
                }
            </Droppable>
        </DragDropContext>
    )

    const generateId = () =>{
        let start = `${fields.length}`;
        let add = 1;
        let current = start;
        // eslint-disable-next-line no-loop-func
        while(fields.filter(el => el.id === current).length !== 0){
            current = start + add;
            add = add+1;
        }
        return current;
    }

    console.log(fields);
    return (
        <>
            <Grid container alignItems='center' direction='column' spacing={2}>
                <Grid item>
                    {/*{fields.map((outerItem, index) => getListElement(outerItem, index))}*/}
                    {elements()}
                </Grid>
                {!content.noAdd &&
                <Grid item>
                    <button type='button' className='btn-primary btn' onClick={() => {
                        console.log(methods.getValues());
                        console.log(name)
                       append({id: generateId(), orderNumber: fields.length});
                    }}>
                        Dodaj
                    </button>
                </Grid>
                }
            </Grid>
        </>
    );
};

export const getSize = (type) => {
    switch (type) {
        case 'file':
        case 'list':
        case 'textarea':
            return 12;
        default:
            return 3;
    }
}