export const fields = [
    {
        name: 'Nazwa szablonu',
        id: 'templateName',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Tytuł',
        id: 'title',
        type: 'text',
    },
    {
        name: 'Opis',
        id: 'description',
        type: 'textarea',
    },
    {
        name: 'Szczegóły',
        id: 'details',
        type: 'textarea',
    },
    {
        name: 'Zdjęcie',
        id: 'image',
        type: 'file',
    },
    {
        name: 'Miniaturka',
        id: 'minImage',
        type: 'file',
    }
];