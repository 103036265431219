import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../../modules/table/GenericTable";
import {changePagination, deleteCategory} from "./category.reducer";
import Page from "../../../../tools/Page";

class Categories extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Nazwa'}
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie kategorie artykułów'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'kategorię artykułu'}
                    deleteFunction={this.props.deleteCategory}
                    slug={'category'}
                    add={'Dodaj nową kategorię artykułu'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({articleCategory}) => ({
    data: articleCategory.data,
    pagination: articleCategory.pagination,
});

const mapDispatchToProps = {
    changePagination,
    deleteCategory
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Categories);