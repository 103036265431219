import {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getStaticText, updateStaticText} from "./text.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";


class Text extends Component{

   componentDidMount() {
       this.props.getStaticText(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.data.id;
       this.props.updateStaticText(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {
      return (
          <>
              <Page header={`Tekst statyczny ${this.props.match.params.id}`}>
              { this.props.data && !Array.isArray(this.props.data.content) &&
                <GenericForm
                    content={fields}
                    data={this.props.data}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({text}) => ({
   data: text.data
});

const mapDispatchToProps = {
    getStaticText,
    updateStaticText
};

export default connect(mapStateToProps, mapDispatchToProps)(Text);