import React from "react";
import {useForm} from "react-hook-form";
import {Grid} from "@material-ui/core";

export const Search = ({onSearch}) => {

    const {register, handleSubmit} = useForm();

    return (
        <>
            <form onSubmit={handleSubmit(onSearch)}>
                <div className='text-right'>
                    <Grid container direction='row' alignItems='center' spacing='1'>
                        <Grid item>
                            <input className='form-control' type='text' placeholder='szukaj' name='search' ref={register}/>
                        </Grid>
                        <Grid item>
                            <input hidden type='submit' name='submit'/>
                            <button name='submit'><i className="fa fa-search"/></button>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </>
    );
}