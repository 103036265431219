import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createRealization} from "./realization.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class RealizationCreator extends Component{

    componentDidMount() {
        this.props.getDesignSimple();
    }

    columns = fields;
    cancel(){
        this.props.history.goBack();
    }

    save(data){
        data.design = {id: data.design};
        this.props.createRealization(data);
    }

    render() {
        this.columns[3].values=this.props.designs;
        return (
            <Page header={'Dodaj realizację'}>
                <GenericForm
                    content={this.columns}
                    data = {{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({realization, design}) => ({
    updateSuccess: realization.updateSuccess,
    data: realization.data,
    designs: design.data,
});

const mapDispatchToProps = {
    createRealization,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(RealizationCreator);