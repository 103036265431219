import {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createArticle} from "./article.reducer";
import {getSimpleCategories} from "./category/category.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import uploadFile from "../../../tools/uploadFile";

class ArticleCreator extends Component{

    state={
        images: [],
    }

    componentDidMount() {
        this.props.getSimpleCategories();
    }

    columns = fields;
    cancel(){
        this.props.history.goBack();
    }


    handleFileUpload = (img) => {
        console.log(img);
        return uploadFile(`file/admin`, img).then(
            (result) => {
                this.setState((previous) => {
                    return {
                        images: [...previous.images, result]
                    }
                });
                return result;
            }
        )
    }

    save(data){
        data.category = {id: data.category};
        data.images = this.state.images.map(el => {
            return {
                id: el.id
            }
        })
        this.props.createArticle(data);
    }

    render() {
        this.columns[1].callback = this.handleFileUpload;
        this.columns[4].values=this.props.categories;
        return (
            <Page header={'Dodaj artykuł'}>
                {Array.isArray(this.props.categories) &&
                <GenericForm
                    content={this.columns}
                    data={{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                }
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({article, articleCategory}) => ({
    updateSuccess: article.updateSuccess,
    data: article.data,
    categories: articleCategory.data,
});

const mapDispatchToProps = {
    createArticle,
    getSimpleCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCreator);