import {Switch} from 'react-router-dom';
import Texts from './texts';
import TextView from "./text";
import TextCreator from "./textCreator";
import PrivateRoute from "../../../tools/privateRoute";

const Text = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={TextCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={TextView}/>
            <PrivateRoute exact path={`${match.url}`} component={Texts}/>
        </Switch>
    </>
);

export default Text;