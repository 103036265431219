import React, { Component } from "react";
import { connect } from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import { getAddon, updateAddon } from "./advert.reducer";
import { fields } from "./constants";
import { getDesignSimple } from "../../design/design/design.reducer";
import Page from "../../../tools/Page";


class Addon extends Component {

    componentDidMount() {
        this.props.getDesignSimple();
        this.props.getAddon(this.props.match.params.id);
    }

    columns = fields;

    save(data) {

        let body = {
            id: this.props.data.id,
            recommend: false,
            excludedFromFreeChoice: false,
            isPromoMaterials: true,
            price: data.price,
            name: data.name,
            description: data.description,
            orderNumber: data.orderNumber,
            details: data.details,
            image: data.image,
            maxCount: data.maxCount
        }
        this.props.updateAddon(body);
        this.props.history.goBack();
    }

    cancel() {
        this.props.history.goBack();
    }

    render() {
        return (
            <Page info='' header={`Dodatek - ${this.props.data && this.props.data.name}`}>
                {this.props.data &&
                    <GenericForm
                        content={this.columns}
                        data={{
                            ...this.props.data,
                        }}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                }
            </Page>
        );

    }

}

const mapStateToProps = ({ addon, design }) => ({
    data: addon.data,
    designs: design.data,
});

const mapDispatchToProps = {
    getAddon,
    updateAddon,
    getDesignSimple,
};

export default connect(mapStateToProps, mapDispatchToProps)(Addon);