import {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getArchitect, updateArchitect} from "./architect.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";


class Architect extends Component{

   componentDidMount() {
       this.props.getArchitect(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.data.id;
       this.props.updateArchitect(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {
      return (
          <>
              <Page header={`Architekt ${this.props.match.params.id}`}>
              { this.props.data &&
                <GenericForm
                    content={fields}
                    data={this.props.data}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({architect}) => ({
   data: architect.data
});

const mapDispatchToProps = {
    getArchitect,
    updateArchitect
};

export default connect(mapStateToProps, mapDispatchToProps)(Architect);