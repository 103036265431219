export const fields = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Opis na górze',
        id: 'editorTop',
        type: 'page',
    },
    {
        name: 'Opis na dole',
        id: 'editorBottom',
        type: 'page',
    },
    {
        name: 'Zdjęcie',
        id: 'image',
        type: 'file',
        image: {
            name: false,
            description: false,
        }
    },
    {
        name: 'Banner',
        id: 'bannerImage',
        type: 'file',
        image: {
            name: false,
            description: false,
        }
    },
    {
        name: 'Slug',
        id: 'slug',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Projekty',
        id: 'designs',
        type: 'multiSelect',
    },
    {
        name: 'Meta tytuł',
        id: 'pageTitle',
        type: 'text',
    },
    {
        name: 'Meta opis',
        id: 'pageDescription',
        type: 'text',
    }
];