import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import SidebarMenuItem from "./SidebarMenuItem";
import { USER_ROLE_NAMES } from "./constants";
import { TAB_NAMES } from "../../tools/user_access_constants";

export const Sidebar = (props) => {
  const [currentTab, setCurrentTab] = useState("");

  const renderElement = (path, text) => (
    <li>
      <Link
        to={`${path}`}
        className={`${currentTab === path && "active"}`}
        onClick={() => setCurrentTab(path)}
      >
        {text}
      </Link>
    </li>
  );
  const getUserRoleName = () => USER_ROLE_NAMES[props.user.authority];

  return (
    <div className="page-sidebar pagescroll">
      <div className="page-sidebar-wrapper" id="main-menu-wrapper">
        <div className="profile-info row">
          <div className="profile-image col-xs-4">
            <Link to="/admin/profile">
              {props.user && props.user.avatar ? (
                <img
                  alt=""
                  src={props.user.avatar.file}
                  className="img-responsive img-circle"
                />
              ) : (
                <img
                  alt=""
                  src="/admin/images/profile/profile.jpg"
                  className="img-responsive img-circle"
                />
              )}
            </Link>
          </div>

          <div className="profile-details col-xs-8">
            <h3>
              <Link to="/admin/profile">
                {props.user && `${props.user.firstName} ${props.user.lastName}`}
              </Link>

              <span className="profile-status online"></span>
            </h3>

            <p className="profile-title">
              {props && props.user && props.user.authority
                ? getUserRoleName()
                : ""}
            </p>
          </div>
        </div>

        <ul className="wraplist">
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.STATIC_CONTENT}
            >
              <Grid item>
                <i className="fa fa-files-o"></i>
              </Grid>
              <Grid item>
                <span className="title">Zawartość statyczna</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/static-content/page", "Strony")}
              {renderElement("/static-content/text", "Teksty")}
              {renderElement("/static-content/media", "Media")}
              {renderElement("/static-content/partner", "Partnerzy")}
              {renderElement("/static-content/advert", "Materiały reklamowe")}
            </ul>
          </li>
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.DESIGN}
            >
              <Grid item>
                <i className="fa fa-edit"></i>
              </Grid>
              <Grid item>
                <span className="title">Projekty Domów</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/design", "Lista projektów")}
              {renderElement("/design/sale", "Promocje")}
              {renderElement("/design/category", "Kategorie")}
              {renderElement("/design/addon", "Dodatki")}
              {renderElement("/design/addonOrder", "Kolejność dodatków")}
              {renderElement("/design/addonPackage", "Pakiety dodatków")}
              {renderElement("/design/opinion", "Opinie użytkowników")}

            </ul>
          </li>
          <li>
            <SidebarMenuItem container justify="flex-start" tab={TAB_NAMES.ABC}>
              <Grid item>
                <i className="fa fa-files-o"></i>
              </Grid>
              <Grid item>
                <span className="title">Deweloper</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/abc/estate", "Osiedla Deweloperskie")}
            </ul>
          </li>
          <li>
            <SidebarMenuItem container justify="flex-start" tab={TAB_NAMES.ABC}>
              <Grid item>
                <i className="fa fa-files-o"></i>
              </Grid>
              <Grid item>
                <span className="title">ABC Budowy</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/abc/architect", "Architekci")}
              {renderElement("/abc/article", "Baza wiedzy")}
              {renderElement("/abc/article/category", "Kategorie artykułów")}
              {renderElement("/abc/company", "Firmy budowlane")}
              {renderElement("/abc/manufacturer", "Producenci")}
            </ul>
          </li>
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.FORMS}
            >
              <Grid item>
                <i className="fa fa-files-o"></i>
              </Grid>
              <Grid item>
                <span className="title">Formularze</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu" style={{ textAlign: "left" }}>
              {renderElement("/forms/contact", "Kontakt")}
              {renderElement("/forms/design-selection", "Dobór projektu")}
              {renderElement("/forms/newsletter", "Newsletter")}
              {renderElement("/forms/marketing", "Zgody marketingowe")}
              {renderElement("/forms/order-catalogue", "Zamówienia katalogu")}
              {renderElement("/forms/bought-here", "Zamówiono projekt w HK")}
              {renderElement(
                "/forms/bought-elsewhere",
                "Zamówiono projekt gdzie indziej"
              )}
            </ul>
          </li>
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.FORUM}
            >
              <Grid item>
                <i className="fa fa-files-o"></i>
              </Grid>
              <Grid item>
                <span className="title">Forum</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/forum/thread", "Wątki")}
              {renderElement("/forum/category", "Kategorie forum")}
              {renderElement("/forum/post", "Wszystkie posty")}
            </ul>
          </li>
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.COMMUNITY}
            >
              <Grid item>
                <i className="fa fa-user"></i>
              </Grid>
              <Grid item>
                <span className="title">Społeczność</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/community/realization", "Realizacje")}
              {renderElement("/community/journal", "Dzienniki budowy")}
            </ul>
          </li>
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.OFFERS}
            >
              <Grid item>
                <i className="fa fa-dollar"></i>
              </Grid>
              <Grid item>
                <span className="title">Rabaty</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/offers/template", "Szablony rabatów")}
              {renderElement("/offers/discount", "Rabaty")}
              {renderElement("/offers/special", "Oferty Specjalne")}
            </ul>
          </li>
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.PURCHASE}
            >
              <Grid item>
                <i className="fa fa-dollar"></i>
              </Grid>
              <Grid item>
                <span className="title">Zamówienia</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/purchase", "Zamówienia")}
              {renderElement("/shipment/web", "Przesyłki")}
              {renderElement("/purchase/refund", "Zwroty")}
              {/*{renderElement('/purchase/exchange', "Wymiana")}*/}
            </ul>
          </li>
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.USERS}
            >
              <Grid item>
                <i className="fa fa-user"></i>
              </Grid>
              <Grid item>
                <span className="title">Użytkownicy</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/users", "Lista użytkowników")}
              {renderElement("/roles", "Role użytkowników")}
            </ul>
          </li>
          <li>
            <SidebarMenuItem
              container
              justify="flex-start"
              tab={TAB_NAMES.USERS}
            >
              <Grid item>
                <i className="fa fa-files-o"></i>
              </Grid>
              <Grid item>
                <span className="title">Przekierowania 301</span>
              </Grid>
              <Grid item>
                <span className="arrow "></span>
              </Grid>
            </SidebarMenuItem>
            <ul className="sub-menu">
              {renderElement("/tools/redirect", "Lista przekierowań")}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, null)(Sidebar);
