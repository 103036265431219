import React from "react";
import {Dialog, DialogTitle, DialogActions, Button} from "@material-ui/core";

export const ConfirmDelete = (props) =>{

    return(
        <Dialog open={props.open}
               onClose={props.close}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{`Czy na pewno chcesz usunąć ${props.object} o id: ${props.id}?`}</DialogTitle>
            <DialogActions>
                <Button color="primary" onClick={props.close}>
                        Nie
                </Button>
                <Button color="primary" onClick={props.delete}>
                        Tak
                </Button>
            </DialogActions>
        </Dialog>
    );
};