import { removeEmptyKeys } from "./json";

export const backend = 'https://www.homekoncept.com.pl/api/';
// export const backend = 'http://localhost:8080/api/';

const addRaw = (url) => {
    if (url.includes('?')) {
        return url + "&raw=true";
    } else {
        return url + "?raw=true";
    }
};


const connection = (url, method, body, raw = false, string = false) => {

    return fetch(backend + addRaw(url), {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') || 'pl',
        },
        body: body ? (string ? body : JSON.stringify(removeEmptyKeys(body))) : null
    })
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            } else {
                response.method = method;
                if (method === 'DELETE' || raw) {
                    return response;
                } else {
                    return response.json();
                }
            }
        })
};
export default connection;