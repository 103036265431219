import {states} from "../../../tools/states";

export const fields = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Logo',
        id: 'logo',
        type: 'file',
    },
    {
        name: 'Zdjęcia',
        id: 'images',
        type: 'files',
    },
    {
        name: 'Kraj',
        id: 'country',
        type: 'multiSelect',
        required: 'To pole jest wymagane',
        values: [
            {
                name: 'abroad',
                id: 'abroad'
            },
            {
                name: 'Albania',
                id: 'Albania'
            },
            {
                name: 'Andora',
                id: 'Andora',
            },
            {
                name: 'Austria',
                id: 'Austria',
            },
            {
                name: 'Belgia',
                id: 'Belgia',
            },
            {
                name: 'Białoruś',
                id: 'Białoruś',
            },
            {
                name: 'Bośnia i Hercegowina',
                id: 'Bośnia i Hercegowina',
            },
            {
                name: 'Bułgaria',
                id: 'Bułgaria',
            },
            {
                name: 'Chorwacja',
                id: 'Chorwacja',
            },
            {
                name: 'Czarnogóra',
                id: 'Czarnogóra',
            },
            {
                name: 'Czechy',
                id: 'Czechy',
            },
            {
                name: 'Dania',
                id: 'Dania',
            },
            {
                name: 'Estonia',
                id: 'Estonia',
            },
            {
                name: 'Finlandia',
                id: 'Finlandia',
            },
            {
                name: 'Francja',
                id: 'Francja',
            },
            {
                name: 'Grecja',
                id: 'Grecja',
            },
            {
                name: 'Hiszpania',
                id: 'Hiszpania',
            },
            {
                name: 'Holandia',
                id: 'Holandia',
            },
            {
                name: 'Irlandia',
                id: 'Irlandia',
            },
            {
                name: 'Islandia',
                id: 'Islandia',
            },
            {
                name: 'Liechtenstein',
                id: 'Liechtenstein',
            },
            {
                name: 'Litwa',
                id: 'Litwa',
            },
            {
                name: 'Luksemburg',
                id: 'Luksemburg',
            },
            {
                name: 'Łotwa',
                id: 'Łotwa',
            },
            {
                name: 'Macedonia Północna',
                id: 'Macedonia Północna',
            },
            {
                name: 'Malta',
                id: 'Malta',
            },
            {
                name: 'Mołdawia',
                id: 'Mołdawia',
            },
            {
                name: 'Monako',
                id: 'Monako',
            },
            {
                name: 'Niemcy',
                id: 'Niemcy',
            },
            {
                name: 'Norwegia',
                id: 'Norwegia',
            },
            {
                name: 'Polska',
                id: 'Polska',
            },
            {
                name: 'Portugalia' ,
                id: 'Portugalia',
            },
            {
                name: 'Rosja',
                id: 'Rosja',
            },
            {
                name: 'Rumunia',
                id: 'Rumunia',
            },
            {
                name: 'San Marino',
                id: 'San Marino',
            },
            {
                name: 'Serbia',
                id: 'Serbia',
            },
            {
                name: 'Słowacja',
                id: 'Słowacja',
            },
            {
                name: 'Słowenia',
                id: 'Słowenia',
            },
            {
                name: 'Szwajcaria',
                id: 'Szwajcaria',
            },
            {
                name: 'Szwecja',
                id: 'Szwecja',
            },
            {
                name: 'Turcja',
                id: 'Turcja',
            },
            {
                name: 'Ukraina',
                id: 'Ukraina',
            },
            {
                name: 'Watykan',
                id: 'Watykan',
            },
            {
                name: 'Węgry',
                id: 'Węgry',
            },
            {
                name: 'Wielka Brytania',
                id: 'Wielka Brytania',
            },
            {
                name: 'Włochy',
                id: 'Włochy',
            }
        ],
    },
    {
        name: 'Województwo',
        id: 'state',
        type: 'multiSelect',
        required: 'To pole jest wymagane',
        values: states,
    },
    {
        name: 'Ogólny opis',
        id: 'description',
        type: 'textarea',
    },
    {
        name: 'Adres strony internetowej',
        id: 'website',
        type: 'text',
    },
    {
        name: 'Opis technologii',
        id: 'technology',
        type: 'textarea',
    },
    {
        name: 'Opis Firmy',
        id: 'companyDescription',
        type: 'textarea',
    },
    {
        name: 'Kategoria',
        id: 'category',
        type: 'multiSelect',
        required: 'To pole jest wymagane',
        values: [
            {
                id: 'technologia tradycyjna',
                name: 'technologia tradycyjna',
            },
            {
                id: 'szkielet drewniany',
                name: 'szkielet drewniany',
            },
            {
                id: 'technologia CLT',
                name: 'technologia CLT',
            },
            {
                id: 'system 3e',
                name: 'system 3e',
            },
            {
                id: 'cięzki prefabrykat drewniany',
                name: 'cięzki prefabrykat drewniany',
            },
            {
                id: 'IZODOM 2000',
                name: 'IZODOM 2000',
            },
        ]
    },
    {
        name: 'Email',
        id: 'email',
        type: 'text',
    },
];