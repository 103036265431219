import React, {useState, useEffect} from "react";
// import {closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
// import {SortableContext, sortableKeyboardCoordinates} from "@dnd-kit/sortable";
// import {Draggable, Overlay, Droppable} from "./draggable";
// import {restrictToParentElement} from "@dnd-kit/modifiers";
import SortableItem from "./sortableItem";
import {Item} from './Item'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';


export function GridDnD({removeFile, files, renderFile, moveFiles, name}) {
      
    
    const [items, setItems] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setItems(files);
    })

    const [activeId, setActiveId] = useState(null);
    
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    function handleDragEnd(event) {
        const {active, over} = event;
        
        const overId = items.map((e) => (e.id)).indexOf(over.id)
        const activeId = items.map((e) => (e.id)).indexOf(active.id);

        if (over && active.id !== over.id) {
          setItems((items) => {
            const oldIndex = activeId;
            const newIndex = overId;
    
            return arrayMove(items, oldIndex, newIndex);
          });
        }
        if (over) {
            
            if (active !== overId) {
                moveFiles({
                    destination: {
                        index: overId
                    },
                    source: {
                        index: activeId
                    }
                })
            }
        }
    
        setActiveId(null);
      }
    
      function handleDragStart(event) {
        setActiveId(event.active.id);
      }
    
      function handleDragCancel() {
        setActiveId(null);
      }

      const getActiveOverlay = () => {
        const activeItem = items.filter(item => item.id === activeId)
        return renderFile(activeItem[0])
    }

    return (
        
        <div
            style={{
                display: 'inline-grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
            }}
        >
            {/* unvisible */}
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
            >
                <SortableContext style={{border: 'none'}} items={items} strategy={rectSortingStrategy}>
                    {items.map((item) => (
                        <SortableItem key={item.id} item={item} renderFile={renderFile} removeFile={removeFile} />
                    ))}
                    
                </SortableContext>
                <DragOverlay>{activeId ? <Item label={activeId}>{getActiveOverlay()}</Item> : null}</DragOverlay>
            </DndContext>
        </div>

        // <DndContext
        //     onDragEnd={handleDragEnd}
        //     onDragStart={handleDragStart}
        //     collisionDetection={closestCenter}
        //     onDragCancel={handleDragCancel}
        //     sensors={sensors}
        // >
        //     <SortableContext id={name} items={files}>
        //         <ul className="thumbs">
        //             {files.map((item, i) => (
        //                 <Draggable key={i} id={`${i}:${name}`} activeIndex={getIndex(active)}>
        //                     {renderFile(item)}
        //                 </Draggable>
        //             ))}
        //         </ul>
        //     </SortableContext>

        //     <DragOverlay modifiers={[restrictToParentElement]}>
        //         {active &&
        //             <Overlay
        //                 render={renderFile}
        //                 item={files[getIndex(active)]}
        //                 activeIndex={active}
        //             />
        //         }
        //     </DragOverlay>
        // </DndContext>
    );
}

export const getIndex = (el) => {
    if(el != null) {
        return el.split(":")[0];
    }else {
        return null;
    }
}