import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteMedia} from "./media.reducer";
import Page from "../../../tools/Page";

class Medias extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'title', name: 'Tytuł'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie media'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'medium'}
                    deleteFunction={this.props.deleteMedia}
                    slug={'media'}
                    add={'Dodaj nowe medium'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({media}) => ({
    data: media.data,
    pagination: media.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteMedia
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Medias);