import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_REFUNDS: 'refund/FETCH_ALL_REFUNDS',
    FETCH_REFUND: 'refund/FETCH_REFUND',
    DELETE_REFUND: 'refund/DELETE_REFUND',
    CHANGE_PAGINATION: 'refund/CHANGE_PAGINATION',
    RESET: 'refund/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const RefundState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_REFUNDS):
        case REQUEST(ActionTypes.FETCH_REFUND):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.DELETE_REFUND):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_REFUNDS):
        case FAILURE(ActionTypes.FETCH_REFUND):
        case FAILURE(ActionTypes.DELETE_REFUND):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };
        case SUCCESS(ActionTypes.FETCH_ALL_REFUNDS):
        case SUCCESS(ActionTypes.FETCH_REFUND):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_REFUND):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default RefundState;

const apiUrl = 'refund/admin';

export const getAllRefunds = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_REFUNDS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().refund.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllRefunds());
    return result;
};


export const getRefund = (id) =>({
    type: ActionTypes.FETCH_REFUND,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const deleteRefund = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_REFUND,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllRefunds());
    return result;
};