import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteManufacturer} from "./manufacturer.reducer";
import Page from "../../../tools/Page";

class Manufacturers extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Nazwa'},
                {id: 'website', name: 'Strona internetowa'},
                {id: 'category', name: 'Kategoria'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszyscy producenci'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'producent'}
                    deleteFunction={this.props.deleteManufacturer}
                    slug={'manufacturer'}
                    add={'Dodaj nowego producenta'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({manufacturer}) => ({
    data: manufacturer.data,
    pagination: manufacturer.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteManufacturer
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Manufacturers);