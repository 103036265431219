import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createRedirect} from "./redirect.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class RedirectCreator extends Component{

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        console.log(data);
        this.props.createRedirect(data);
    }

    render() {

        return (
            <Page header={'Dodaj przekierowanie'}>
                <GenericForm
                    content={fields}
                    data = {{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({redirect}) => ({
    updateSuccess: redirect.updateSuccess,
    data: redirect.data
});

const mapDispatchToProps = {
    createRedirect
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectCreator);