import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../tools/privateRoute";
import Post from './post';
import Thread from './thread';
import Category from './category';

const Abc = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/post`} component={Post}/>
            <PrivateRoute path={`${match.url}/thread`} component={Thread}/>
            <PrivateRoute path={`${match.url}/category`} component={Category}/>
        </Switch>
    </>
);

export default Abc;