import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteNewsletter} from "./newsletter.reducer";
import Page from "../../../tools/Page";

class Newsletters extends Component {
    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'email', name: 'Adres email'},
                //{id: 'marketing', name: 'Zgoda marketingowa'},
                {id: 'date', name: 'Data'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie formularze z Newsletter'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'newsletter'}
                    deleteFunction={this.props.deleteNewsletter}
                    slug={'newsletter'}
                    skip={['date']}
                    downloadLink='form/newsletter/admin/raport.xlsx'
                    disableAdd
                    disableEdit
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({newsletter}) => ({
    data: newsletter.data,
    pagination: newsletter.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteNewsletter
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Newsletters);