import React, { Component } from "react";
import { connect } from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import { getUser, updateUser, getAdvisors } from "./user.reducer";
import { getSimpleRoles } from "../roles/role.reducer";
import { fields } from "./constants";
import { getDesignSimple } from "../../design/design/design.reducer";
import { USER_ROLES } from "../../../tools/user_access_constants";


class User extends Component {

    columns = fields;

    componentDidMount() {
        this.props.getAdvisors();
        this.props.getSimpleRoles();
        this.props.getUser(this.props.userId);
        this.props.getDesignSimple();

        // blocking access to authority field
        const userAuthority = this.getUserAuthority();
        if (userAuthority === USER_ROLES.ADMIN || userAuthority === USER_ROLES.COUNSELOR) {
            this.columns[6] = {
                name: 'Uprawnienia',
                id: 'authority',
                type: 'text',
                required: 'To pole jest wymagane',
                readOnly: true
            }
        }
    }
    getUserAuthority() {
        return localStorage.getItem('role');
    }
    save(data) {
        data.id = this.props.data.id;
        if (data.advisor) {
            data.advisor = { id: data.advisor };
        }
        if (data.role) {
            data.role = { id: data.role };
        }
        if (data.design) {
            data.design = this.props.designs.find(design => Number(design.id) === Number(data.design));
        }
        this.props.updateUser(data);
    }

    render() {
        this.columns[8].values = this.props.designs;
        this.columns[7].values = this.props.advisors;
        // this.columns[7].values = this.props.roles;
        return (
            <>
                {this.props.data && !Array.isArray(this.props.data) && this.props.advisors && this.props.advisors.length > 0 &&
                    this.props.roles && !this.props.roles.content &&
                    <GenericForm
                        content={this.columns}
                        data={{
                            ...this.props.data,
                            advisor: this.props.data.advisor ? this.props.data.advisor.id.toString() : '',
                            // role: this.props.data.role ? this.props.data.role.id.toString() : '',
                            design: this.props.data.design ? this.props.data.design.id.toString() : ''
                        }}
                        save={this.save.bind(this)}
                        cancel={this.props.cancel}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = ({ user, role, design }) => ({
    data: user.data,
    advisors: user.advisors,
    roles: role.data,
    designs: design.data
});

const mapDispatchToProps = {
    getUser,
    updateUser,
    getAdvisors,
    getSimpleRoles,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(User);