import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getRealization, updateRealization} from "./realization.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {fields} from "./constants";
import Page from "../../../tools/Page";


class Realization extends Component{

    columns = fields;

   componentDidMount() {
       this.props.getRealization(this.props.match.params.id);
       this.props.getDesignSimple();
   }

   save(data){
       data.id = this.props.data.id;
       data.design = {id: data.design};
       this.props.updateRealization(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {
       this.columns[3].values = this.props.designs;
      return (
          <>
              <Page header={`Realizacja ${this.props.match.params.id}`}>
              { this.props.data && !this.props.data.content &&
                <GenericForm
                    content={this.columns}
                    data={{
                        ...this.props.data,
                        design: this.props.data.design ? this.props.data.design.id.toString() : '',
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({realization, design}) => ({
    data: realization.data,
    designs: design.data,
});

const mapDispatchToProps = {
    getRealization,
    updateRealization,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(Realization);