import React from 'react';
import {Switch} from 'react-router-dom';
import Purchase from "./purchase";
import Refund from './refund';
import PrivateRoute from "../../tools/privateRoute";

const Content = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/refund`} component={Refund}/>
            <PrivateRoute path={`${match.url}`} component={Purchase}/>
        </Switch>
    </>
);

export default Content;