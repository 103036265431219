import {Grid} from "@material-ui/core";
import React from "react";
import Time from "../../../../modules/form/Time";

export const DateFilter = ({onChange, time}) => {

    const handleFrom = (e) =>{
        onChange({from: e.slice(0, -6)})
    }

    const handleTo = (e) =>{
        onChange({to: e.slice(0, -6)})
    }

    return (
        <Grid container spacing={2} alignItems='center'>
            <Grid item style={{minWidth: '250px'}}>
                <label className='form-label' style={{fontWeight: "bold"}}>Od:</label>
                <Time
                    onChange={handleFrom}
                    value={''}
                    noTime
                    clear={ () => onChange({from: ''})}
                    noInput
                />
            </Grid>

            <Grid item>
                <label className='form-label' style={{fontWeight: "bold"}}>Do:</label>
                <Time
                    onChange={handleTo}
                    value={''}
                    noTime
                    clear={ () => onChange({to: ''})}
                    noInput
                />
            </Grid>
        </Grid>
    );
}

export default DateFilter;