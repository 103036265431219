import React, { useState } from "react";
import { connect } from "react-redux";
import { clearToken } from "../account/account.reducer";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";

export const TopBar = (props) => {
  const [lang, setLang] = useState(localStorage.getItem("lang") || "pl");

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    setLang(language);
    localStorage.setItem("lang", language);
    window.location.reload();
  };

  return (
    <div className="page-topbar ">
      <div className="logo-area"></div>
      <div className="quick-area">
        <div className="pull-left">
          <ul className="info-menu left-links list-inline list-unstyled">
            <li className="sidebar-toggle-wrap">
              <a
                href="/"
                data-toggle="sidebar"
                className="sidebar_toggle"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <i className="fa fa-bars" />
              </a>
            </li>
          </ul>
        </div>
        <div className="pull-right">
          <Grid container alignItems="center" spacing="3">
            <Grid item>
              <select value={lang} onChange={handleLanguageChange}>
                <option value="pl" selected={lang === "pl"}>
                  pl
                </option>
                <option value="en" selected={lang === "en"}>
                  en
                </option>
              </select>
            </Grid>
            <Grid item>
              <ul className="info-menu right-links list-inline list-unstyled">
                <li className="profile">
                  <Link
                    to="/"
                    data-toggle="dropdown"
                    className="toggle"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Grid container alignItems="center">
                      <Grid item>
                        {props.user && props.user.avatar ? (
                          <img
                            alt=""
                            src={props.user.avatar.file}
                            className="img-responsive img-circle"
                          />
                        ) : (
                          <img
                            alt=""
                            src="/admin/images/profile/profile.jpg"
                            className="img-responsive img-circle"
                          />
                        )}
                      </Grid>
                      <Grid item>
                        <span>
                          {props.user &&
                            `${props.user.firstName} ${props.user.lastName}`}
                          <i className="fa fa-angle-down" />
                        </span>
                      </Grid>
                    </Grid>
                  </Link>
                  <ul className="dropdown-menu profile animated fadeIn">
                    <li>
                      <Link to={"/profile"}>
                        <i className="fa fa-user" />
                        Profil
                      </Link>
                    </li>
                    <li className="last">
                      <Link onClick={() => props.clearToken()} to={"/login"}>
                        <i className="fa fa-lock" />
                        Wyloguj
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {
  clearToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
