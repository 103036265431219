export const fields = [
    {
        name: 'Adres początkowy',
        id: 'origin',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Adres końcowy',
        id: 'destination',
        type: 'text',
        required: 'To pole jest wymagane',
    },
];