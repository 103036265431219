import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../tools/privateRoute";
import Redirect from './redirect';

const Tools = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/redirect`} component={Redirect}/>
        </Switch>
    </>
);

export default Tools;