import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_MANUFACTURERS: 'manufacturer/FETCH_ALL_MANUFACTURERS',
    FETCH_MANUFACTURER: 'manufacturer/FETCH_MANUFACTURER',
    UPDATE_MANUFACTURER: 'manufacturer/UPDATE_MANUFACTURER',
    DELETE_MANUFACTURER: 'manufacturer/DELETE_MANUFACTURER',
    CHANGE_PAGINATION: 'manufacturer/CHANGE_PAGINATION',
    CREATE_MANUFACTURER: 'manufacturer/CREATE_MANUFACTURER',
    RESET: 'manufacturer/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const ManufacturerState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_MANUFACTURERS):
        case REQUEST(ActionTypes.FETCH_MANUFACTURER):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_MANUFACTURER):
        case REQUEST(ActionTypes.DELETE_MANUFACTURER):
        case REQUEST(ActionTypes.UPDATE_MANUFACTURER):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_MANUFACTURERS):
        case FAILURE(ActionTypes.FETCH_MANUFACTURER):
        case FAILURE(ActionTypes.CREATE_MANUFACTURER):
        case FAILURE(ActionTypes.DELETE_MANUFACTURER):
        case FAILURE(ActionTypes.UPDATE_MANUFACTURER):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_MANUFACTURER):
        case SUCCESS(ActionTypes.UPDATE_MANUFACTURER):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_MANUFACTURERS):
        case SUCCESS(ActionTypes.FETCH_MANUFACTURER):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_MANUFACTURER):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default ManufacturerState;

const apiUrl = 'abc/admin/manufacturer';

export const getAllManufacturers = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_MANUFACTURERS,
    payload: connection(applyParamsToUrl(`abc/manufacturer`,getState().manufacturer.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllManufacturers());
    return result;
}


export const getManufacturer = (id) =>({
    type: ActionTypes.FETCH_MANUFACTURER,
    payload: connection(`abc/manufacturer/${id}`, 'GET', null)
});

export const createManufacturer = (manufacturer) => ({
    type: ActionTypes.CREATE_MANUFACTURER,
    payload: connection(`${apiUrl}`, 'POST', manufacturer)
});

export const updateManufacturer = (manufacturer) => ({
    type: ActionTypes.UPDATE_MANUFACTURER,
    payload: connection(`${apiUrl}`, 'PUT', manufacturer)
});

export const deleteManufacturer = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_MANUFACTURER,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllManufacturers());
    return result;
};