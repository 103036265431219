import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import AddonOrderView from './addonOrder';


const AddonOrder = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}`} component={AddonOrderView}/>
        </Switch>
    </>
);

export default AddonOrder;