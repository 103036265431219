import React, {Component} from "react";
import GenericForm from "../../../modules/form/GenericForm";
import {connect} from "react-redux";
import Page from "../../../tools/Page";
import {Redirect} from "react-router-dom";
import {importFields} from "./constants";
import {importData, importProjections} from "./design.reducer";

class ImportData extends Component{

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        if(data.type === 'DESIGN') {
            this.props.importData(data.file);
        }else{
            this.props.importProjections(data.file);
        }
    }

    render() {
        return (
            <Page info='' header={'Importuj dane'}>
                <GenericForm
                    content={importFields}
                    data = {{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                <Redirect to={`/design`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({design}) => ({
    updateSuccess: design.updateSuccess,
});

const mapDispatchToProps = {
    importData,
    importProjections
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportData);