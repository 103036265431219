import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import AddonCreator from "./addonCreator";
import Addons from "./addons";
import AddonView from "./addon";


const Addon = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={AddonCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={AddonView}/>
            <PrivateRoute exact path={`${match.url}`} component={Addons}/>
        </Switch>
    </>
);

export default Addon;