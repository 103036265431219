export const fields = [
    {
        name: 'Nazwa',
        id: 'title',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Projekty',
        id: 'designs',
        type: 'multiSelect',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Krótki opis',
        id: 'shortText',
        type: 'text',
    },
    {
        name: 'Szczegółowy opis',
        id: 'description',
        type: 'textarea',
    },
    {
        name: 'Miniaturka',
        id: 'image',
        type: 'file',
    },
    {
        name: 'Data zakończenia',
        id: 'deadline',
        type: 'time',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Poprzednia cena',
        id: 'previousPrice',
        type: 'number',
    },
    {
        name: 'Cena',
        id: 'price',
        type: 'number',
    },
    {
        name: 'Województwa',
        id: 'states',
        type: 'multiSelect',
        required: 'To pole jest wymagane'
    }
];