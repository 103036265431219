import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteRedirect} from "./redirect.reducer";
import Page from "../../../tools/Page";

class Redirects extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'origin', name: 'Adres początkowy'},
                {id: 'destination', name: 'Adres końcowy'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie przekierowania'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'przekierowanie'}
                    deleteFunction={this.props.deleteRedirect}
                    slug={'redirect'}
                    add={'Dodaj nowe przekierowanie'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({redirect}) => ({
    data: redirect.data,
    pagination: redirect.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteRedirect
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Redirects);