import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getDetails, updateDetails} from "./details.reducer";
import {descriptionFields} from "./constants";


class Description extends Component{

   componentDidMount() {
       this.props.getDetails(this.props.designId);
   }

   save(data){
       data.id = this.props.description.id;
       this.props.updateDetails(data);
   }


    render() {
      return (
          <>
              { this.props.description && !this.props.loading &&
                <GenericForm
                    content={descriptionFields}
                    data={this.props.description}
                    save={this.save.bind(this)}
                    cancel={this.props.cancel}
                />
              }
          </>
      );
   }

}

const mapStateToProps = ({details}) => ({
   description: details.data,
    loading: details.loading,
});

const mapDispatchToProps = {
    getDetails,
    updateDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Description);