import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import MediaCreator from "./mediaCreator";
import Medias from "./medias";
import MediaView from "./media";

const Media = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={MediaCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={MediaView}/>
            <PrivateRoute exact path={`${match.url}`} component={Medias}/>
        </Switch>
    </>
);

export default Media;