import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import { getDesignSelection } from "./designSelection.reducer";
import {fields} from "./constants";
import translateProp from "../../../tools/translateProp";
import Page from "../../../tools/Page";
import connection from "../../../tools/connection";



class DesignSelection extends Component {
    constructor(props) {
        super(props);
        this.state = { url: null };
    }
    componentDidMount() {
        this.props.getDesignSelection(this.props.match.params.id);
    }
    save() {

    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.props.data && this.props.data.file && this.props.data.file.file && this.getDesignFile();
        }
    }
    getDesignFile() {
        connection(this.props.data.file.file.split('api')[1], 'GET', null, true, false)
            .then(res => res.blob())
            .then(res => { this.setState({ url: URL.createObjectURL(res) }) });
    }
    cancel() {
        this.props.history.goBack();
    }
    render() {
        
        return (
            <Page header={`Formularz doboru projektu ${this.props.match.params.id}`}>
                { this.props.data &&
                    <>
                        <GenericForm
                            content={fields}
                            data={this.props.data}
                            save={this.save.bind(this)}
                            cancel={this.cancel.bind(this)}
                            readOnly
                        />
                        {this.props.data && this.props.data.file && this.props.data.file.file && <a className="btn btn-primary" href={this.state.url} target="_blank" rel="noreferrer">Pobierz plik</a> }
                    </>    
                }
            </Page>
        );
    }
}



const mapStateToProps = ({designSelection}) => {
    const translatedState = designSelection;
    if (translatedState && translatedState.data) {
        translatedState.data.roofType         = translateProp(translatedState.data.roofType);
        translatedState.data.storey           = translateProp(translatedState.data.storey);
        translatedState.data.numberOfGarages  = translateProp(translatedState.data.numberOfGarages);
        translatedState.data.numberOfBedrooms = translateProp(translatedState.data.numberOfBedrooms);
    }
    
    return { data: translatedState.data }
}
const mapDispatchToProps = {
    getDesignSelection,
};



export default connect(mapStateToProps, mapDispatchToProps)(DesignSelection);