export const fields = [
    {
        name: 'Lokalizacja',
        id: 'location',
        type: 'text',
    },
    {
        name: 'Meta tytuł',
        id: 'pageTitle',
        type: 'text',
    },
    {
        name: 'Meta opis',
        id: 'pageDescription',
        type: 'text',
    },
    {
        name: 'Tytuł',
        id: 'title',
        type: 'text',
    },
    {
        name: 'Data początkowa',
        id: 'startDate',
        type: 'time',
        noTime: true,
        required: 'To pole jest wymagane',
    },
    {
        name: 'Miniaturka',
        id: 'mainImage',
        type: 'file',
    },
    {
        name: 'Projekt',
        id: 'design',
        type: 'select',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Wpisy',
        id: 'entries',
        type: 'list',
        noAdd: true,
        values: [
            {
                name: 'Tytuł',
                id: 'title',
                type: 'text',
                required: 'To pole jest wymagane',
            },
            {
                name: 'Data',
                id: 'date',
                type: 'time',
                noTime: true,
                required: 'To pole jest wymagane',
            },
            {
                name: 'Etap',
                id: 'stage',
                type: 'select',
                required: 'To pole jest wymagane',
                values: [
                    {
                        id: 'Projekt pozwolenia',
                        name: 'Projekt pozwolenia'
                    },
                    {
                        id: 'Fundamenty',
                        name: 'Fundamenty'
                    },
                    {
                        id: 'Ściany i stropy',
                        name: 'Ściany i stropy'
                    },
                    {
                        id: 'Wieźba i dach',
                        name: 'Wieźba i dach'
                    },
                    {
                        id: 'Okna i drzwi',
                        name: 'Okna i drzwi'
                    },
                    {
                        id: 'Instalacje',
                        name: 'Instalacje'
                    },
                    {
                        id: 'Elewacje, Wnętrza',
                        name: 'Elewacje, Wnętrza'
                    },
                    {
                        id: 'Ogród i ogrodzenie',
                        name: 'Ogród i ogrodzenie'
                    },
                    {
                        id: 'Już mieszkamy!',
                        name: 'Już mieszkamy!'
                    }
                ]
            },
            {
                name: 'Zawartość',
                id: 'content',
                type: 'textarea',
                required: 'To pole jest wymagane',
            },
            {
                name: 'Zdjęcia',
                id: 'images',
                type: 'files',
            },
        ]
    },
];