import React from 'react';
import {Switch} from 'react-router-dom';
import Pages from './pages';
import PageView from "./page";
import PrivateRoute from "../../../tools/privateRoute";

const Page = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/:name`} component={PageView}/>
            <PrivateRoute exact path={`${match.url}`} component={Pages}/>
        </Switch>
    </>
);

export default Page;