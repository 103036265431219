import Grid from "@material-ui/core/Grid";
import React from "react";
import {AddonFields, DesignFields, PackageFields, ProductsFields, ProductType} from "./constants";
import {useFieldArray} from "react-hook-form";
import Box from "@material-ui/core/Box";
import {renderField} from "../../../../modules/form/input";

export const Products = ({data, methods, designs, addons, packages}) => {

    const {fields, append, remove} = useFieldArray({
            control: methods.control,
            name: 'products',
        }
    );

    const getSum = (array) => {
        let sum = 0;
        for (let field of array) {
            sum += field.price * field.quantity;
        }
        return sum;
    }

    const name = 'products';

    const content = ProductsFields;

    const products = methods.watch(`${name}`)

    const getListElement = (product, index) => {
        return <div className='thumb-box' style={{
            backgroundColor: '#d0cece'
        }}>
            <Grid key={product.id} container justify='space-between' alignItems='center' spacing={10}>
                <Grid item xs={10}>
                    <input hidden ref={methods.register()} name={`${name}[${index}].id`}
                           defaultValue={product.id || null}/>
                    <Grid container spacing={2}>

                        <>
                            <Grid item xs={10} sm={12}>
                                <Box ml={4} style={{width: '100%'}}>
                                    {renderField(ProductType, product, 0, methods, false,
                                        `${name}[${index}].prodType`, `${name}[${index}].prodType`)}
                                </Box>
                            </Grid>
                        </>

                        {methods.watch(`${name}[${index}].prodType`) === 'design' &&
                        <>
                            {DesignFields(designs).map((item, it) => (
                                <Grid key={it} item xs={10} sm={12}>
                                    <Box ml={4} style={{width: '100%'}}>
                                        {renderField(item, product, it
                                            , methods, false, `${name}[${index}].${item.id}`,
                                            `${name}[${index}].${item.id}`)}
                                    </Box>
                                </Grid>
                            ))}
                        </>
                        }

                        {methods.watch(`${name}[${index}].prodType`) === 'addon' &&
                        <>
                            {AddonFields(designs, addons).map((item, it) => (
                                <Grid key={it} item xs={10} sm={6}>
                                    <Box ml={4} style={{width: '100%'}}>
                                        {renderField(item, product, it
                                            , methods, false, `${name}[${index}].${item.id}`,
                                            `${name}[${index}].${item.id}`)}
                                    </Box>
                                </Grid>
                            ))}
                        </>
                        }

                        {methods.watch(`${name}[${index}].prodType`) === 'package' &&
                        <>
                            {PackageFields(designs, packages).map((item, it) => (
                                <Grid key={it} item xs={10} sm={6}>
                                    <Box ml={4} style={{width: '100%'}}>
                                        {renderField(item, product, it
                                            , methods, false, `${name}[${index}].${item.id}`,
                                            `${name}[${index}].${item.id}`)}
                                    </Box>
                                </Grid>
                            ))}
                        </>
                        }

                        {methods.watch(`${name}[${index}].prodType`) &&
                        <>
                            {content.values && content.values.map((item, it) => (
                                <Grid key={it} item xs={10} sm={4}>
                                    <Box ml={4} style={{width: '100%'}}>
                                        {renderField(item, product, it
                                            , methods, false, `${name}[${index}].${item.id}`,
                                            `${name}[${index}].${item.id}`)}
                                    </Box>
                                </Grid>
                            ))}
                        </>
                        }
                    </Grid>
                </Grid>
                <span className="clear" onClick={() => remove(index)}>
                    <i className='fa fa-times'/>
                </span>
            </Grid>
        </div>;
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <label className='form-label' style={{fontWeight: "bold", fontSize: '24px'}}>Dane dotyczące
                    zamówienia</label>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='center' direction='column' spacing={2}>
                    <Grid item>
                        {fields.map((outerItem, index) => getListElement(outerItem, index))}
                    </Grid>
                    <Grid item>
                        <button type='button' className='btn-primary btn' onClick={() => {
                            append({id: null});
                        }}>
                            Dodaj
                        </button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <label className='form-label'
                       style={{fontWeight: "bold", fontSize: '18px'}}>{`Suma zamówienia: ${getSum(products)} zł`}</label>
            </Grid>
        </Grid>
    );

}

export default Products;

