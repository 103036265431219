import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getDownloadable, updateDownloadable} from "./details.reducer";
import {downloadableFields} from "./constants";


class Downloadable extends Component{

   componentDidMount() {
       this.props.getDownloadable(this.props.designId);
   }

   save(data){
       data.id = this.props.downloadable.id;
       this.props.updateDownloadable(data);
   }


    render() {
      return (
          <>
              { this.props.downloadable && !this.props.loading &&
                <GenericForm
                    content={downloadableFields}
                    data={this.props.downloadable}
                    save={this.save.bind(this)}
                    cancel={this.props.cancel}
                />
              }
          </>
      );
   }

}

const mapStateToProps = ({details}) => ({
   downloadable: details.data,
    loading: details.loading,
});

const mapDispatchToProps = {
    getDownloadable,
    updateDownloadable
};

export default connect(mapStateToProps, mapDispatchToProps)(Downloadable);