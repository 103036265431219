import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Users from "./users";
import MainUser from '../mainUser';
import DocumentCreator from "../documents/DocumentCreator";
import DraftCreator from "../documents/DraftCreator";
import UserCreator from "./userCreator";
import CategoryCreator from "../documents/CategoryCreator";

const User = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/draft/:id/type/:type`} component={DraftCreator}/>
            <PrivateRoute exact path={`${match.url}/document/category/:id`} component={CategoryCreator}/>
            <PrivateRoute exact path={`${match.url}/document/:id`} component={DocumentCreator}/>
            <PrivateRoute exact path={`${match.url}/add`} component={UserCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={MainUser}/>
            <PrivateRoute exact path={`${match.url}`} component={Users}/>
        </Switch>
    </>
);

export default User;