import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../tools/privateRoute";
import Webapi from './webapi';
import Servicepoint from './servicepoint';

const Content = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/web`} component={Webapi}/>
            <PrivateRoute path={`${match.url}/servicepoint`} component={Servicepoint}/>
        </Switch>
    </>
);

export default Content;