import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_STATIC_IMAGES: 'image/FETCH_ALL_STATIC_IMAGES',
    FETCH_STATIC_IMAGE: 'image/FETCH_STATIC_IMAGE',
    UPDATE_STATIC_IMAGE: 'image/UPDATE_STATIC_IMAGE',
    DELETE_STATIC_IMAGE: 'image/DELETE_STATIC_IMAGE',
    CREATE_STATIC_IMAGE: 'image/CREATE_STATIC_IMAGE',
    CHANGE_PAGINATION: 'image/CHANGE_PAGINATION',
    RESET: 'image/RESET',
};

const initState = {
    loading: false,
    pagination: defaultSearchableParams(10),
    data: [],
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const StaticImageState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_STATIC_IMAGES):
        case REQUEST(ActionTypes.FETCH_STATIC_IMAGE):
            return{
                ...state,
                data: null,
                errorMessage: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_STATIC_IMAGE):
        case REQUEST(ActionTypes.DELETE_STATIC_IMAGE):
        case REQUEST(ActionTypes.UPDATE_STATIC_IMAGE):
            return{
                ...state,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_STATIC_IMAGES):
        case FAILURE(ActionTypes.FETCH_STATIC_IMAGE):
        case FAILURE(ActionTypes.CREATE_STATIC_IMAGE):
        case FAILURE(ActionTypes.DELETE_STATIC_IMAGE):
        case FAILURE(ActionTypes.UPDATE_STATIC_IMAGE):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }

        case SUCCESS(ActionTypes.CREATE_STATIC_IMAGE):
        case SUCCESS(ActionTypes.UPDATE_STATIC_IMAGE):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_STATIC_IMAGES):
        case SUCCESS(ActionTypes.FETCH_STATIC_IMAGE):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_STATIC_IMAGE):
            return {
                ...state,
                data: {},
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default StaticImageState;

const apiUrl = 'cms/admin/image';

export const getAllStaticImages = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_STATIC_IMAGES,
    payload: connection(applyParamsToUrl('cms/image', getState().image.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllStaticImages());
    return result;
}

export const getStaticImage = (id) =>({
    type: ActionTypes.FETCH_STATIC_IMAGE,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createStaticImage = (image) => ({
    type: ActionTypes.CREATE_STATIC_IMAGE,
    payload: connection(`${apiUrl}`, 'POST', image)
});

export const updateStaticImage = (image) => ({
    type: ActionTypes.UPDATE_STATIC_IMAGE,
    payload: connection(`${apiUrl}`, 'PUT', image)
});

export const deleteStaticImage = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_STATIC_IMAGE,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllStaticImages());
    return result;
}