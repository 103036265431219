import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Posts from "./posts";
import PostView from "./post";

const Post = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/:id`} component={PostView}/>
            <PrivateRoute exact path={`${match.url}`} component={Posts}/>
        </Switch>
    </>
);

export default Post;