import React from "react";
import {useForm, FormProvider} from "react-hook-form";
import {renderField} from "./input";


//content list of json like this:
// {
//    name: Name to display
//    id: object name
//    type: type of data (text, checkbox, date, number)
// }

export const GenericForm = (props) => {

    const methods = useForm({defaultValues: props.noDefault ? {} : props.data});

    const deletePrice = (id) => {
        props.deletePrice(id);
        // window.location.reload();
    }

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(props.save)}>
                    <div className="col-xs-12 col-sm-9 col-md-8" style={{width: '100%'}}>
                        {props.content && props.content.length > 0 && props.content.map(
                            (item, index) => renderField(item, props.data, index, methods, props.readOnly))
                        }
                    </div>
                    {props.priceData &&
                        <div style={{display: 'block', textAlign: 'left', marginBottom: '30px'}}>
                            <p style={{fontWeight: 'bold'}}>Historia cen minimalnych:</p>
                            {props.priceData && props.priceData.map((data) =>
                                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                    <div>{data.price}</div>
                                    <div style={{color: 'red', fontWeight: 'bold', cursor: 'pointer'}} onClick={() => deletePrice(data.id)}>Usuń</div>
                                </div>
                            )}
                        </div>
                    }
                    {!props.viewOnly &&
                        <div className="col-xs-12 col-sm-9 col-md-8 padding-bottom-30" style={{width: '100%'}}>
                            <div className="text-left">
                                <button type='button' className='btn' onClick={props.cancel}>Wróć</button>
                                {!props.readOnly &&
                                    <input className='btn btn-primary' type='submit' value='Zapisz'/>
                                }
                            </div>
                        </div>
                    }
                </form>
            </FormProvider>
        </div>
    );
};

export default GenericForm;
