import React, {Component} from "react";

class Test extends Component {

    listenMessage = (msg) =>{

        if(msg.data != null) {
            let point;
            if ((msg.data).constructor === ({}).constructor) {
                point = msg.data;
            } else {
                point = JSON.parse(msg.data);
            }

            console.log(point.sap);
        }
    }
    componentDidMount() {
        if (window.addEventListener) {
            window.addEventListener("message", this.listenMessage, false);
        } else {
            window.attachEvent("onmessage",  this.listenMessage);
        }
    }

    render() {
        return (
            <iframe src='https://parcelshop.dhl.pl/mapa' title="DHL" style={{width: '100%', height: '100%'}}/>
        );
    }
}

export default Test;