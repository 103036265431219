import {CellPlugin} from '@react-page/editor';
import '@react-page/plugins-image/lib/index.css';
import Landscape from '@material-ui/icons/Landscape';
import CustomImageControls from "./ImageControls";
import {ImageUploadType} from "@react-page/plugins-image";

export type Data = {
    src: string;
    alt?: string;
    link?: string;
    tab: boolean;
};

const icon = {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
};

// const fakeImageUploadService: (url: string) => ImageUploadType = (defaultUrl) => (file, reportProgress) => {
//     return new Promise((resolve, reject) => {
//         let counter = 0;
//         const interval = setInterval(() => {
//             counter++;
//             reportProgress(counter * 10);
//             if (counter > 9) {
//                 clearInterval(interval);
//                 alert(
//                     'This is a fake image upload service, please provide actual implementation via plugin properties'
//                 );
//                 resolve({ url: defaultUrl });
//             }
//         }, 500);
//     });
// };

// const renderImage = (data: Data): ReactNode => {
//     return(
//     <>
//     {data.src ?
//         <img src={data.src} alt={data.alt}/>
//         :
//         <div style={icon}>
//             <Landscape/>
//         </div>
//     }
//     </>
//     )
// }

const customImagePlugin = (uploader: ImageUploadType): CellPlugin<Data> =>{

    return {
        Renderer: ({data, children}) => (
            <div className='react-page-image-content'>
                {data.link ?
                    <a href={data.link} target={`${data.tab ? '_blank' : ''}`}>
                        {data.src ?
                            <img src={data.src} alt={data.alt}/>
                            :
                            <div style={icon}>
                                <Landscape/>
                            </div>
                        }
                    </a>
                :
                    <>
                    {data.src ?
                            <img src={data.src} alt={data.alt}/>
                            :
                            <div style={icon}>
                                <Landscape/>
                            </div>
                    }
                    </>
                }
            </div>
        ),
        id: 'image',
        title: 'Image',
        description: 'Image',
        version: 1,
        controls: {
            type: 'custom',
            Component: (props) => (
                <CustomImageControls
                    {...props}
                    imageUpload={uploader}
                />
            ),
        },
    }
};

export default customImagePlugin;