import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_FORUM_CATEGORIES: 'articleForumCategory/FETCH_ALL_FORUM_CATEGORIES',
    FETCH_SIMPLIFIED_FORUM_CATEGORIES: 'articleForumCategory/FETCH_SIMPLIFIED_FORUM_CATEGORIES',
    FETCH_FORUM_CATEGORY: 'articleForumCategory/FETCH_FORUM_CATEGORY',
    UPDATE_FORUM_CATEGORY: 'articleForumCategory/UPDATE_FORUM_CATEGORY',
    DELETE_FORUM_CATEGORY: 'articleForumCategory/DELETE_FORUM_CATEGORY',
    CHANGE_PAGINATION: 'articleForumCategory/CHANGE_PAGINATION',
    CREATE_FORUM_CATEGORY: 'articleForumCategory/CREATE_FORUM_CATEGORY',
    RESET: 'articleForumCategory/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const ForumCategoryState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_FORUM_CATEGORIES):
        case REQUEST(ActionTypes.FETCH_SIMPLIFIED_FORUM_CATEGORIES):
        case REQUEST(ActionTypes.FETCH_FORUM_CATEGORY):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_FORUM_CATEGORY):
        case REQUEST(ActionTypes.DELETE_FORUM_CATEGORY):
        case REQUEST(ActionTypes.UPDATE_FORUM_CATEGORY):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_FORUM_CATEGORIES):
        case FAILURE(ActionTypes.FETCH_FORUM_CATEGORY):
        case FAILURE(ActionTypes.CREATE_FORUM_CATEGORY):
        case FAILURE(ActionTypes.FETCH_SIMPLIFIED_FORUM_CATEGORIES):
        case FAILURE(ActionTypes.DELETE_FORUM_CATEGORY):
        case FAILURE(ActionTypes.UPDATE_FORUM_CATEGORY):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.CREATE_FORUM_CATEGORY):
        case SUCCESS(ActionTypes.UPDATE_FORUM_CATEGORY):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_FORUM_CATEGORIES):
        case SUCCESS(ActionTypes.FETCH_FORUM_CATEGORY):
        case SUCCESS(ActionTypes.FETCH_SIMPLIFIED_FORUM_CATEGORIES):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_FORUM_CATEGORY):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default ForumCategoryState;

const apiUrl = 'forum/category';

export const getAllForumCategories = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_FORUM_CATEGORIES,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().forumCategory.pagination), 'GET', null),
});


export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllForumCategories());
    return result;
}

export const getSimpleForumCategories= () =>({
    type: ActionTypes.FETCH_SIMPLIFIED_FORUM_CATEGORIES,
    payload: connection(`${apiUrl}/simple/all`, 'GET', null),
});

export const getForumCategory = (id) =>({
    type: ActionTypes.FETCH_FORUM_CATEGORY,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createForumCategory = (forumForumCategory) => ({
    type: ActionTypes.CREATE_FORUM_CATEGORY,
    payload: connection(`forum/admin/category`, 'POST', forumForumCategory)
});

export const updateForumCategory = (forumForumCategory) => ({
    type: ActionTypes.UPDATE_FORUM_CATEGORY,
    payload: connection(`forum/admin/category`, 'PUT', forumForumCategory)
});

export const deleteForumCategory = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_FORUM_CATEGORY,
        payload: connection(`forum/admin/category/${id}`, 'DELETE', null)
    });
    dispatch(getAllForumCategories());
    return result;
};