import React from 'react';
import TopBar from "./TopBar";
import Sidebar from "../sidebar/sidebar";

function Menu() {
    return (
        <div>
            <TopBar/>
            <Sidebar/>
        </div>
    );
}

export default Menu;