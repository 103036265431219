import React from 'react';
import {Switch} from 'react-router-dom';
import Categories from './categories';
import CategoryCreator from './categoryCreator';
import CategoryView from './category';
import PrivateRoute from "../../../tools/privateRoute";

const Category = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={CategoryCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={CategoryView}/>
            <PrivateRoute exact path={`${match.url}`} component={Categories}/>
        </Switch>
    </>
);

export default Category;