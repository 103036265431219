export const fields = [
    {
        name: 'Imię',
        id: 'name',
        type: 'text',
    },
    {
        name: 'Nazwisko',
        id: 'lastName',
        type: 'text',
    },
    {
        name: 'Telefon',
        id: 'phone',
        type: 'text',
    },
    {
        name: 'Email',
        id: 'email',
        type: 'text',
    },
    {
        name: 'Projekt',
        id: 'design.name',
        type: 'text',
    },
    {
        name: 'Wiadomość',
        id: 'message',
        type: 'text',
    },
    {
        name: 'Data',
        id: 'date',
        type: 'time',
    },
];