export const fields = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Logo',
        id: 'logo',
        type: 'file',
    },
    {
        name: 'Zdjęcia',
        id: 'images',
        type: 'files',
    },
    {
        name: 'Opis',
        id: 'description',
        type: 'textarea',
    },
    {
        name: 'Opis produkty',
        id: 'product',
        type: 'textarea',
    },
    {
        name: 'Opis firmy',
        id: 'companyDescription',
        type: 'textarea',
    },
    {
        name: 'Adres strony internetowej',
        id: 'website',
        type: 'text',
    },
    {
        name: 'Kategoria',
        id: 'category',
        type: 'multiSelect',
        required: 'To pole jest wymagane',
        values: [
            {
                id: 'Wokół domu',
                name: 'Wokół domu',
            },
            {
                id: 'Dach',
                name: 'Dach',
            },
            {
                id: 'Balkony i Tarasy',
                name: 'Balkony i Tarasy',
            },
            {
                id: 'Instalacje',
                name: 'Instalacje',
            },
            {
                id: 'Elewacja',
                name: 'Elewacja',
            },
            {
                id: 'Pergola',
                name: 'Pergola',
            },
            {
                id: 'Elementy Konstrukcyjne',
                name: 'Elementy Konstrukcyjne',
            },
            {
                id: 'Wnętrza',
                name: 'Wnętrza',
            },
            {
                id: 'Okna, Drzwi i Bramy',
                name: 'Okna, Drzwi i Bramy',
            }
        ]
    },
    {
        name: 'Email',
        id: 'email',
        type: 'text',
    },
];