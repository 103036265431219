import React, {Component} from 'react';
import {connect} from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteArticle} from "./article.reducer";
import Page from "../../../tools/Page";
import {Grid} from "@material-ui/core";
import {DateSpanFilter} from "../../purchase/purchase/filters/DateSpanFilter";

class Articles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keys: [
                {id: 'title', name: 'Tytuł'},
                {id: 'postDate', name: 'Data utworzenia'},
                {id: 'category', name: 'Kategoria', value: 'name', slug: 'article/category'}
            ],
            postFrom: '',
            postTo: '',
        }
    }

    buildSearch = (search) => {
        const {postFrom, postTo} = this.state;
        const params = search.split(',');
        params[0] = postFrom;
        params[1] = postTo;
        return params.join(',');
    }

    handleChange = () => {
        const params = this.props.pagination;
        params.search = this.buildSearch(params.search);
        this.props.changePagination(params);
    }

    handlePostDate = (e) => {
        if (e.from !== undefined) {
            this.setState({postFrom: e.from}, () => this.handleChange());
        } else if (e.to !== undefined) {
            this.setState({postTo: e.to}, () => this.handleChange());
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie artykuły'>
                    <Grid container direction='column' spacing={4} alignItems='center'>
                        <Grid item>
                            <DateSpanFilter
                                update={this.handlePostDate.bind(this)}
                                name='postDate'
                                title='Data dodania:'
                            />
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <GenericTable
                                data={this.props.data}
                                changePage={this.props.changePagination}
                                params={this.props.pagination}
                                columns={this.state.keys}
                                title={'artykuł'}
                                deleteFunction={this.props.deleteArticle}
                                slug={'article'}
                                add={'Dodaj nowy artykuł'}
                                skip={['postDate', 'category']}
                                search={`${this.state.postFrom},${this.state.postTo}`}
                            />
                        </Grid>
                    </Grid>
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({article}) => ({
    data: article.data,
    pagination: article.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteArticle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Articles);