import React from "react";
import { Switch } from "react-router-dom";
import Entities from "./entities";
import PrivateRoute from "./tools/privateRoute";
import Menu from "./modules/menu/Menu";

const Routes = () => (
  <>
    <Menu />
    <section id="main-content" className=" ">
      <section className="wrapper main-wrapper row" style={{}}>
        <Switch>
          <PrivateRoute path={"/"} component={Entities} />
        </Switch>
      </section>
    </section>
  </>
);

export default Routes;
