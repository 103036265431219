import React from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import { fields } from "./constants";
import { createAddon } from "./advert.reducer";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { getDesignSimple } from "../../design/design/design.reducer";

class AddonCreator extends React.Component {

    componentDidMount() {
        this.props.getDesignSimple();
    }

    columns = fields;

    cancel() {
        this.props.history.goBack();
    }

    save(data) {

        let body = {
            recommend: false,
            excludedFromFreeChoice: false,
            isPromoMaterials: true,
            price: data.price,
            name: data.name,
            description: data.description,
            orderNumber: data.orderNumber,
            details: data.details,
            image: data.image,
            maxCount: data.maxCount
        }
        this.props.createAddon(body);
    }

    render() {
        this.columns[5].values = this.props.designs;
        return (
            <Page header={'Dodaj materiał reklamowy'}>
                {this.props.designs && !this.props.designs.content &&
                    <GenericForm
                        content={this.columns}
                        data={{}}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                }
                {this.props.updateSuccess &&
                    <Redirect to={`/static-content/advert`} />
                }
            </Page>
        );
    }
}


const mapStateToProps = ({ addon, design }) => ({
    updateSuccess: addon.updateSuccess,
    data: addon.data,
    designs: design.data,
});

const mapDispatchToProps = {
    createAddon,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonCreator);