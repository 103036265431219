export const TAB_NAMES = {

    // Zawartość statyczna
    STATIC_CONTENT: 'static-content',

    // Projekty domów
    DESIGN: 'design',

    // ABC Budowy + Deweloper
    ABC: 'abc',

    // Formularze
    FORMS: 'forms',

    // Forum
    FORUM: 'forum',

    // Społeczność
    COMMUNITY: 'community',

    // Rabaty
    OFFERS: 'offers',

    // Zamówienia + Zwroty + Wymiany
    PURCHASE: 'purchase',

    // Przesyłki
    SHIPMENT: 'shipment',

    // Lista użytkowników
    USERS: 'users',

    // Role użytkowników
    ROLES: 'roles'

};


export const USER_ROLES = {
    ADMIN    : 'ROLE_ADMIN',
    OWNER    : 'ROLE_OWNER',
    EDITOR   : 'ROLE_EDITOR',
    COUNSELOR: 'ROLE_COUNSELOR',
    USER     : 'ROLE_USER',
};


export const AUTHED_TABS = {
    'ROLE_ADMIN'    : [
        // Domyślnie administrator posiada taki sam dostęp jak właściciel strony z wyjątkiem pola ze zmianą uprawnień innych użytkowników
    ],
    'ROLE_OWNER'    : [
        // Domyślnie właściciel strony posiada dostęp do wszystkich zakładek
    ],
    'ROLE_EDITOR'   : [
        TAB_NAMES.STATIC_CONTENT,
        TAB_NAMES.DESIGN,
        TAB_NAMES.ABC,
        TAB_NAMES.FORMS,
        TAB_NAMES.FORUM,
        TAB_NAMES.COMMUNITY,
        TAB_NAMES.OFFERS
    ],
    'ROLE_COUNSELOR': [
        TAB_NAMES.PURCHASE,
        TAB_NAMES.SHIPMENT,
        TAB_NAMES.USERS,
        TAB_NAMES.ROLES
    ],
    'ROLE_USER': [
        // Domyślnie użytkownik nie posiada dostępu do żadnej podstrony
    ]
};