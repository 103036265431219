import React from "react";
import {ListForm} from "./ListForm";
import {Controller} from "react-hook-form";
import WYSIWYGEditor from "./WYSIWYGEditor";
import Time from "./Time";
import CustomSelect from "./CustomSelect";
import './customStyle.css';
import {Grid} from "@material-ui/core";
import CategoriesOrdered from "./categories";
import PageEditor from "./page-editor/page-editor";
import {CustomCheckbox} from "../table/customCheckbox";
import FileUploaderController from "./FileUploaderController";
import {Mirror} from "../../entities/design/design/icons/mirror";
import './_input.scss';

export const renderInput = (content, data, methods, readOnly, name, previousName) =>{
    let id = name || content.id;
    // console.log(content.type)
    // console.log(methods)
    // console.log(methods.errors)
    // console.log(methods.errors, id)
    // console.log(getValue(methods.errors, id))
    switch (content.type) {
        case 'file':
            return (
                <>
                    <Controller
                        as={<FileUploaderController/>}
                        name={id}
                        control={methods.control}
                        defaultValue={data || null}
                        readOnly={readOnly}
                        content={content}
                        rules={{required: content.required}}
                    />
                    {/*// <FileUploader name={id} defaultValue={data || null} content={content}/>*/}
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='required' && (
                        <span role="alert">{content.required}</span>
                    )}
                </>
            );
        case 'files':
            return (
                <>
                    <Controller
                        as={<FileUploaderController/>}
                        name={id}
                        control={methods.control}
                        defaultValue={data !== undefined ? data : []}
                        readOnly={readOnly}
                        content={content}
                        rules={{required: content.required}}
                        multiple
                    />
                    {/*// <FileUploader multiple name={id} defaultValue={data || []} content={content}/>*/}
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='required' && (
                        <span role="alert">{content.required}</span>
                    )}
                </>
            );
        case 'page':
            return (
                <Controller
                    as={<PageEditor/>}
                    name={id}
                    control={methods.control}
                    defaultValue={data || ''}
                    readOnly={readOnly}
                    uploader={content.callback}
                />
            );
        case 'textarea':
            return (
                <Controller
                    as={<WYSIWYGEditor/>}
                    name={id}
                    control={methods.control}
                    defaultValue={data || ''}
                    readOnly={readOnly}
                />
            );
        case 'list':
            return (
                <ListForm content={content} data={data} previous={previousName} methods={methods}/>
            );
        case 'multiSelect':
            return (
                <>
                    <Controller
                        as={<CustomSelect/>}
                        name={id}
                        multi={true}
                        options={content.values}
                        control={methods.control}
                        defaultValue={data}
                        aria-invalid={getValue(methods.errors, id) ? 'true': 'false'}
                        rules={{required: content.required}}
                    />
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='required' && (
                        <span role="alert" data-name={content.id}>{content.required}</span>
                    )}
                </>
            );
        case 'select':
            return (
                <>
                    <Controller
                        as={<CustomSelect/>}
                        options={content.values}
                        name={id}
                        control={methods.control}
                        defaultValue={data && data.id ? data.id : data}
                        aria-invalid={getValue(methods.errors, id) ? 'true': 'false'}
                        rules={{required: content.required}}
                    />
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='required' && (
                        <span role="alert">{content.required}</span>
                    )}
                </>
            );
        case 'category':
            return (
                <Controller
                    as={<CategoriesOrdered/>}
                    name={id}
                    content={content}
                    control={methods.control}
                    defaultValue={data}
                    aria-invalid={getValue(methods.errors, id) ? 'true': 'false'}
                    rules={{required: content.required}}
                    limit={content.limit}
                />
            );
        case 'checkbox':
            return (
                <>
                    <Controller
                        as={CustomCheckbox}
                        control={methods.control}
                        name={id}
                        defaultValue={data}
                        aria-invalid={getValue(methods.errors, id) ? 'true': 'false'}
                        />
                    {/*<input className='iCheck' type={content.type} name={id}*/}
                    {/*       checked={data}*/}
                    {/*       ref={methods.register({required: content.required})}*/}
                    {/*       aria-invalid={getValue(methods.errors, id) ? 'true': 'false'}*/}
                    {/*/>*/}
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='required' && (
                        <span role="alert">{content.required}</span>
                    )}
                </>
            );
        case 'number':
            return (
                <>
                    <input className='form-control' name={id}
                           defaultValue={data || ""} ref={methods.register({required: content.required, pattern: /^-?\d*\.?\d*$/})} readOnly={readOnly}
                           aria-invalid={getValue(methods.errors, id) ? 'true': 'false'}
                    />
                    { getValue(methods.errors, id) &&  getValue(methods.errors, id).type ==='pattern' &&(
                        <span role="alert">Wartość musi być liczbą!</span>
                    )}
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='required' && (
                        <span role="alert">{content.required}</span>
                    )}
                </>
            );
        case 'class':
            const length = content.id.length + 1;
            return (
                <div style={{marginLeft: '20px'}}>
                    { content.values && content.values.map( (item, index)=>(
                        <div key={index} className='form-group text-left'>
                            <label className='form-label'>{item.name}</label>
                            {
                                renderInput(item, data && data[item.id.substring(length)], methods, readOnly)
                            }
                        </div>
                    ))
                    }
                </div>
            );
        case 'time':
            return (
                <>
                    <Controller
                        as={<Time/>}
                        name={id}
                        control={methods.control}
                        defaultValue={data || ""}
                        aria-invalid={getValue(methods.errors, id) ? 'true': 'false'}
                        rules={{required: content.required}}
                        readOnly={readOnly}
                        noTime={content.noTime}
                    />
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='required' && (
                        <span role="alert">{content.required}</span>
                    )}
                </>
            );
        default:
            return (
                <>
                    <input className='form-control' type={content.type} name={id}
                           defaultValue={data || ""} ref={methods.register({required: content.required, maxLength: (content.maxLength || 255) })} readOnly={readOnly || content.readOnly}
                           aria-invalid={getValue(methods.errors, id) ? 'true': 'false'}
                    />
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='required' && (
                        <span role="alert">{content.required}</span>
                    )}
                    { getValue(methods.errors, id) && getValue(methods.errors, id).type ==='maxLength' && (
                        <span role="alert">{`Maksymalna długość to ${content.maxLength || 255} znaków`}</span>
                    )}
                </>
            );
    }
};

export const renderField = (content, data, index, methods, readOnly, name, previousName) => (
    <div key={index} className='form-group text-left'>
        {content.type === 'checkbox' ?
            <>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                        {renderInput(content, data && data[content.id], methods, readOnly, name, previousName)}
                    </Grid>
                    <Grid>
                        <label className='form-label' style={{fontWeight: "bold"}}>{content.name}</label>
                    </Grid>
                </Grid>
            </>
            :
            <>
                <label className='form-label' data-name={content.id} style={{fontWeight: "bold"}}>{content.mirror && content.mirror === true ? <div className="mirror"><Mirror /> {content.name}</div> : content.name} {data && data.materials && content.name === 'Zestawienie materiałów' ? ` - ${data.materials.numberOfSubscribers} subskrypcji` : ''}{data && data.drawing && content.name === 'Rysunek szczegółowy' ? ` - ${data.drawing.numberOfSubscribers} subskrypcji` : ''}{data && data.drawingMirror && content.name === 'Rysunek szczegółowy - lustrzane odbicie' ? ` - ${data.drawingMirror.numberOfSubscribers} subskrypcji` : ''}{data && data.estimate && content.name === 'Kosztorys uproszczony' ? ` - ${data.estimate.numberOfSubscribers} subskrypcji` : ''}{data && data.houseDwg && content.name === 'Obrys domu w DWG' ? ` - ${data.houseDwg.numberOfSubscribers} subskrypcji` : ''}{data && data.housePdf && content.name === 'Obrys domu w PDF' ? ` - ${data.housePdf.numberOfSubscribers} subskrypcji` : ''}</label>
                {renderInput(content, data && data[content.id], methods, readOnly, name, previousName)}
            </>
        }
    </div>
);


const getValue = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1');
    s = s.replace(/^\./, '');
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export default renderInput;