import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import RealizationCreator from "./realizationCreator";
import Realizations from "./realizations";
import RealizationView from "./realization";

const Realization = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={RealizationCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={RealizationView}/>
            <PrivateRoute exact path={`${match.url}`} component={Realizations}/>
        </Switch>
    </>
);

export default Realization;