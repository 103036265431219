import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_ADDON_PACKAGES: 'addonPackage/FETCH_ALL_ADDON_PACKAGES',
    FETCH_ADDON_PACKAGE: 'addonPackage/FETCH_ADDON_PACKAGE',
    UPDATE_ADDON_PACKAGE: 'addonPackage/UPDATE_ADDON_PACKAGE',
    DELETE_ADDON_PACKAGE: 'addonPackage/DELETE_ADDON_PACKAGE',
    CHANGE_PAGINATION: 'addonPackage/CHANGE_PAGINATION',
    CREATE_ADDON_PACKAGE: 'addonPackage/CREATE_ADDON_PACKAGE',
    RESET: 'addonPackage/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const AddonPackageState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_ADDON_PACKAGES):
        case REQUEST(ActionTypes.FETCH_ADDON_PACKAGE):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_ADDON_PACKAGE):
        case REQUEST(ActionTypes.DELETE_ADDON_PACKAGE):
        case REQUEST(ActionTypes.UPDATE_ADDON_PACKAGE):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_ADDON_PACKAGES):
        case FAILURE(ActionTypes.FETCH_ADDON_PACKAGE):
        case FAILURE(ActionTypes.CREATE_ADDON_PACKAGE):
        case FAILURE(ActionTypes.DELETE_ADDON_PACKAGE):
        case FAILURE(ActionTypes.UPDATE_ADDON_PACKAGE):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_ADDON_PACKAGE):
        case SUCCESS(ActionTypes.UPDATE_ADDON_PACKAGE):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_ADDON_PACKAGES):
        case SUCCESS(ActionTypes.FETCH_ADDON_PACKAGE):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_ADDON_PACKAGE):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default AddonPackageState;

const apiUrl = 'addon/package';

export const getAllAddonPackages = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_ADDON_PACKAGES,
    payload: connection(applyParamsToUrl(`${apiUrl}/admin`,getState().addonPackage.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllAddonPackages());
    return result;
}

export const getSimplePackages = () =>({
    type: ActionTypes.FETCH_ALL_ADDON_PACKAGES,
    payload: connection(`${apiUrl}/admin/simple`, 'GET', null),
});


export const getAddonPackage = (id) =>({
    type: ActionTypes.FETCH_ADDON_PACKAGE,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createAddonPackage = (addonPackage) => ({
    type: ActionTypes.CREATE_ADDON_PACKAGE,
    payload: connection(`${apiUrl}/admin`, 'POST', addonPackage)
});

export const updateAddonPackage = (addonPackage) => ({
    type: ActionTypes.UPDATE_ADDON_PACKAGE,
    payload: connection(`${apiUrl}/admin`, 'PUT', addonPackage)
});

export const deleteAddonPackage = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_ADDON_PACKAGE,
        payload: connection(`${apiUrl}/admin/${id}`, 'DELETE', null)
    });
    dispatch(getAllAddonPackages());
    return result;
};