import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_JOURNALS: 'journal/FETCH_ALL_JOURNALS',
    FETCH_JOURNAL: 'journal/FETCH_JOURNAL',
    UPDATE_JOURNAL: 'journal/UPDATE_JOURNAL',
    DELETE_JOURNAL: 'journal/DELETE_JOURNAL',
    CHANGE_PAGINATION: 'journal/CHANGE_PAGINATION',
    RESET: 'journal/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const JournalState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_JOURNALS):
        case REQUEST(ActionTypes.FETCH_JOURNAL):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.DELETE_JOURNAL):
        case REQUEST(ActionTypes.UPDATE_JOURNAL):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_JOURNALS):
        case FAILURE(ActionTypes.FETCH_JOURNAL):
        case FAILURE(ActionTypes.DELETE_JOURNAL):
        case FAILURE(ActionTypes.UPDATE_JOURNAL):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.UPDATE_JOURNAL):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_JOURNALS):
        case SUCCESS(ActionTypes.FETCH_JOURNAL):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_JOURNAL):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default JournalState;

const apiUrl = 'community/journal';

export const getAllJournals = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_JOURNALS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().journal.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllJournals());
    return result;
};

export const getJournal = (id) =>({
    type: ActionTypes.FETCH_JOURNAL,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const updateJournal = (journal) => ({
    type: ActionTypes.UPDATE_JOURNAL,
    payload: connection(`${apiUrl}/admin`, 'PUT', journal)
});

export const deleteJournal = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_JOURNAL,
        payload: connection(`community/secured/journal/${id}`, 'DELETE', null)
    });
    dispatch(getAllJournals())
    return result
};

export const getSimpleJournal = () => ({
    type: ActionTypes.FETCH_ALL_JOURNALS,
    payload: connection(`${apiUrl}/simple/all`,'GET', null)
});