import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createSpecial} from "./special.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import {getDesignSimple} from "../../design/design/design.reducer";

class SpecialCreator extends Component{

    componentDidMount() {
        this.props.getDesignSimple();
    }

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        data.designs = data.designs.map((el) => ({id: el}));
        this.props.createSpecial(data);
    }

    columns = fields;

    render() {
        this.columns[1].values = this.props.designs;
        return (
            <Page header={'Dodaj ofertę specjalną'}>
                <GenericForm
                    content={this.columns}
                    data={{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({special, design}) => ({
    updateSuccess: special.updateSuccess,
    data: special.data,
    designs: design.data,
});

const mapDispatchToProps = {
    createSpecial,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialCreator);