export const removeEmptyKeys = (obj) => {
    Object.keys(obj).forEach(k => {
            if (obj[k] && typeof obj[k] === 'object') {
                if (!k.includes('editor')) {
                    removeEmptyKeys(obj[k])
                }
            } else if (obj[k] !== undefined && obj[k] === "" && isReadAble(obj, k)) {
                obj[k] = null
            }
        }
    );

    return obj;
};


function isReadAble(obj, k) {
    try {
        obj[k] = 'test';
        return true;
    } catch (e) {
        return false;
    }
}