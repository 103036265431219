import React, {Component} from "react";
import {connect} from 'react-redux';
import {getSale} from "./sale.reducer";
import Page from "../../../tools/Page";
import SaleForm from "./saleForm";
import {getSimplePackages} from "../addonPackages/addonPackage.reducer";
import {getSimpleDesignCategories} from "../category/category.reducer";
import {getSimpleAddons} from "../addons/addon.reducer";
import {getDesignSimple} from "../design/design.reducer";
import {updateSale} from "./sale.reducer";


class Sale extends Component{

   componentDidMount() {
       this.props.getDesignSimple();
       this.props.getSimpleAddons();
       this.props.getSimpleDesignCategories();
       this.props.getSimplePackages();
       this.props.getSale(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.match.params.id;
       if(data.designs){
           data.designs = data.designs.map(el => { return {id: el}})
       }
       if(data.addons){
           data.addons = data.addons.map(el => { return {id: el}})
       }
       if(data.packages){
           data.packages = data.packages.map(el => { return {id: el}})
       }
       if(data.categories){
           data.categories = data.categories.map(el => { return {id: el}})
       }
       console.log('@@@@', data)
       this.props.updateSale(data);
   }

    cancel(){
        this.props.history.goBack();
    }


    render() {
      return (
          <>
              <Page header={`Promocja ${this.props.match.params.id}`}>
                  {this.props.data && !this.props.data.content
                  && this.props.packages && this.props.designs && this.props.addons && this.props.categories &&
                  <SaleForm
                      data={{
                          ...this.props.data,
                          designs: this.props.data.designs && this.props.data.designs.map( el => el.id.toString()),
                          addons: this.props.data.addons && this.props.data.addons.map( el => el.id.toString()),
                          packages: this.props.data.packages && this.props.data.packages.map( el => el.id.toString()),
                          categories: this.props.data.categories && this.props.data.categories.map( el => el.id.toString()),
                      }}
                      packages={this.props.packages}
                      designs={this.props.designs}
                      addons={this.props.addons}
                      categories={this.props.categories}
                      save={this.save.bind(this)}
                      cancel={this.cancel.bind(this)}
                  />
                  }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({sale, addonPackage, design, addon, category}) => ({
   data: sale.data,
    packages: addonPackage.data,
    designs: design.data,
    addons: addon.data,
    categories: category.data,
});

const mapDispatchToProps = {
    getSale,
    updateSale,
    getSimplePackages,
    getSimpleDesignCategories,
    getSimpleAddons,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(Sale);