export const fields = [
    {
        name: 'Tytuł',
        id: 'title',
        type: 'text',
    },
    {
        name: 'Miniaturka (zalecane 480px x 270px)',
        id: 'mainImage',
        type: 'file',
    },
    {
        name: 'Zdjęcia (zalecane 1920 x 889)',
        id: 'images',
        type: 'files',
    },
    {
        name: 'Projekt',
        id: 'design',
        type: 'select',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Data dodania',
        id: 'creationDate',
        type: 'time',
    },
];