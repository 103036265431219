import connection from "./connection";
import {toast} from "react-toastify";

export const handleDownload = (downloadLink, name) => {
    connection(downloadLink, 'GET', null, true)
        .then((response) => response.blob())
        .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);

                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
            }
        ).catch(error =>{
        return error.text();
    }).then(error => {
        toast.error(error);
    })
};