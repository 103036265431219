import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_DISCOUNTS: 'discount/FETCH_ALL_DISCOUNTS',
    FETCH_DISCOUNT: 'discount/FETCH_DISCOUNT',
    UPDATE_DISCOUNT: 'discount/UPDATE_DISCOUNT',
    DELETE_DISCOUNT: 'discount/DELETE_DISCOUNT',
    CHANGE_PAGINATION: 'discount/CHANGE_PAGINATION',
    CREATE_DISCOUNT: 'discount/CREATE_DISCOUNT',
    RESET: 'discount/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const DiscountState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_DISCOUNTS):
        case REQUEST(ActionTypes.FETCH_DISCOUNT):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_DISCOUNT):
        case REQUEST(ActionTypes.DELETE_DISCOUNT):
        case REQUEST(ActionTypes.UPDATE_DISCOUNT):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_DISCOUNTS):
        case FAILURE(ActionTypes.FETCH_DISCOUNT):
        case FAILURE(ActionTypes.CREATE_DISCOUNT):
        case FAILURE(ActionTypes.DELETE_DISCOUNT):
        case FAILURE(ActionTypes.UPDATE_DISCOUNT):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_DISCOUNT):
        case SUCCESS(ActionTypes.UPDATE_DISCOUNT):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_DISCOUNTS):
        case SUCCESS(ActionTypes.FETCH_DISCOUNT):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_DISCOUNT):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };
        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default DiscountState;

const apiUrl = 'discount/admin';

export const getAllDiscounts = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_DISCOUNTS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().discount.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllDiscounts());
    return result;
}

export const getUserDiscounts = (id) => ({
    type: ActionTypes.FETCH_ALL_DISCOUNTS,
    payload: connection(`user/admin/discount/${id}`, 'GET', null),
});

export const getDiscount = (id) =>({
    type: ActionTypes.FETCH_DISCOUNT,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createDiscount = (discount) => ({
    type: ActionTypes.CREATE_DISCOUNT,
    payload: connection(`${apiUrl}`, 'POST', discount)
});

export const updateDiscount = (discount) => ({
    type: ActionTypes.UPDATE_DISCOUNT,
    payload: connection(`${apiUrl}`, 'PUT', discount)
});

export const deleteDiscount = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_DISCOUNT,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllDiscounts());
    return result;
};