import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getProjection, updateProjection} from "./details.reducer";
import {projectionFields} from "./constants";


class Projection extends Component{

   componentDidMount() {
       this.props.getProjection(this.props.designId);
   }

   save(data){
       data.id = this.props.projection.id;
       this.props.updateProjection(data);
   }


    render() {
      return (
          <>
              { this.props.projection && !this.props.loading &&
                <GenericForm
                    content={projectionFields}
                    data={this.props.projection}
                    save={this.save.bind(this)}
                    cancel={this.props.cancel}
                />
              }
          </>
      );
   }

}

const mapStateToProps = ({details}) => ({
   projection: details.data,
    loading: details.loading,
});

const mapDispatchToProps = {
    getProjection,
    updateProjection
};

export default connect(mapStateToProps, mapDispatchToProps)(Projection);