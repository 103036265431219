import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteStaticText} from "./text.reducer";
import Page from "../../../tools/Page";

class Texts extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                    {id: 'name', name: 'Nazwa'},
                    {id: 'data', name: 'Zawartość'},
                    {id: 'language', name: 'Język'}
                ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie teksty statyczne'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'tekst'}
                    deleteFunction={this.props.deleteStaticText}
                    slug={'text'}
                    add={'Dodaj nowy tekst'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({text}) => ({
    data: text.data,
    pagination: text.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteStaticText
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Texts);