import React, {Component} from 'react';
import {connect} from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteDiscount} from "./discount.reducer";
import Page from "../../../tools/Page";
import Select from "react-select";
import {Grid} from "@material-ui/core";
import {DateSpanFilter} from "../../purchase/purchase/filters/DateSpanFilter";

const mapTypeToString = (type) => {
    switch (type) {
        case 'HK_VOUCHER':
            return 'Bon do HK shop';
        case 'HK_DESIGN':
            return 'Bon na projekty domów';
        case 'HK_GARDEN':
            return 'Bon na projekty ogrodów';
        default:
            return '';
    }
}
class Discounts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keys: [
                {id: 'user', name: 'Użytkownik', value: 'id', slug: '/users'},
                {id: 'user', name: 'Imię', value: 'name'},
                {id: 'user', name: 'Nazwisko', value: 'surname'},
                {id: 'type', name: 'Rodzaj rabatu', mapper: mapTypeToString},
                {id: 'creationDate', name: 'Data stworzenia'},
                {id: 'deadline', name: 'Data zakończenia'},
                {id: 'code', name: 'Kod'},
            ],
            tmp: this.props.location.search.split('search=')[1],
            id: this.props.location.search.split('search=user:')[1],
            search: `${this.props.location.search.split('search=')[1]},,,,`,

            type: '',
            createFrom: '',
            createTo: '',
        }
    }

    options = [
        {
            value: "HK_GARDEN",
            label: mapTypeToString('HK_GARDEN')
        },
        {
            value: "HK_DESIGN",
            label: mapTypeToString('HK_DESIGN')
        },
        {
            value: "HK_VOUCHER",
            label: mapTypeToString('HK_VOUCHER')
        }
    ];

    buildSearch = (search)=>{
        const type = this.state.type !== '' ? `type:${this.state.type}` : '';
        const params = search.split(',');
        params[0] = this.state.tmp;
        params[1] = type;
        params[2] = this.state.createFrom;
        params[3] = this.state.createTo;

        this.setState({
            search: `${params[0]},${params[1]},${params[2]},${params[3]}`
        })
        return params.join(',');
    }

    search = () => {
        const params = this.props.pagination;
        params.search = this.buildSearch(params.search);
        this.props.changePagination(params);
    }

    handleChange = (e) => {
        this.setState(() => {
                let value = '';
                if(e){
                    value = e.value;
                }
                return {
                    type: value,
                }
            },
            () => {
                this.search();
            })
    }

    handleCreationDate = (e) => {
        if (e.from !== undefined) {
            this.setState({createFrom: e.from}, () => this.handleChange());
        } else if (e.to !== undefined) {
            this.setState({createTo: e.to}, () => this.handleChange());
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie rabaty'>
                    <Grid container direction='column' spacing={2} alignItems='center'>
                        <Grid item style={{width: '100%'}}>
                            <Select
                                closeMenuOnSelect={true}
                                options={this.options}
                                onChange={this.handleChange.bind(this)}
                                styles={selectStyles}
                                placeholder='Rodzaj rabatu'
                                isClearable={true}
                            />
                        </Grid>
                        <Grid item>
                            <DateSpanFilter
                                update={this.handleCreationDate.bind(this)}
                                name='creationDate'
                                title='Data stworzenia:'
                            />
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <GenericTable
                                data={this.props.data}
                                changePage={this.props.changePagination}
                                params={this.props.pagination}
                                columns={this.state.keys}
                                title={'rabat'}
                                skip={['deadline', 'creationDate', 'id', 'type']}
                                deleteFunction={this.props.deleteDiscount}
                                slug={'discount'}
                                add={'Dodaj nowy rabat'}
                                search={this.state.search || ''}
                                setId={this.state.id}
                            />
                        </Grid>
                    </Grid>
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({discount}) => ({
    data: discount.data,
    pagination: discount.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteDiscount
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Discounts);

export const selectStyles = {menu: styles => ({...styles, zIndex: 999})};