import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteSpecial} from "./special.reducer";
import Page from "../../../tools/Page";

class Specials extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'title', name: 'Nazwa'},
                {id: 'price', name: 'Cena'},
                {id: 'deadline', name: 'Ważny do'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie oferty specjalne'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'oferta specjalna'}
                    skip={['deadline']}
                    deleteFunction={this.props.deleteSpecial}
                    slug={'special'}
                    add={'Dodaj nową ofertę specjalną'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({special}) => ({
    data: special.data,
    pagination: special.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteSpecial
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Specials);