export const fields = [
    {
        name: 'Rodzaj rabatu',
        id: 'type',
        type: 'select',
        required: 'To pole jest wymagane',
        values: [
            {
                id: 'HK_VOUCHER',
                name: 'Bon rabatowy do HK shop',
            },
            {
                id: 'HK_DESIGN',
                name: 'Bon rabatowy na wybrane projekty',
            },
            {
                id: 'HK_GARDEN',
                name: 'Bon rabatowy na projekt ogrodu',
            }
        ]
    },
    {
        name: 'Użytkownik',
        id: 'user.id',
        type: 'text',
        required: 'To pole jest wymagane'
    },
    {
        name: 'Kod rabatowy',
        id: 'code',
        type: 'text',
        required: 'To pole jest wymagane'
    },
    {
        name: 'Wartość procentowa?',
        id: 'isPercent',
        type: 'checkbox',
        required: 'To pole jest wymagane'
    },
    {
        name: 'Wartość',
        id: 'value',
        type: 'number',
        required: 'To pole jest wymagane'
    },
    {
        name: 'Termin rabatu',
        id: 'deadline',
        type: 'time',
        required: 'To pole jest wymagane'
    }
];

export const optionalFields = [
    {
        name: 'Kategorie projektów',
        id: 'categories',
        type: 'multiSelect',
    },
    {
        name: 'Projekty',
        id: 'designs',
        type: 'multiSelect',
    }
]