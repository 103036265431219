import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_DOWNLOADABLES: 'detail/FETCH_ALL_DOWNLOADABLES',
    FETCH_DETAILS: 'detail/FETCH_DETAILS',
    UPDATE_DETAILS: 'detail/UPDATE_DETAILS',
    FETCH_DETAILS_DOWNLOADABLE: 'detail/FETCH_DETAILS_DOWNLOADABLE',
    UPDATE_DETAILS_DOWNLOADABLE: 'detail/UPDATE_DETAILS_DOWNLOADABLE',
    FETCH_DETAILS_ESTIMATE: 'detail/FETCH_DETAILS_ESTIMATE',
    UPDATE_DETAILS_ESTIMATE: 'detail/UPDATE_DETAILS_ESTIMATE',
    FETCH_DETAILS_PROJECTION: 'detail/FETCH_DETAILS_PROJECTION',
    UPDATE_DETAILS_PROJECTION: 'detail/UPDATE_DETAILS_PROJECTION',
    FETCH_DETAILS_TECHNICAL: 'detail/FETCH_DETAILS_TECHNICAL',
    UPDATE_DETAILS_TECHNICAL: 'detail/UPDATE_DETAILS_TECHNICAL',
    CHANGE_PAGINATION: 'design/CHANGE_PAGINATION',
    RESET: 'detail/RESET',
};

const initState = {
    loading: false,
    data: [],
    pagination: defaultSearchableParams(25),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const DetailsState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_DETAILS_DOWNLOADABLE):
        case REQUEST(ActionTypes.FETCH_DETAILS_ESTIMATE):
        case REQUEST(ActionTypes.FETCH_DETAILS_PROJECTION):
        case REQUEST(ActionTypes.FETCH_DETAILS_TECHNICAL):
        case REQUEST(ActionTypes.FETCH_DETAILS):
        case REQUEST(ActionTypes.FETCH_ALL_DOWNLOADABLES):
            return{
                data: null,
                ...state,
                errorMessage: null,
                loading: true,
            };
        case REQUEST(ActionTypes.UPDATE_DETAILS_DOWNLOADABLE):
        case REQUEST(ActionTypes.UPDATE_DETAILS_ESTIMATE):
        case REQUEST(ActionTypes.UPDATE_DETAILS_PROJECTION):
        case REQUEST(ActionTypes.UPDATE_DETAILS_TECHNICAL):
        case REQUEST(ActionTypes.UPDATE_DETAILS):
            return{
                ...state,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_DOWNLOADABLES):
        case FAILURE(ActionTypes.FETCH_DETAILS_DOWNLOADABLE):
        case FAILURE(ActionTypes.FETCH_DETAILS_ESTIMATE):
        case FAILURE(ActionTypes.FETCH_DETAILS_PROJECTION):
        case FAILURE(ActionTypes.FETCH_DETAILS_TECHNICAL):
        case FAILURE(ActionTypes.UPDATE_DETAILS_DOWNLOADABLE):
        case FAILURE(ActionTypes.UPDATE_DETAILS_ESTIMATE):
        case FAILURE(ActionTypes.UPDATE_DETAILS_PROJECTION):
        case FAILURE(ActionTypes.UPDATE_DETAILS_TECHNICAL):
        case FAILURE(ActionTypes.FETCH_DETAILS):
        case FAILURE(ActionTypes.UPDATE_DETAILS):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.UPDATE_DETAILS_DOWNLOADABLE):
        case SUCCESS(ActionTypes.UPDATE_DETAILS_ESTIMATE):
        case SUCCESS(ActionTypes.UPDATE_DETAILS_PROJECTION):
        case SUCCESS(ActionTypes.UPDATE_DETAILS_TECHNICAL):
        case SUCCESS(ActionTypes.UPDATE_DETAILS):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_DOWNLOADABLES):
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            };
        case SUCCESS(ActionTypes.FETCH_DETAILS_DOWNLOADABLE):
        case SUCCESS(ActionTypes.FETCH_DETAILS_ESTIMATE):
        case SUCCESS(ActionTypes.FETCH_DETAILS_PROJECTION):
        case SUCCESS(ActionTypes.FETCH_DETAILS_TECHNICAL):
        case SUCCESS(ActionTypes.FETCH_DETAILS):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default DetailsState;

const fetchUrl = 'design/details';

const updateUrl = 'design/admin/details';

export const getDetails = (id) =>({
    type: ActionTypes.FETCH_DETAILS,
    payload: connection(`${fetchUrl}/${id}`, 'GET', null)
});

export const updateDetails = (details) => ({
    type: ActionTypes.UPDATE_DETAILS,
    payload: connection(`${updateUrl}`, 'PUT', details)
});

export const getDownloadable = (id) =>({
    type: ActionTypes.FETCH_DETAILS_DOWNLOADABLE,
    payload: connection(`${fetchUrl}/downloadable/${id}`, 'GET', null)
});

export const updateDownloadable = (downloadable) => ({
    type: ActionTypes.UPDATE_DETAILS_DOWNLOADABLE,
    payload: connection(`${updateUrl}/downloadable`, 'PUT', downloadable)
});

export const getEstimate = (id) =>({
    type: ActionTypes.FETCH_DETAILS_ESTIMATE,
    payload: connection(`${fetchUrl}/estimate/${id}`, 'GET', null)
});

export const updateEstimate = (estimate) => ({
    type: ActionTypes.UPDATE_DETAILS_ESTIMATE,
    payload: connection(`${updateUrl}/estimate`, 'PUT', estimate)
});

export const getProjection = (id) =>({
    type: ActionTypes.FETCH_DETAILS_PROJECTION,
    payload: connection(`${fetchUrl}/projection/${id}`, 'GET', null)
});

export const updateProjection = (projection) => ({
    type: ActionTypes.UPDATE_DETAILS_PROJECTION,
    payload: connection(`${updateUrl}/projection`, 'PUT', projection)
});

export const getTechnical = (id) =>({
    type: ActionTypes.FETCH_DETAILS_PROJECTION,
    payload: connection(`${fetchUrl}/technical/${id}`, 'GET', null)
});

export const updateTechnical = (technical) => ({
    type: ActionTypes.UPDATE_DETAILS_PROJECTION,
    payload: connection(`${updateUrl}/technical`, 'PUT', technical)
});

export const getSimilar = (id) => ({
    type: ActionTypes.FETCH_DETAILS,
    payload: connection(`design/admin/similar/${id}`, 'GET', null),
})

export const updateSimilar = (id, similar) => ({
    type: ActionTypes.UPDATE_DETAILS,
    payload: connection(`design/admin/similar/${id}`, 'PUT', similar),
})
export const getImages = (id) =>({
    type: ActionTypes.FETCH_DETAILS,
    payload: connection(`design/details/images/${id}`, 'GET', null)
});

export const updateImages = (images) => ({
    type: ActionTypes.UPDATE_DETAILS,
    payload: connection(`design/admin/details/images/`, 'PUT', images)
});


export const getSimpleAddonsByDesign = (id) =>({
    type: ActionTypes.FETCH_DETAILS,
    payload: connection(`addon/admin/simple/${id}`, 'GET', null),
});


export const updateDesignAddons = (id, addons) => ({
    type: ActionTypes.UPDATE_DETAILS,
    payload: connection(`addon/admin/design/${id}`, 'PUT', addons)
});

export const getRotationImages = (id) =>({
    type: ActionTypes.FETCH_DETAILS,
    payload: connection(`design/details/rotation/${id}`, 'GET', null),
});


export const updateRotationImages = (id, images) => ({
    type: ActionTypes.UPDATE_DETAILS,
    payload: connection(`design/details/admin/rotation/${id}`, 'PUT', images)
});


export const getAllDownloadables = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_DOWNLOADABLES,
    payload: connection(applyParamsToUrl(`${updateUrl}/documents/subs`, getState().details.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllDownloadables());
    return result;
};