import React, { Component } from "react";
import "./App.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "./routes";
import Login from "./modules/account/login";
import { getUser } from "./modules/account/account.reducer";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import forgotPassword from "./modules/account/forgotPassword";
import Activate from "./modules/account/activate";

class App extends Component {
  componentDidMount() {
    this.props.getUser();
  }

  render() {
    return (
      <Router>
        <div className="App">
          <ToastContainer />
          <Switch>
            <Route exact path={"/login"} component={Login} />
            <Route exact path={"/reset"} component={forgotPassword} />
            <Route exact path={"/activate:key?"} component={Activate} />
            <Route component={Routes} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = {
  getUser,
};

export default connect(null, mapDispatchToProps)(App);
