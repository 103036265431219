export const fields = [
  {
    name: "Nazwa",
    id: "name",
    type: "text",
    required: "To pole jest wymagane",
  },
  {
    name: "Numer",
    id: "number",
    type: "text",
  },
  {
    name: "Wariant",
    id: "variant",
    type: "text",
  },
  {
    name: "Kategoria",
    id: "categories",
    type: "multiSelect",
    required: "To pole jest wymagane",
  },
  {
    name: "Stara cena",
    id: "oldPrice",
    type: "number",
  },
  {
    name: "Aktualna cena",
    id: "price",
    type: "number",
    required: "To pole jest wymagane",
  },
  // {
  //     name: 'Promocja',
  //     id: 'onSale',
  //     type: 'checkbox',
  // },
  {
    name: "Liczba łazienek",
    id: "numberOfBathrooms",
    type: "number",
  },
  {
    name: "Liczba sypialni",
    id: "numberOfBedrooms",
    type: "number",
  },
  {
    name: "Liczba miejsc postojowych",
    id: "numberOfGarages",
    type: "number",
  },
  {
    name: "Poddasze do adaptacji",
    id: "attic",
    type: "checkbox",
  },
  {
    name: "Antresola",
    id: "entresol",
    type: "checkbox",
  },
  {
    name: "Wjazd od południa",
    id: "southEntrance",
    type: "checkbox",
  },
  {
    name: "Pralnia",
    id: "laundry",
    type: "checkbox",
  },
  {
    name: "Sauna",
    id: "sauna",
    type: "checkbox",
  },
  {
    name: "Spiżarnia",
    id: "pantry",
    type: "checkbox",
  },
  {
    name: "Zadaszony taras",
    id: "terrace",
    type: "checkbox",
  },
  {
    name: "Kominek",
    id: "fireplace",
    type: "checkbox",
  },
  {
    name: "master bedroom",
    id: "masterBedroom",
    type: "checkbox",
  },
  {
    name: "pomieszczenie rekreacyjne",
    id: "recreationRoom",
    type: "checkbox",
  },
  {
    name: "Kondygnacja",
    id: "storey",
    type: "select",
    values: [
      {
        id: "SINGLE_STOREY",
        name: "Dom parterowy",
      },
      {
        id: "MULTI_STOREY",
        name: "Dom piętrowy",
      },
      {
        id: "ATTIC_HOUSE",
        name: "Dom z poddaszem",
      },
    ],
  },
  {
    name: "Rodzaj dachu",
    id: "roofType",
    type: "select",
    values: [
      {
        id: "FLAT",
        name: "Płaski",
      },
      {
        id: "GABLE",
        name: "Dwuspadowy",
      },
      {
        id: "HIP",
        name: "Wielospadowy",
      },
    ],
  },
  {
    name: "Slug",
    id: "slug",
    type: "text",
  },
  {
    name: "Link do 'Zobacz produkty'",
    id: "linkToProducts",
    type: "text",
  },
  {
    name: "Karta projektu",
    id: "designCard",
    type: "file",
    image: true,
    noAlt: true,
  },
  {
    name: "Link do edytora rzutów",
    id: "linkToProjections",
    type: "text",
  },
  {
    name: "Rodzaj dostawy",
    id: "deliveryType",
    type: "select",
    values: [
      {
        id: "BEFORE",
        name: "Zamów przed 13:00",
      },
      {
        id: "FIVE",
        name: "dostawa 1-5 dni",
      },
      {
        id: "DEFAULT",
        name: "dostawa do 20 dni",
      },
    ],
  },
  {
    name: "Wyłączyć lustro",
    id: "enableMirror",
    type: "checkbox",
  },
  {
    name: "Aktywny projekt",
    id: "active",
    type: "checkbox",
  },
  {
    name: "Meta tytuł",
    id: "pageTitle",
    type: "text",
  },
  {
    name: "Meta opis",
    id: "pageDescription",
    type: "text",
  },
];

export const importFields = [
  {
    name: "Rodzaj importu",
    id: "type",
    type: "select",
    values: [
      {
        id: "DESIGN",
        name: "Importuj projekty",
      },
      {
        id: "PROJECTION",
        name: "Importuj rzuty",
      },
    ],
    required: "To pole jest wymagane!",
  },
  {
    name: "Plik xlsx",
    id: "file",
    type: "file",
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    image: true,
    noAlt: true,
  },
];
