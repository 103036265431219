export const fields = [
    {
        name: 'Imię',
        id: 'name',
        type: 'text',
    },
    {
        name: 'Nazwisko',
        id: 'surname',
        type: 'text',
    },
    {
        name: 'Telefon',
        id: 'phone',
        type: 'text',
    },
    {
        name: 'Email',
        id: 'email',
        type: 'text',
    },
    {
        name: 'Adres',
        id: 'address',
        type: 'text',
    },
    {
        name: 'Firma',
        id: 'company',
        type: 'text',
    },
    {
        name: 'Kod pocztowy',
        id: 'postCode',
        type: 'text',
    },
    {
        name: 'Miasto',
        id: 'city',
        type: 'text',
    },
    {
        name: 'Komentarz',
        id: 'comments',
        type: 'text',
    },
    {
        name: 'Źródło',
        id: 'whereLearned',
        type: 'text',
    },
    {
        name: 'Zgoda marketingowa',
        id: 'marketing',
        type: 'text',
    },
    {
        name: 'Data',
        id: 'date',
        type: 'text',
    },
];