import { Component } from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import { getAllAddons, changePagination, deleteAddon } from "./advert.reducer";
import Page from "../../../tools/Page";

class Addons extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keys: [
                { id: 'name', name: 'Nazwa' },
                { id: 'price', name: 'Cena' },
                { id: 'description', name: 'Opis' }
            ],
        }
    }
    componentDidMount() {
        this.props.getAllAddons();
    }

    render() {
        return (
            <>
                <Page header='Materiały reklamowe'>
                    <GenericTable
                        data={this.props.data}
                        changePage={this.props.changePagination}
                        params={this.props.pagination}
                        columns={this.state.keys}
                        title={'dodatek'}
                        deleteFunction={this.props.deleteAddon}
                        skip={['price']}
                        slug={'advert'}
                        add={'Dodaj nowy materiał reklamowy'}
                        disableSearch
                        disableImport
                    />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({ addon }) => ({
    data: addon.data,
    pagination: addon.pagination
});

const mapDispatchToProps = {
    getAllAddons,
    changePagination,
    deleteAddon
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Addons);