import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../../tools/privateRoute";
import Purchases from "./purchases";
import PurchaseView from "./purchase";
import PurchaseCreator from "./purchaseCreator";

const Purchase = ({ match }) => (
  <>
    <Switch>
      <PrivateRoute
        exact
        path={`${match.url}/add`}
        component={PurchaseCreator}
      />
      <PrivateRoute exact path={`${match.url}/:id`} component={PurchaseView} />
      <PrivateRoute exact path={`${match.url}`} component={Purchases} />
    </Switch>
  </>
);

export default Purchase;
