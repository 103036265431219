import React, {Component} from "react";
import {connect} from 'react-redux';
import {getDiscount, updateDiscount} from "./discount.reducer";
import {getSimpleDesignCategories} from "../../design/category/category.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {fields, optionalFields} from "./constants";
import Page from "../../../tools/Page";
import DiscountForm from "./discountForm";


class Discount extends Component{

   componentDidMount() {
       this.props.getSimpleDesignCategories();
       this.props.getDesignSimple();
       this.props.getDiscount(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.data.id;
       if(data.designs) {
           data.designs = data.designs.map((el) => ({id: el}));
       }
       if (data.categories) {
           data.categories = data.categories.map((el) => ({id: el}));
       }
       this.props.updateDiscount(data);
   }

    cancel(){
        this.props.history.goBack();
    }

    columns = optionalFields;

    render() {
        this.columns[0].values = this.props.categories;
        this.columns[1].values = this.props.designs;
      return (
          <>
              <Page header={`Rabat ${this.props.match.params.id}`}>
              { this.props.data && this.props.data.designs && this.props.designs && !this.props.designs.content && this.props.categories &&
                  !this.props.categories.content &&
                <DiscountForm
                    optional={this.columns}
                    content={fields}
                    data={{
                        ...this.props.data,
                        designs: this.props.data.designs.map((el) => el.id.toString()),
                        categories: this.props.data.categories.map((el)=> el.id.toString()),
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
              }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({discount, design, category}) => ({
    data: discount.data,
    designs: design.data,
    categories: category.data,
});

const mapDispatchToProps = {
    getDiscount,
    updateDiscount,
    getDesignSimple,
    getSimpleDesignCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Discount);