import ImageUpload from "@react-page/editor/lib/ui/ImageUpload/ImageUpload";
import {TextField, Typography, Checkbox, FormControlLabel} from "@material-ui/core";
import React from "react";
import {ImageUploadType} from "@react-page/plugins-image";
import {CellPluginComponentProps} from "@react-page/editor";
import {Data} from "./ImagePlugin";

declare type CustomImageControlType = React.ComponentType<CellPluginComponentProps<Data> & {
    imageUpload?: ImageUploadType;
}>;

export const CustomImageControls: CustomImageControlType = (props) => {
    return (
        <div>
            <div style={{ display: 'flex' }}>
                {props.imageUpload && (
                    <React.Fragment>
                        <ImageUpload
                            maxFileSize={1024* 1000}
                            imageUpload={props.imageUpload}
                            imageUploaded={(image) =>
                                props.onChange({
                                    src: image.url,
                                })
                            }
                        />
                        <Typography
                            variant="body1"
                            style={{ margin: '20px'}}
                        >
                            Lub
                        </Typography>
                    </React.Fragment>
                )}
                <TextField
                    placeholder={'Umieść link do zdjęcia'}
                    label={'Umieść link do zdjęcia'}
                    name="src"
                    value={props.data.src ?? ''}
                    onChange={(e) =>
                        props.onChange({
                            src: e.target.value,
                        })
                    }
                />
            </div>
            <TextField
                placeholder='Alt'
                label='Alt'
                name="alt"
                style={{ width: '512px' }}
                value={props.data.alt ?? ''}
                onChange={(e) =>
                    props.onChange({
                        alt: e.target.value,
                    })
                }
            />
            <div style={{ display: 'flex' }}>
            <TextField
                placeholder='Link'
                label='Link'
                name="link"
                style={{ width: '512px', marginRight: '20px'}}
                value={props.data.link ?? ''}
                onChange={(e) =>
                    props.onChange({
                        link: e.target.value,
                    })
                }
            />
            <FormControlLabel
                label='Otworzyć w nowej karcie'
                name="tab"
                control={<Checkbox/>}
                style={{marginTop: '10px'}}
                checked={props.data.tab}
                onChange={(e) => {
                    props.onChange({
                        tab: e.target.checked,
                    })
                }
                }
            />
            </div>
        </div>
    );
};

export default CustomImageControls;