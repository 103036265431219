import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getDesign, updateDesign, getPrice, deletePriceData} from "./design.reducer";
import {getSimpleDesignCategories} from '../category/category.reducer';
import {fields} from "./constants";


class Design extends Component{

    columns = fields;

    componentDidMount() {
        this.props.getDesign(this.props.designId);
        this.props.getSimpleDesignCategories();
        this.props.getPrice(this.props.designId);
    }

    save(data){
        data.id = this.props.designId;
        if(data.categories) {
            data.categories = data.categories.map((el) => {
                return {id: el}
            });
        }
        this.props.updateDesign(data);
    }

    render() {
       this.columns[3].values=this.props.categories;
       console.log(this.props)
        return (
          <>
              { this.props.data && this.props.data.id && this.props.categories && !this.props.loading &&
                  <>
                      <GenericForm
                          content={this.columns}
                          data={{
                              ...this.props.data,
                              categories: this.props.data.categories.map((e) => {return e.id.toString()})
                          }}
                          save={this.save.bind(this)}
                          cancel={this.props.cancel}
                          priceData={this.props.priceData}
                          deletePrice={this.props.deletePriceData}
                      />
                  </>
              }
          </>
      );
   }

}

const mapStateToProps = ({design, category}) => ({
    data: design.data,
    categories: category.data,
    priceData: design.price,
    loading: design.loading,
});

const mapDispatchToProps = {
    getDesign,
    updateDesign,
    getSimpleDesignCategories,
    getPrice,
    deletePriceData
};

export default connect(mapStateToProps, mapDispatchToProps)(Design);