import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {category} from "./constants";
import {getAllDocuments, addCategory} from "./document.reducer";
import {connect} from "react-redux";

class CategoryCreator extends Component{

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        this.props.addCategory(data, this.props.match.params.id);
        this.props.getAllDocuments();
        this.cancel();
    }

    render() {

        return (
            <Page header={'Dodaj kategorię'}>
                <GenericForm
                    content={category}
                    data = {{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
            </Page>
        );
    }
}


const mapStateToProps = ({document}) => ({
    updateSuccess: document.updateSuccess,
    data: document.data
});

const mapDispatchToProps = {
    addCategory,
    getAllDocuments
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCreator);