import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { USER_ROLES, TAB_NAMES, AUTHED_TABS } from "../../tools/user_access_constants";



const SidebarMenuItem = (props) => {

    const gridProps = {...props};
    delete gridProps.user;
    delete gridProps.tab;


    const isDisabled = () => {
        const tabName = props.tab.toUpperCase()?.replaceAll('-', '_');
        const userRole = props && props.user && props.user.authority ? props.user.authority : null;
        
        if (userRole) {
            if (userRole !== USER_ROLES.ADMIN && userRole !== USER_ROLES.OWNER && !AUTHED_TABS[userRole].includes(TAB_NAMES[tabName]))
                return { opacity: '0.4', pointerEvents: 'none' };
            else
                return { opacity: '1', pointerEvents: 'auto' };
        }
        return { opacity: '0.4', pointerEvents: 'none' };
    }
    const handleClick = e => {
        e.preventDefault();
    }
    

    return (
        
        <a 
            href="/" 
            onClick={ handleClick } 
            style={ isDisabled() }>
            <Grid {...gridProps} />
        </a>
    )
}



const mapStateToProps = ({auth}) => ({
    user: auth.user
});



export default connect(mapStateToProps, null)(SidebarMenuItem);