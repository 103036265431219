import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getUser, banUser} from "./user.reducer";
import {ban} from "./constants";


class BanUser extends Component{

    componentDidMount() {
        this.props.getUser(this.props.userId);
    }

    save(data){
        this.props.banUser(this.props.userId, data.banDate);
    }

    render() {
        return (
            <>
                { this.props.data && !Array.isArray(this.props.data) &&
                <GenericForm
                    content={ban}
                    data={{
                        banDate: this.props.data.banDate,
                    }}
                    save={this.save.bind(this)}
                    cancel={this.props.cancel}
                />
                }
            </>
        );
    }

}

const mapStateToProps = ({user}) => ({
    data: user.data,
});

const mapDispatchToProps = {
    getUser,
    banUser
};

export default connect(mapStateToProps, mapDispatchToProps)(BanUser);