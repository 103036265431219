export const fields = [
    {
        name: 'Tytuł',
        id: 'title',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Zawartość',
        id: 'editor',
        type: 'page',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Miniaturka - zalecany rozmiar: 375 x 310',
        id: 'mainImage',
        type: 'file',
        image: true,
    },
    {
        name: 'Zdjęcie w banerze - zalecany rozmiar: 1835 x 300',
        id: 'bannerImage',
        type: 'file',
        image: true,
    },
    {
        name: 'Kategoria',
        id: 'category',
        type: 'select',
    },
    {
        name: 'Wstęp',
        id: 'intro',
        type: 'text',
        required: 'To pole jest wymagane',
        maxLength: 150,
    },
    {
        name: 'Data dodania',
        id: 'postDate',
        type: 'time',
    },
    {
        name: 'Slug',
        id: 'slug',
        type: 'text',
    },
    {
        name: 'Artykuł specjalny?',
        id: 'special',
        type: 'checkbox',
    },
    {
        name: 'Zdjęcia (tylko do usuwania, zdjęcia dodaje się wewnątrz artykułu)',
        id: 'files',
        type: 'files',
        image: true,
        noAlt: true,
        noAdd: true,
    },
    {
        name: 'Meta tytuł',
        id: 'metaTitle',
        type: 'text',
    },
    {
        name: 'Meta opis',
        id: 'metaDescription',
        type: 'text',
    }
];