export const fields = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Zawartość',
        id: 'data',
        type: 'textarea',
        required: 'To pole jest wymagane',
    }
];