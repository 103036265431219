import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_DESIGN_CATEGORIES: 'designCategory/FETCH_ALL_DESIGN_CATEGORIES',
    FETCH_SIMPLIFIED_DESIGN_CATEGORIES: 'designCategory/FETCH_SIMPLIFIED_DESIGN_CATEGORIES',
    FETCH_DESIGN_CATEGORY: 'designCategory/FETCH_DESIGN_CATEGORY',
    UPDATE_DESIGN_CATEGORY: 'designCategory/UPDATE_DESIGN_CATEGORY',
    DELETE_DESIGN_CATEGORY: 'designCategory/DELETE_DESIGN_CATEGORY',
    CREATE_DESIGN_CATEGORY: 'designYCategory/CREATE_DESIGN_CATEGORY',
    CHANGE_PAGINATION: 'design/CHANGE_PAGINATION',
    RESET: 'designCategory/RESET',
};

const initState = {
    loading: false,
    pagination: defaultSearchableParams(10),
    data: null,
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const designCategoryState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_DESIGN_CATEGORIES):
        case REQUEST(ActionTypes.FETCH_SIMPLIFIED_DESIGN_CATEGORIES):
        case REQUEST(ActionTypes.FETCH_DESIGN_CATEGORY):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_DESIGN_CATEGORY):
        case REQUEST(ActionTypes.DELETE_DESIGN_CATEGORY):
        case REQUEST(ActionTypes.UPDATE_DESIGN_CATEGORY):
            return{
                ...state,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_DESIGN_CATEGORIES):
        case FAILURE(ActionTypes.FETCH_DESIGN_CATEGORY):
        case FAILURE(ActionTypes.CREATE_DESIGN_CATEGORY):
        case FAILURE(ActionTypes.DELETE_DESIGN_CATEGORY):
        case FAILURE(ActionTypes.FETCH_SIMPLIFIED_DESIGN_CATEGORIES):
        case FAILURE(ActionTypes.UPDATE_DESIGN_CATEGORY):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.CREATE_DESIGN_CATEGORY):
        case SUCCESS(ActionTypes.UPDATE_DESIGN_CATEGORY):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_DESIGN_CATEGORIES):
        case SUCCESS(ActionTypes.FETCH_DESIGN_CATEGORY):
        case SUCCESS(ActionTypes.FETCH_SIMPLIFIED_DESIGN_CATEGORIES):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_DESIGN_CATEGORY):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default designCategoryState;

const apiUrl = 'admin/category';

export const getAllDesignCategories= () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_DESIGN_CATEGORIES,
    payload: connection(applyParamsToUrl('category', getState().category.pagination), 'GET', null),
});


export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllDesignCategories());
    return result;
}


export const getSimpleDesignCategories= () =>({
    type: ActionTypes.FETCH_SIMPLIFIED_DESIGN_CATEGORIES,
    payload: connection('category/simple/all', 'GET', null),
});

export const getDesignCategory = (id) =>({
    type: ActionTypes.FETCH_DESIGN_CATEGORY,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createDesignCategory = (designCategory) => ({
    type: ActionTypes.CREATE_DESIGN_CATEGORY,
    payload: connection(`${apiUrl}`, 'POST', designCategory)
});

export const updateDesignCategory = (designCategory) => ({
    type: ActionTypes.UPDATE_DESIGN_CATEGORY,
    payload: connection(`${apiUrl}`, 'PUT', designCategory)
});

export const deleteDesignCategory = (id) => async dispatch =>{
    const result = await dispatch({
        type: ActionTypes.DELETE_DESIGN_CATEGORY,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllDesignCategories());
    return result;
};