import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import ShipmentCreatorServicePoint from "./shipmentCreatorServicePoint";
import ShipmentCreatorServicePointEmpty from "./shipmentCreatorServicePointEmpty";
import Test from "./test";

const Purchase = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute  path={`${match.url}/add`} component={ShipmentCreatorServicePointEmpty}/>
            <PrivateRoute  path={`${match.url}/:source/:id`} component={ShipmentCreatorServicePoint}/>
            <PrivateRoute exact path={`${match.url}`} component={Test}/>
        </Switch>
    </>
);

export default Purchase;