import React, {Component} from "react";
import {connect} from 'react-redux';
import {changePagination, deleteDesignCategory} from "./category.reducer";
import GenericTable from "../../../modules/table/GenericTable";
import Page from "../../../tools/Page";

class Categories extends Component{

    columnList = () =>{
        return [
            {id: 'name', name: 'Nazwa'}
        ]
    };

    render() {
        return(
            <Page header='Wszystkie Kategorie'>
                <GenericTable changePage={this.props.changePagination}
                              params={this.props.pagination}
                              data={this.props.categories}
                              columns={this.columnList()}
                              title={'kategorię'}
                              deleteFunction={this.props.deleteDesignCategory}
                              slug={'category'}
                              add={'Stwórz nową kategorię'}
                />
            </Page>
        );
    }
}

const mapStateToProps =({category}) =>({
    categories: category.data,
    pagination: category.pagination,
});

const mapDispatchToProps = {
    changePagination,
    deleteDesignCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);

