import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deletePartner} from "./partner.reducer";
import Page from "../../../tools/Page";

class Partners extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'website', name: 'Strona'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszyscy partnerzy'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'partnera'}
                    skip={['deadline']}
                    deleteFunction={this.props.deletePartner}
                    slug={'partner'}
                    add={'Dodaj nowego partnera'}
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({partner}) => ({
    data: partner.data,
    pagination: partner.pagination
});

const mapDispatchToProps = {
    changePagination,
    deletePartner
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Partners);