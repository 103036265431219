export const fields = [
    {
        name: 'Nazwa',
        id: 'name',
        type: 'text',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Adres',
        id: 'address',
        type: 'text',
    },
    {
        name: 'Email 1',
        id: 'emailOne',
        type: 'text',
    },
    {
        name: 'Email 2',
        id: 'emailTwo',
        type: 'text',
    },
    {
        name: 'Logo',
        id: 'image',
        type: 'file',
    },
    {
        name: 'Szerokość geograficzna',
        id: 'latitude',
        type: 'number',
        step: '0.000000000000001',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Długość geograficzna',
        id: 'longitude',
        type: 'number',
        step: '0.000000000000001',
        required: 'To pole jest wymagane',
    },
    {
        name: 'Numer telefonu biura',
        id: 'phoneOffice',
        type: 'text',
    },
    {
        name: 'Numer telefonu studia',
        id: 'phoneStudio',
        type: 'text',
    },
    {
        name: 'Adres strony internetowej',
        id: 'website',
        type: 'text',
    }
];