import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createPartner} from "./partner.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class PartnerCreator extends Component{

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        console.log(data);
        this.props.createPartner(data);
    }

    render() {

        return (
            <Page header={'Dodaj partnera'}>
                <GenericForm
                    content={fields}
                    data = {{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                    <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({partner}) => ({
    updateSuccess: partner.updateSuccess,
    data: partner.data
});

const mapDispatchToProps = {
    createPartner
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCreator);