import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {Redirect} from "react-router-dom";
import {fields} from "./constants";
import {createShipment} from "./shipment-web.reducer";
import {connect} from "react-redux";

class ShipmentCreatorWebEmpty extends Component{

    cancel(){
        this.props.history.goBack();
    }

    save(data){
        this.props.createShipment(data);
    }

    render() {
        return(
            <Page header={'Dodaj przesyłkę kurierską'}>
                <GenericForm
                    content={fields}
                    data={{
                        pieceList: {
                            item: [{}],
                        }
                    }}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                {this.props.updateSuccess &&
                <Redirect to={`/shipment/web`}/>
                }
            </Page>
        );
    }
}

const mapStateToProps = ({shipmentWeb}) => ({
    updateSuccess: shipmentWeb.updateSuccess,
});

const mapDispatchToProps = {
    createShipment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentCreatorWebEmpty)