import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_PAGES: 'page/FETCH_ALL_PAGES',
    FETCH_PAGE: 'page/FETCH_PAGE',
    FETCH_PAGENAME: 'page/FETCH_PAGENAME',
    UPDATE_PAGE: 'page/UPDATE_PAGE',
    CHANGE_PAGINATION: 'page/CHANGE_PAGINATION',
    RESET: 'page/RESET',
};

const initState = {
    loading: false,
    pagination: defaultSearchableParams(10),
    data: null,
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const PageState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_PAGES):
        case REQUEST(ActionTypes.FETCH_PAGE):
            return{
                ...state,
                data: null,
                errorMessage: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.FETCH_PAGENAME):
            return{
                ...state,
                data: null,
                errorMessage: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.UPDATE_PAGE):
            return{
                ...state,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_PAGES):
        case FAILURE(ActionTypes.FETCH_PAGE):
        case FAILURE(ActionTypes.FETCH_PAGENAME):
        case FAILURE(ActionTypes.UPDATE_PAGE):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.UPDATE_PAGE):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.FETCH_ALL_PAGES):
        case SUCCESS(ActionTypes.FETCH_PAGE):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.FETCH_PAGENAME):
            return {
                ...state,
                dataName: action.payload,
                loading: false,
            };
        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default PageState;

const apiUrl = 'cms/page';

export const getAllPages = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_PAGES,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().page.pagination), 'GET', null),
});


export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllPages());
    return result;
}

export const getPage = (name) =>({
    type: ActionTypes.FETCH_PAGE,
    payload: connection(`${apiUrl}/${name}`, 'GET', null)
});

export const getPageName = (name) =>({
    type: ActionTypes.FETCH_PAGENAME,
    payload: connection(`${apiUrl}?page=0&size=10&sort=&search=name:${name}&raw=true`, 'GET', null)
});


export const updatePage = (page) => ({
    type: ActionTypes.UPDATE_PAGE,
    payload: connection(`cms/admin/page`, 'PUT', page)
});
