import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import BoughtDesigns from "./boughtDesigns";

const BoughtDesign = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}`} component={BoughtDesigns}/>
        </Switch>
    </>
);

export default BoughtDesign;