import React, {Component} from "react";
import GenericForm from "../form/GenericForm";
import {connect} from "react-redux";
import {updateUser} from "./account.reducer";
import {fields} from "./constants";
import Page from "../../tools/Page";

class Profile extends Component{

    save(data){
        this.props.updateUser(data);
    }

    cancel(){
        this.props.history.goBack();
    }

    render() {
        return (
            <>
                <Page header={`Mój profil`}>
                    <GenericForm
                        content={fields}
                        data={this.props.data}
                        save={this.save.bind(this)}
                        cancel={this.cancel.bind(this)}
                    />
                </Page>
            </>
        );
    }

}

const mapStateToProps = ({auth}) => ({
    data: auth.user,
});

const mapDispatchToProps = {
    updateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);