import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteBoughtDesign} from "./otherBoughtDesigns.reducer";
import Page from "../../../tools/Page";

class OtherBoughtDesigns extends Component{
    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'name', name: 'Imię'},
                {id: 'lastName', name: 'Nazwisko'},
                {id: 'email', name: 'Adres email'},
                {id: 'phone', name: 'Telefon'},
                {id: 'design', name: 'Projekt', value: 'name', slug:'/design'},
                {id: 'date', name: 'Data'},
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Zgłoszenia zakupionych projektów poza HK'>
                    <GenericTable
                        data={this.props.data}
                        changePage={this.props.changePagination}
                        params={this.props.pagination}
                        columns={this.state.keys}
                        title={'otherBoughtDesign'}
                        deleteFunction={this.props.deleteBoughtDesign}
                        slug={'bought-elsewhere'}
                        skip={['date']}
                        downloadLink='form/bought-elsewhere/admin/raport.xlsx'
                        disableAdd
                        viewOnly
                    />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({otherBoughtDesign}) => ({
    data: otherBoughtDesign.data,
    pagination: otherBoughtDesign.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteBoughtDesign
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (OtherBoughtDesigns);