import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import DesignSelections from "./designSelections";
import DesignSelectionView from './designSelection';

const DesignSelection = ({match}) => (
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/:id`} component={DesignSelectionView}/>
            <PrivateRoute exact path={`${match.url}`} component={DesignSelections}/>
        </Switch>
    </>
);

export default DesignSelection;