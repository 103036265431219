import React from 'react';

export const Mirror = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.824" height="28.243" viewBox="0 0 39.824 28.243">
            <g transform="translate(0.5 0.009)">
                <g transform="translate(0)">
                    <path className="a" fill="#262626" stroke="#262626" strokeMiterlimit="10" d="M-19.4,168.71l-12.966-12.829V181.54Z"
                          transform="translate(32.364 -154.586)"/>
                    <path className="b" fill="none" stroke="#262626" strokeDasharray="2 0" strokeMiterlimit="10" d="M-19.263,168.71-6.3,155.881V181.54Z" transform="translate(45.121 -154.586)"/>
                    <line className="c" fill="none" stroke="#262626" strokeDasharray="2 0.9" strokeMiterlimit="10" y1="28.224" x2="0.534" transform="translate(19.412)"/>
                </g>
            </g>
        </svg>
    )
};