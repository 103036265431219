import React, {Component} from "react";
import Page from "../../../tools/Page";
import GenericForm from "../../../modules/form/GenericForm";
import {fields} from "./constants";
import {createDesignCategory} from "./category.reducer";
import {getDesignSimple} from "../design/design.reducer";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

class categoryCreator extends Component {

    componentDidMount() {
        this.props.getDesignSimple();
    }

    cancel() {
        this.props.history.goBack();
    }

    save(data) {
        console.log(data);
        data.designs = data.designs.map((el) => ({id: el}));
        this.props.createDesignCategory(data);
    }

    columns = fields;

    render() {
        this.columns[6].values = this.props.designs;
        return (
            <Page info='Ogólne' header={'Swtórz kategorię'}>
                {this.props.designs &&
                <GenericForm
                    content={fields}
                    data={{}}
                    save={this.save.bind(this)}
                    cancel={this.cancel.bind(this)}
                />
                }
                {this.props.updateSuccess &&
                <Redirect to={`${this.props.data.id}`}/>
                }
            </Page>
        );
    }
}


const mapStateToProps = ({category, design}) => ({
    updateSuccess: category.updateSuccess,
    data: category.data,
    designs: design.data
});

const mapDispatchToProps = {
    createDesignCategory,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(categoryCreator);