export const fields = [
    {
        name: 'Zdjęcie profilowe',
        id: 'avatar',
        type: 'file',
        image: true,
        noAlt: true,
    },
    {
        name: 'Nick na forum',
        id: 'nick',
        type: 'text',
    },
    {
        name: 'Imię',
        id: 'firstName',
        type: 'text',
    },
    {
        name: 'Nazwisko',
        id: 'lastName',
        type: 'text',
    },
    {
        name: 'Email',
        id: 'email',
        type: 'text',
    },
    {
        name: 'Telefon',
        id: 'phone',
        type: 'text',
    },
    {
        name: 'Stare hasło',
        id: 'oldPassword',
        type: 'text',
    },
    {
        name: 'Nowe hasło',
        id: 'newPassword',
        type: 'text',
    },
    {
        name: 'Nowe hasło powtórzone',
        id: 'newPasswordRepeated',
        type: 'text',
    }
];