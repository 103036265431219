import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../tools/privateRoute";
import Users from './users';

const Abc = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}`} component={Users}/>
        </Switch>
    </>
);

export default Abc;