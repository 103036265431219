import React from "react";
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import moment from "moment";
import {Grid} from "@material-ui/core";

const format = "YYYY-MM-DDTHH:mm";
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";

const Time = (props) => {

    const onTimeChange = data => {
        if (data instanceof moment) {
            props.onChange(data.format(format));
        }
    };

    return (
        <>
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <Datetime
                        input={!props.noInput}
                        dateFormat={dateFormat}
                        timeFormat={props.noTime ? false : timeFormat}
                        initialValue={moment.utc(props.value)}
                        value={moment.utc(props.value)}
                        onChange={onTimeChange}
                        inputProps={{disabled: props.readOnly}}
                    />
                </Grid>
                <Grid item>
                    {props.clear &&
                    <button type='button' className='btn-primary btn' onClick={props.clear}>Wyczyść</button>
                    }
                </Grid>
            </Grid>
        </>
    )

};

export default Time;
