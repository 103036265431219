import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_COMPANIES: 'company/FETCH_ALL_COMPANIES',
    FETCH_COMPANY: 'company/FETCH_COMPANY',
    UPDATE_COMPANY: 'company/UPDATE_COMPANY',
    DELETE_COMPANY: 'company/DELETE_COMPANY',
    CHANGE_PAGINATION: 'company/CHANGE_PAGINATION',
    CREATE_COMPANY: 'company/CREATE_COMPANY',
    RESET: 'company/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const CompanyState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_COMPANIES):
        case REQUEST(ActionTypes.FETCH_COMPANY):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_COMPANY):
        case REQUEST(ActionTypes.DELETE_COMPANY):
        case REQUEST(ActionTypes.UPDATE_COMPANY):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_COMPANIES):
        case FAILURE(ActionTypes.FETCH_COMPANY):
        case FAILURE(ActionTypes.CREATE_COMPANY):
        case FAILURE(ActionTypes.DELETE_COMPANY):
        case FAILURE(ActionTypes.UPDATE_COMPANY):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_COMPANY):
        case SUCCESS(ActionTypes.UPDATE_COMPANY):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_COMPANIES):
        case SUCCESS(ActionTypes.FETCH_COMPANY):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_COMPANY):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default CompanyState;

const apiUrl = 'abc/admin/company';

export const getAllCompanies = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_COMPANIES,
    payload: connection(applyParamsToUrl(`abc/company`,getState().company.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllCompanies());
    return result;
}


export const getCompany = (id) =>({
    type: ActionTypes.FETCH_COMPANY,
    payload: connection(`abc/company/${id}`, 'GET', null)
});

export const createCompany = (company) => ({
    type: ActionTypes.CREATE_COMPANY,
    payload: connection(`${apiUrl}`, 'POST', company)
});

export const updateCompany = (company) => ({
    type: ActionTypes.UPDATE_COMPANY,
    payload: connection(`${apiUrl}`, 'PUT', company)
});

export const deleteCompany = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_COMPANY,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllCompanies());
    return result;
};