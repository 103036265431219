import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import RoleCreator from "./roleCreator";
import Roles from "./roles";
import RoleView from "./role";

const Role = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}/add`} component={RoleCreator}/>
            <PrivateRoute exact path={`${match.url}/:id`} component={RoleView}/>
            <PrivateRoute exact path={`${match.url}`} component={Roles}/>
        </Switch>
    </>
);

export default Role;