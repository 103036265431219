import connection from "../../tools/connection";
import {FAILURE, REQUEST, SUCCESS} from "../../redux/actionTypes";



export const ACTION_TYPES = {
    LOGIN: 'account/LOGIN',
    LOGOUT: 'account/LOGOUT',
    GET_USER: 'account/GET_USER',
    UPDATE_USER: 'account/UPDATE_USER',
    RESET_PASSWORD_INIT: 'account/RESET_PASSWORD_INIT',
    RESET_PASSWORD_FINISH: 'account/RESET_PASSWORD_FINISH',
};

const initState = {
    sessionFetched: false,
    isAuthenticated: false,
    updating: false,
    user: null,
    reset: false,
};

const AccountState = (state= initState, action ) => {
    switch (action.type) {
        case ACTION_TYPES.LOGOUT:
        case REQUEST(ACTION_TYPES.GET_USER):
        case REQUEST(ACTION_TYPES.LOGIN):
        case REQUEST(ACTION_TYPES.RESET_PASSWORD_INIT):
        case REQUEST(ACTION_TYPES.RESET_PASSWORD_FINISH):
            return {
                ...initState
            };
        case REQUEST(ACTION_TYPES.UPDATE_USER):
            return {
                ...state,
                updating: true,
                reset: false,
            }
        case SUCCESS(ACTION_TYPES.RESET_PASSWORD_FINISH):
            return {
                ...state,
                reset: true,
                sessionFetched: true
            }
        case SUCCESS(ACTION_TYPES.GET_USER):
            if(action.payload.authority ==='ROLE_ADMIN' || action.payload.authority ==='ROLE_COUNSELOR' || action.payload.authority === 'ROLE_EDITOR' || action.payload.authority === 'ROLE_OWNER') {
                return {
                    ...state,
                    sessionFetched: true,
                    user: action.payload,
                    isAuthenticated: true,
                    reset: false,
                };
            }else {
                clearToken();
                return {
                    ...state,
                    sessionFetched: true,
                    user: null,
                    isAuthenticated: false,
                    reset: false,
                };
            }
        case SUCCESS(ACTION_TYPES.RESET_PASSWORD_INIT):
            return {
                ...state,
                reset: true,
            }
        case SUCCESS(ACTION_TYPES.LOGIN):
            const user = action.payload.user;
            const token = action.payload.token;

            if(user.authority ==='ROLE_ADMIN' || user.authority ==='ROLE_COUNSELOR' || user.authority === 'ROLE_EDITOR' || user.authority === 'ROLE_OWNER'){
                localStorage.setItem('token', token);
                localStorage.setItem('role', user.authority);
                localStorage.setItem('user', user);

                return {
                  ...state,
                    sessionFetched: true,
                    user,
                    isAuthenticated: true
                };
            }else {
                clearToken();
                return {
                    ...state,
                    sessionFetched: true,
                    user: null,
                    isAuthenticated: false,
                };
            }
        case SUCCESS(ACTION_TYPES.UPDATE_USER):
            return {
                ...state,
                user: action.payload,
                updating: false,
            }
        case FAILURE(ACTION_TYPES.UPDATE_USER):
            return {
                ...state,
                updating: false,
                reset: false,
            }
        case FAILURE(ACTION_TYPES.RESET_PASSWORD_FINISH):
        case FAILURE(ACTION_TYPES.RESET_PASSWORD_INIT):
        case FAILURE(ACTION_TYPES.GET_USER):
        case FAILURE(ACTION_TYPES.LOGIN):
            clearToken();
            return {
                ...state,
                sessionFetched: true,
                user: null,
                isAuthenticated: false,
                reset: false,
            };

        default:
            return state;
    }
};

export default AccountState;


export const loginRequest = (email, password, remember) => ({
   type: ACTION_TYPES.LOGIN,
   payload: connection(`login`, 'POST', {email, password, remember}),
});

export const clearToken = () => (dispatch, getState) => {
    if(localStorage.getItem('token')){
        localStorage.removeItem('token')
    }
    if(sessionStorage.getItem('token')){
        sessionStorage.removeItem('token')
    }
    dispatch({
        type: ACTION_TYPES.LOGOUT
    });
};

export const getUser = () =>({
    type: ACTION_TYPES.GET_USER,
    payload: connection('account/secured/profile', 'GET', null),
});

export const updateUser = (user) =>({
    type: ACTION_TYPES.UPDATE_USER,
    payload: connection('account/secured/profile', 'PUT', user),
});

export const resetPasswordInit = (email)=> ({
    type: ACTION_TYPES.RESET_PASSWORD_INIT,
    payload: connection('reset-password', 'POST', email, true, true),
});

export const resetPasswordFinish = (data)=> ({
    type: ACTION_TYPES.RESET_PASSWORD_FINISH,
    payload: connection('reset-password/finish', 'POST', data, true),
});

