import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_ADDONS: 'addon/FETCH_ALL_ADDONS',
    FETCH_ADDON: 'addon/FETCH_ADDON',
    UPDATE_ADDON: 'addon/UPDATE_ADDON',
    UPDATE_ADDONS_ORDER: 'addon/UPDATE_ADDONS_ORDER',
    DELETE_ADDON: 'addon/DELETE_ADDON',
    CHANGE_PAGINATION: 'addon/CHANGE_PAGINATION',
    CREATE_ADDON: 'addon/CREATE_ADDON',
    RESET: 'addon/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const AddonState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_ADDONS):
        case REQUEST(ActionTypes.FETCH_ADDON):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_ADDON):
        case REQUEST(ActionTypes.DELETE_ADDON):
        case REQUEST(ActionTypes.UPDATE_ADDON):
        case REQUEST(ActionTypes.UPDATE_ADDONS_ORDER):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_ADDONS):
        case FAILURE(ActionTypes.FETCH_ADDON):
        case FAILURE(ActionTypes.CREATE_ADDON):
        case FAILURE(ActionTypes.DELETE_ADDON):
        case FAILURE(ActionTypes.UPDATE_ADDON):
        case FAILURE(ActionTypes.UPDATE_ADDONS_ORDER):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_ADDON):
        case SUCCESS(ActionTypes.UPDATE_ADDON):
        case SUCCESS(ActionTypes.UPDATE_ADDONS_ORDER):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_ADDONS):
        case SUCCESS(ActionTypes.FETCH_ADDON):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_ADDON):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default AddonState;

const apiUrl = 'addon';

export const getAllAddons = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_ADDONS,
    payload: connection(applyParamsToUrl(`${apiUrl}/admin`,getState().addon.pagination), 'GET', null),
});

export const getSimpleAddons = () =>({
    type: ActionTypes.FETCH_ALL_ADDONS,
    payload: connection(`${apiUrl}/admin/simple`, 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllAddons());
    return result;
}

export const getAddon = (id) =>({
    type: ActionTypes.FETCH_ADDON,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createAddon = (addon) => ({
    type: ActionTypes.CREATE_ADDON,
    payload: connection(`${apiUrl}/admin`, 'POST', addon)
});

export const updateAddon = (addon) => ({
    type: ActionTypes.UPDATE_ADDON,
    payload: connection(`${apiUrl}/admin`, 'PUT', addon)
});

export const updateAddonsOrder = (addonsIds) => ({
    type: ActionTypes.UPDATE_ADDONS_ORDER,
    payload: connection(`${apiUrl}/admin/order`, 'PUT', addonsIds)
});

export const deleteAddon = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_ADDON,
        payload: connection(`${apiUrl}/admin/${id}`, 'DELETE', null)
    });
    dispatch(getAllAddons());
    return result;
};