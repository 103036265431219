import React from "react";
import Select from "react-select";

export const CustomSelect = ({options, onChange, value, multi}) =>{
    let mappedOptions = options ? options.map( option =>{
        return {
            value: option.id.toString(),
            label: option.name,
        }
    }) : [];

    let dictionary = {};

    for (const o of mappedOptions){
        dictionary[o.value] = o.label;
    }

    const handleChange = (e) => {
        if(multi){
            onChange(e.map(el => el.value))
        }else{
            onChange(e.value)
        }
    }

    let currentValue = multi ? (value ? value.map(val => {
        return {
            value: val,
            label: dictionary[val],
        }
    }) : [])
        :
        {
            value: value,
            label: dictionary[value]
        };

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    return (
       <Select
           isMulti={multi}
           closeMenuOnSelect={!multi}
           options={mappedOptions}
           onChange={handleChange}
           value={currentValue}
           styles={selectStyles}
       />
    );

};

export default CustomSelect;