import React from 'react';
import {Switch} from 'react-router-dom';
import PrivateRoute from "../../../tools/privateRoute";
import Marketings from "./marketings";

const Marketing = ({match}) => (
    <>
        <Switch>
            <PrivateRoute exact path={`${match.url}`} component={Marketings}/>
        </Switch>
    </>
);

export default Marketing;