import React, { Component } from "react";
import {
  TableSortLabel,
  TableContainer,
  TableRow,
  TableBody,
  TablePagination,
  TableFooter,
  Table,
  TableCell,
  TableHead,
  Grid,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ConfirmDelete } from "../../tools/confirmDelete";
import "./GenericTable.css";
import { Search } from "./Search";
import { handleDownload } from "../../tools/download";
import { CustomCheckbox } from "./customCheckbox";
import connection from "../../tools/connection";

class GenericTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      open: false,
    };
  }

  componentDidMount() {
    this.getPage(0);
  }

  getPage = (page) => {
    let params = this.props.params;
    params.page = page;
    params.search = this.buildSearch("");
    this.props.changePage(params);
  };

  changePage = (e, page) => {
    this.getPage(page);
  };

  changePageSize = (e) => {
    let params = this.props.params;
    params.size = parseInt(e.target.value, 10);
    params.page = 0;
    this.props.changePage(params);
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  openModal = (id) => {
    this.setState({
      id: id,
      open: true,
    });
  };

  handleDelete = () => {
    this.props.deleteFunction(this.state.id);
    this.setState({
      open: false,
    });
  };

  isActive = (value) => {
    return this.props.params.sort.startsWith(value);
  };

  getOrder = () => {
    const order = this.props.params.sort.split(",");
    return order[0];
  };

  getStateDirection = () => {
    const order = this.props.params.sort.split(",");
    return order.length > 1 ? order[1] : "asc";
  };

  getDirection = (value) => {
    return this.isActive(value) ? this.getStateDirection() : "asc";
  };

  handleSort = (event, property) => {
    const isAsc =
      this.getOrder() === property && this.getStateDirection() === "asc";
    const sort = `${property},${isAsc ? "desc" : "asc"}`;
    const params = this.props.params;
    params.sort = sort;
    params.page = 0;
    this.props.changePage(params);
  };

  buildSearch = (search) => {
    if (search === "") {
      return this.props.search;
    } else {
      const result = this.props.columns.map((item) => {
        if (item.id === "creationDate") {
          return "";
        } else {
          if (item.value) {
            if (this.props.skip && this.props.skip.includes(item.value)) {
              return "";
            } else {
              return `${item.value}:${encodeURI("%" + search + "%")}`;
            }
          } else {
            if (this.props.skip && this.props.skip.includes(item.id)) {
              return "";
            } else {
              return `${item.id}:${encodeURI("%" + search + "%")}`;
            }
          }
        }
      });
      if (!this.props.search || this.props.search === "") {
        return result.join("|");
      } else {
        return this.props.search + "," + result.join("|");
      }
    }
  };

  handleSearch = (data) => {
    const search = this.buildSearch(data.search);
    const params = this.props.params;
    params.search = search;
    params.page = 0;
    this.props.changePage(params);
  };

  getXML = () => {
    connection(`secured/design/export`, 'GET', null)
  }

  render() {
    const { data, columns, title, slug, add } = this.props;

    const sortHandler = (property) => (event) => {
      this.handleSort(event, property);
    };

    return (
      <>
        <div className="text-right">
          <Grid container justify="flex-end" spacing={2}>
            {!this.props.disableSearch && (
              <Grid item>
                <Search onSearch={this.handleSearch} />
              </Grid>
            )}
            {!this.props.disableAdd && (
              <Grid item>
                <Link
                  to={
                    this.props.setId
                      ? `${slug}/add/${this.props.setId}`
                      : `${slug}/add`
                  }
                >
                  <button className="btn btn-primary">{add}</button>
                </Link>
              </Grid>
            )}
            {this.props.downloadLink && (
              <Grid item>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleDownload(this.props.downloadLink, "raport.xlsx")
                  }
                >
                  Pobierz dane
                </button>
              </Grid>
            )}
            {this.props.downloadLink2 && (
              <Grid item>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleDownload(this.props.downloadLink2, "raport.xlsx")
                  }
                >
                  Pobierz dane rzutów
                </button>
              </Grid>
            )}
            {!this.props.disableImport && <><Grid item>
              <Link to={`${slug}/import`}>
                <button className="btn btn-primary">Importuj dane</button>
              </Link>
            </Grid>
              <Grid item>
                <Link to={`${slug}/update`}>
                  <button className="btn btn-primary">Masowe zarządzanie</button>
                </Link>
              </Grid></>}
            {this.props.getXML && (
              <Grid item>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    handleDownload('secured/design/export', "raport.xml")
                  }
                >
                  Pobierz XML
                </button>
              </Grid>
            )}
          </Grid>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item, i) => (
                  <TableCell key={i}>
                    <TableSortLabel
                      active={this.isActive(item.id)}
                      direction={this.getDirection(item.id)}
                      onClick={sortHandler(item.id)}
                    >
                      {item.name}
                      {this.isActive(item.id) && (
                        <span className="visuallyHidden">
                          {this.getDirection(item.id) === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      )}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data &&
                data.content &&
                Array.isArray(data.content) &&
                data.content.map((item, i) => (
                  <TableRow
                    hover
                    key={i}
                    style={{
                      backgroundColor: `${this.props.flagDelivery &&
                          item[columns[9].id] === "BEFORE"
                          ? "#5555"
                          : ""
                        }`,
                    }}
                  >
                    {columns.map((value, i2) =>
                      value.value ? (
                        <TableCell
                          key={i2}
                        // onClick={() => {
                        //   if (value.slug) {
                        //     if (value.slug.includes("users"))
                        //       history.push(
                        //         `${value.slug}/${item[value.id].id}?tab=user`
                        //       );
                        //     // window.location = `${value.slug}/${item[value.id].id}?tab=user`
                        //     else
                        //       history.push(
                        //         `${value.slug}/${item[value.id].id}`
                        //       );
                        //     // window.location = `${value.slug}/${item[value.id].id}`
                        //   }
                        // }}
                        >
                          <Link
                            to={
                              value.slug
                                ? value.slug.includes("users")
                                  ? `${value.slug}/${item[value.id].id
                                  }?tab=user`
                                  : `${value.slug}/${item[value.id].id}`
                                : "/"
                            }
                          >
                            {item[value.id] && item[value.id][value.value]}
                          </Link>
                        </TableCell>
                      ) : value.check && this.props.toggle ? (
                        <TableCell key={i2}>
                          <CustomCheckbox
                            value={item[value.id]}
                            callback={this.props.toggle}
                            id={item.id}
                          />
                        </TableCell>
                      ) : value.link ? (
                        <TableCell
                          key={i2}
                        // onClick={() =>
                        //   (window.location = `${slug}/${item[value.id]}`)
                        // }
                        >
                          <Link to={`${slug}/${item[value.id]}`}>
                            {value.mapper
                              ? value.mapper(item[value.id])
                              : item[value.id]}
                          </Link>
                        </TableCell>
                      ) : (
                        <TableCell key={i2}>
                          {value.mapper
                            ? value.mapper(item[value.id])
                            : item[value.id]}
                        </TableCell>
                      )
                    )}
                    <TableCell align={"right"}>
                      {this.props.seePost && (
                        <Button
                          component={Link}
                          to={`/forum/post?search=thread:${item.id}`}
                          size="sm"
                        >
                          <span className="btn btn-primary">
                            <i className="fa fa-th-list" /> Zobacz posty do
                            wątku
                          </span>
                        </Button>
                      )}
                      {!this.props.disableEdit && (
                        <Button
                          component={Link}
                          to={`${this.props.slug}/${!this.props.slugName ? item.id : item.name
                            }${this.props.slug === "users" ? "?tab=user" : ""}`}
                          size="sm"
                        >
                          <span className="btn btn-primary">
                            <i className="fa fa-edit" />{" "}
                            {this.props.viewOnly ? "Podgląd" : "Edytuj"}
                          </span>
                        </Button>
                      )}
                      <Button onClick={() => this.openModal(item.id)} size="sm">
                        <span className="btn btn-danger">
                          <i className="fa fa-trash" /> Usuń
                        </span>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                {data && (
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 25, 50]}
                    count={data.totalElements}
                    rowsPerPage={this.props.params.size}
                    page={this.props.params.page}
                    onChangePage={this.changePage}
                    onChangeRowsPerPage={this.changePageSize}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <ConfirmDelete
          object={title}
          id={this.state.id}
          open={this.state.open}
          close={this.closeModal.bind(this)}
          delete={this.handleDelete.bind(this)}
        />
      </>
    );
  }
}

export default GenericTable;
