import React from 'react';
import {Switch} from 'react-router-dom';
import Journal from './journal';
import Realization from './realization';
import PrivateRoute from "../../tools/privateRoute";

const Community = ({match}) =>(
    <>
        <Switch>
            <PrivateRoute path={`${match.url}/journal`} component={Journal}/>
            <PrivateRoute path={`${match.url}/realization`} component={Realization}/>
        </Switch>
    </>
);

export default Community;