import React, {Component} from 'react';
import { connect } from 'react-redux';
import GenericTable from "../../../modules/table/GenericTable";
import {changePagination, deleteThread} from "./thread.reducer";
import Page from "../../../tools/Page";

class Threads extends Component{

    constructor(props) {
        super(props);
        this.state={
            keys: [
                {id: 'title', name: 'Tytuł'},
                {id: 'category', name: 'Kategoria', value: 'name', slug:'category'}
            ],
        }
    }

    render() {
        return (
            <>
                <Page header='Wszystkie wątki'>
                <GenericTable
                    data={this.props.data}
                    changePage={this.props.changePagination}
                    params={this.props.pagination}
                    columns={this.state.keys}
                    title={'wątek'}
                    deleteFunction={this.props.deleteThread}
                    slug={'thread'}
                    disableAdd
                    seePost
                />
                </Page>
            </>
        );
    }
}

const mapStateToProps = ({thread}) => ({
    data: thread.data,
    pagination: thread.pagination
});

const mapDispatchToProps = {
    changePagination,
    deleteThread
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (Threads);