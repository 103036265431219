import React, {Component} from "react";
import {connect} from 'react-redux';
import GenericForm from '../../../modules/form/GenericForm';
import {getSpecial, updateSpecial} from "./special.reducer";
import {getDesignSimple} from "../../design/design/design.reducer";
import {states} from "../../../tools/states";

import {fields} from "./constants";
import Page from "../../../tools/Page";


class Special extends Component{

   componentDidMount() {
       this.props.getDesignSimple();
       this.props.getSpecial(this.props.match.params.id);
   }

   save(data){
       data.id = this.props.data.id;
       data.designs = data.designs.map((el) => ({id: el}));
       this.props.updateSpecial(data);
   }

    cancel(){
        this.props.history.goBack();
    }

    columns = fields;

    render() {
        this.columns[1].values = this.props.designs;
        this.columns[8].values = states;
      return (
          <>
              <Page header={`Oferta specjalna ${this.props.match.params.id}`}>
                  {this.props.data && !this.props.data.content &&
                  <GenericForm
                      content={this.columns}
                      data={{
                          ...this.props.data,
                          designs: this.props.data.designs.map((el) => el.id.toString()),
                          states: this.props.data.states.map((el) => el.toString())
                      }}
                      save={this.save.bind(this)}
                      cancel={this.cancel.bind(this)}
                  />
                  }
              </Page>
          </>
      );
   }

}

const mapStateToProps = ({special, design}) => ({
    data: special.data,
    designs: design.data,
});

const mapDispatchToProps = {
    getSpecial,
    updateSpecial,
    getDesignSimple
};

export default connect(mapStateToProps, mapDispatchToProps)(Special);