import React, {useState} from "react";
import Collapse from "@material-ui/core/Collapse";
import {Button, Grid, List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Document} from "./document";
import {Link} from "react-router-dom";
import {ConfirmDelete} from "../../../tools/confirmDelete";
import {deleteDraft} from "./document.reducer";
import {useDispatch} from "react-redux";

export const Draft = ({draft, type}) => {

    const [open, setOpen] = useState(true);

    const [modal, setModal] = useState(false);

    const [id, setId] = useState();

    const dispatch = useDispatch();

    const handleClick = () => {
        setOpen(!open);
    };

    const openModal = (id) => {
        setId(id);
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const handleDelete = () => {
        dispatch(deleteDraft(id));
        closeModal();
    };

    return (

        <>
            <Grid container direction='column'>
                <Grid item>
                    < ListItem style={{width: '100%'}} button onClick={handleClick}>
                        <ListItemText primary={draft.title}/>
                        {type === 'estimate' ?
                            <Link to={`/users/document/${draft.categories && draft.categories[0] && draft.categories[0].id}`}>
                                <button className="btn btn-primary">Dodaj Dokument</button>
                            </Link>
                            :
                            <Link to={`/users/document/category/${draft && draft.id}`}>
                                <button className="btn btn-primary">Dodaj Kategorię</button>
                            </Link>
                        }
                        <Button onClick={() => openModal(draft && draft.id)} size='sm'>
                          <span className='btn btn-danger'><i
                              className="fa fa-trash"/> Usuń</span>
                        </Button>
                    </ListItem>
                </Grid>
                <Grid style={{paddingLeft: '15px'}} item>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {type === 'estimate' ?
                                <Document data={draft.categories && draft.categories[0]} type={type}/>
                                :
                                <List component="div">
                                    {draft.categories.map((item) => (
                                        <Document data={item}/>
                                    ))}
                                </List>
                            }
                        </Collapse>
                </Grid>
            </Grid>
            <ConfirmDelete
                object={"projekt"}
                id={id}
                open={modal}
                close={closeModal}
                delete={handleDelete}
            />
        </>
    )
};