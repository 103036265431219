import connection from '../../../tools/connection';
import {REQUEST, SUCCESS, FAILURE} from "../../../redux/actionTypes";
import {applyParamsToUrl, defaultSearchableParams} from "../../../tools/PageableTools";

export const ActionTypes = {
    FETCH_ALL_USERS: 'user/FETCH_ALL_USERS',
    FETCH_USER: 'user/FETCH_USER',
    UPDATE_USER: 'user/UPDATE_USER',
    DELETE_USER: 'user/DELETE_USER',
    CHANGE_PAGINATION: 'user/CHANGE_PAGINATION',
    CREATE_USER: 'user/CREATE_USER',
    FETCH_ADVISORS: 'user/FETCH_ADVISORS',
    RESET: 'user/RESET',
};

const initState = {
    loading: false,
    data: null,
    pagination: defaultSearchableParams(10),
    advisors: [],
    updating: false,
    updateSuccess: false,
    errorMessage: null,
};

const UserState = (state = initState, action) =>{
    switch (action.type) {
        case REQUEST(ActionTypes.FETCH_ALL_USERS):
        case REQUEST(ActionTypes.FETCH_USER):
            return{
                ...state,
                errorMessage: null,
                data: null,
                loading: true,
                updating: false,
                updateSuccess: false
            };
        case REQUEST(ActionTypes.CREATE_USER):
        case REQUEST(ActionTypes.DELETE_USER):
        case REQUEST(ActionTypes.UPDATE_USER):
            return{
                ...state,
                data: null,
                errorMessage: null,
                updating: true,
                updateSuccess: false
            };

        case FAILURE(ActionTypes.FETCH_ALL_USERS):
        case FAILURE(ActionTypes.FETCH_USER):
        case FAILURE(ActionTypes.CREATE_USER):
        case FAILURE(ActionTypes.DELETE_USER):
        case FAILURE(ActionTypes.UPDATE_USER):
            return {
                ...state,
                errorMessage: action.payload,
                updating: false,
                updateSuccess: false,
                loading: false
            };

        case SUCCESS(ActionTypes.CREATE_USER):
        case SUCCESS(ActionTypes.UPDATE_USER):
            return {
                ...state,
                data: action.payload,
                updating: false,
                updateSuccess: true,
            };
        case SUCCESS(ActionTypes.CHANGE_PAGINATION):
            return {
                ...state,
                pagination: action.payload
            }
        case SUCCESS(ActionTypes.FETCH_ALL_USERS):
        case SUCCESS(ActionTypes.FETCH_USER):
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SUCCESS(ActionTypes.DELETE_USER):
            return {
                ...state,
                data: null,
                updateSuccess: false,
                updating: false,
            };
        case SUCCESS(ActionTypes.FETCH_ADVISORS):
            return {
                ...state,
                advisors: action.payload
            }

        case ActionTypes.RESET:
            return {
                ...initState,
            };
        default:
            return state;
    }
};

export default UserState;

const apiUrl = 'user/admin';

export const getAllUsers = () => (dispatch, getState) => dispatch({
    type: ActionTypes.FETCH_ALL_USERS,
    payload: connection(applyParamsToUrl(`${apiUrl}`,getState().user.pagination), 'GET', null),
});

export const changePagination = (params) => async (dispatch) => {
    const result = await dispatch({
        type: ActionTypes.CHANGE_PAGINATION,
        payload: params,
    });
    dispatch(getAllUsers());
    return result;
};

export const getUserSimple = () =>({
    type: ActionTypes.FETCH_ALL_USERS,
    payload: connection(`users/simple/all`, 'GET', null)
});

export const getUser = (id) =>({
    type: ActionTypes.FETCH_USER,
    payload: connection(`${apiUrl}/${id}`, 'GET', null)
});

export const createUser = (user) => ({
    type: ActionTypes.CREATE_USER,
    payload: connection(`${apiUrl}`, 'POST', user)
});

export const updateUser = (user) => ({
    type: ActionTypes.UPDATE_USER,
    payload: connection(`${apiUrl}`, 'PUT', user)
});

export const banUser = (userId, banDate) => ({
    type: ActionTypes.UPDATE_USER,
    payload: connection(`${apiUrl}/ban/${userId}`, 'PUT', banDate)
});

export const deleteUser = (id) => async dispatch => {
    const result = await dispatch({
        type: ActionTypes.DELETE_USER,
        payload: connection(`${apiUrl}/${id}`, 'DELETE', null)
    });
    dispatch(getAllUsers());
    return result;
};

export const getAdvisors = () =>({
    type: ActionTypes.FETCH_ADVISORS,
    payload: connection(`${apiUrl}/advisor`, 'GET', null)
});